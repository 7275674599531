<app-login-header></app-login-header>

<div class="payment_view_page" [formGroup]="cardForm">
  <div class="px-4">
    <div class="row">
      <div class="heading-font-size mx-auto d-flex">
        <span (click)="backToHome()">
          <img src="../../../../assets/icons/Shape.svg" class="back-page mr-3" alt="arrow-icon" />
        </span>
        <span class="ml-2 mb-4 mb-md-3">Add Money to Wallet</span>
      </div>
    </div>
  </div>
  <div class="payment_cards_section mx-auto">
    <div class="payment_options">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item disabled" (click)="getSelectedPaymentOption(OFFLINE_DEPOSIT)">
          <a
            translate
            class="nav-link"
            [ngClass]="{ active: selectedMethod == OFFLINE_DEPOSIT }"
            id="offlinePayment-tab"
            data-toggle="tab"
            href="#offline-payment"
            role="tab"
            aria-controls="offlinePayment"
            aria-selected="false"
          >
            {{ OFFLINE_DEPOSIT }}
          </a>
        </li>
        <li class="nav-item" (click)="getSelectedPaymentOption(WALLET)">
          <a
            translate
            class="nav-link"
            [ngClass]="{ active: selectedMethod == WALLET }"
            id="wallet"
            data-toggle="tab"
            href="#wallet"
            role="tab"
            aria-controls="wallet"
            aria-selected="true"
          >
            {{ WALLET }}
          </a>
        </li>
      </ul>
    </div>
    <div class="agency-details mt-2">
      <div class="py-3">
        <div class="row pl-2">
          <div class="col-3">
            <label class="text-label text-uppercase"> company Name </label>
            <h5 class="text-data">{{ agencyDetails?.agency_name }}</h5>
          </div>
          <div class="col-2">
            <label class="text-label text-uppercase"> Name </label>
            <h5 class="text-data">{{ agencyDetails?.contact_person }}</h5>
          </div>
          <div class="col-3">
            <label class="text-label text-uppercase"> Email </label>
            <h5 class="text-data">{{ agencyDetails?.email }}</h5>
          </div>
          <div class="col-2">
            <label class="text-label text-uppercase"> Location </label>
            <h5 class="text-data">{{ agencyDetails?.country }}</h5>
          </div>
          <div class="col-2">
            <label class="text-label text-uppercase"> agent ID </label>
            <h5 class="text-data">{{ agencyDetails?.agent_id }}</h5>
          </div>
        </div>
        <div class="row pl-2 align-items-end">
          <div class="col-4">
            <label class="text-label text-uppercase"> reason </label>
            <div>
              <input
                type="text"
                class="form-field w-100"
                placeholder="PLEASE ENTER"
                formControlName="transactionReason"
                required
              />
              <div *ngIf="isSubmitted && form.transactionReason.errors">
                <div class="validationError_msg" *ngIf="isSubmitted && form.transactionReason.errors.required">
                  Reason Required
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <label class="text-label text-uppercase"> Transaction ID </label>
            <div>
              <input
                type="text"
                class="form-field w-100"
                placeholder="PLEASE ENTER"
                formControlName="transactionId"
                required
              />
              <div *ngIf="isSubmitted && form.transactionId.errors">
                <div class="validationError_msg" *ngIf="isSubmitted && form.transactionId.errors.required">
                  Transaction Id Required
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 amountType">
            <label class="btn mr-3 p-0">
              <input type="radio" class="form-switch radio_1set" name="type" formControlName="type" value="credit" />
              <span translate>Credit</span>
            </label>
            <label class="btn mr-3 p-0">
              <input type="radio" class="form-switch radio_1set" name="type" formControlName="type" value="debit" />
              <span translate>Debit</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="credit-card pt-5">
      <div class="card">
        <div class="card-body">
          <div class="card-info">
            <div class="row card_details">
              <div class="col-sm-12">
                <div class="row m-0">
                  <div class="col-sm-12 text-left">
                    <div class="add-money-input">
                      <input
                        required
                        type="number"
                        placeholder="0"
                        formControlName="amount"
                        class="form-control bg-transparent"
                        [ngClass]="{
                          'pl-22': (walletDetails?.currency | currencyCode)?.length == 1,
                          'pl-32': (walletDetails?.currency | currencyCode)?.length == 2,
                          'pl-42': (walletDetails?.currency | currencyCode)?.length == 3
                        }"
                      />
                      <span>{{ walletDetails?.currency | currencyCode }}</span>
                    </div>
                    <div *ngIf="isSubmitted && form.amount.errors">
                      <div class="validationError_msg" *ngIf="isSubmitted && form.amount.errors.required">
                        Required Amount
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row m-0">
                  <div class="col-sm-12 text-left">
                    <ul class="list-unstyled add-money-tags">
                      <li>
                        <span (click)="setAmountToBeAdded(2000000)"
                          >{{ walletDetails?.currency | currencyCode }}2000000</span
                        >
                      </li>
                      <li>
                        <span (click)="setAmountToBeAdded(500000)"
                          >{{ walletDetails?.currency | currencyCode }}500000</span
                        >
                      </li>
                      <li>
                        <span (click)="setAmountToBeAdded(1000)"
                          >{{ walletDetails?.currency | currencyCode }}1000000</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="d-flex justify-content-center m-0">
                  <div class="text-center" (click)="updateWalletAmount()">
                    <button class="btn_addMoney" translate [disabled]="isAddingMoney">
                      <i class="fa fa-spinner fa-spin mr-2" *ngIf="isAddingMoney" aria-hidden="true"></i>
                      Update Wallet
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  tabindex="-1"
  role="dialog"
  class="modal fade"
  id="success_modal"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
  aria-labelledby="success_modal"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content dialog_content">
      <div class="modal-body mdl_body">
        <div class="main-section">
          <div class="row">
            <div class="col-4 px-0">
              <img class="img_section" src="../../../../assets/icons/success-green-tick.svg" alt="success_img" />
            </div>
            <div class="col-8 px-0 d-flex align-items-center">
              <div class="success_text">Wallet Updated Successfully</div>
            </div>
          </div>
          <div>
            <button class="click_btn" data-dismiss="modal" aria-label="Close" (click)="backToHome()">
              View Agent Details
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
