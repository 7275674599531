<div class="bg-Color">
  <div class="forgotPwd_Sec">
    <div class="logo_sec">
      <a class="" [routerLink]="'/'" routerLinkActive="active">
        <img
          *ngIf="apiService.extractWhiteLabelDomain().TS_ORG !== 'TS_FS'"
          class="img-fluid"
          src="assets/organization-logos/{{ apiService.extractWhiteLabelDomain().TS_ORG }}.svg"
          width="160"
          alt="{{ apiService.extractWhiteLabelDomain().TS_ORG }}"
        />
        <img
          *ngIf="apiService.isFlightsiteDomain()"
          class="img-fluid"
          src="assets/organization-logos/flight-site_logo.png"
          width="160"
          alt="travelstart-logo"
        />
      </a>
    </div>
    <div class="passwordSec">
      <div class="flex-fill">
        <div>
          <div class="tab-content">
            <div class="tab-pane mb-4 active pb-md-280">
              <div class="backArrow mb-2">
                <img
                  src="../../../assets/icons/back-arrow.svg"
                  alt="left-short-arrow"
                  (click)="navigateToLoginPage()"
                />
              </div>
              <div class="heading_Txt">
                <span *ngIf="!isResetPasswordScreen">Forgot your password</span>
                <span *ngIf="isResetPasswordScreen">Please enter your new password</span>
              </div>
              <form
                *ngIf="!isResetPasswordScreen"
                (ngSubmit)="forgetPasswordHandler()"
                [formGroup]="forgetPasswordForm"
              >
                <div class="">
                  <label for="Email" class="form-field">Email</label>
                  <input
                    type="text"
                    #email
                    pattern="[a-zA-Z0-9._+-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}"
                    class="form-control"
                    formControlName="email"
                    autocomplete="username"
                  />

                  <div *ngIf="form.email.errors">
                    <div class="validationError_msg" *ngIf="form.email.errors.pattern">Invalid email address</div>
                  </div>
                  <div *ngIf="isForgotPasswordFormSubmitted && form.email.errors">
                    <div
                      class="validationError_msg"
                      *ngIf="isForgotPasswordFormSubmitted && form.email.errors.required"
                    >
                      Email address is required
                    </div>
                  </div>
                  <div *ngIf="isPasswordResetLinkSent" class="password-rest-link">
                    <div>Password reset link sent to your email.</div>
                  </div>
                  <div class="mt-4 forget-password-btn">
                    <app-loader [spin]="loader" [btnValue]="isPasswordResetLinkSent ? 'Resend' : 'Send'"> </app-loader>
                  </div>
                </div>
              </form>
              <form
                *ngIf="isResetPasswordScreen"
                (ngSubmit)="newPasswordFormSubmissionHandler()"
                [formGroup]="newPasswordForm"
              >
                <div class="">
                  <label for="New Password">New Password</label>

                  <input
                    [type]="!shouldShowPassword ? 'password' : 'text'"
                    #password
                    class="form-control"
                    formControlName="password"
                    autocomplete="off"
                    required
                  />
                  <div class="showEye">
                    <i
                      aria-hidden="true"
                      [ngClass]="[!shouldShowPassword ? 'fa fa-eye-slash' : 'fa fa-eye']"
                      (click)="shouldShowPassword = !shouldShowPassword"
                    ></i>
                  </div>

                  <div *ngIf="newPasswordForm.controls.password.errors">
                    <div class="validationError_msg" *ngIf="newPasswordForm.controls.password.errors.minlength">
                      Password minimum length 6 required
                    </div>
                  </div>
                  <div *ngIf="isResetPasswordFormSubmitted && newPasswordForm.controls.password.errors">
                    <div class="validationError_msg" *ngIf="newPasswordForm.controls.password.errors.required">
                      Password is required
                    </div>
                  </div>
                  <div class="w-100 mt-3">
                    <label for="Confirm Password">Confirm Password</label>
                    <input
                      [type]="!shouldShowConfirmPassword ? 'password' : 'text'"
                      #confirmPassword
                      class="form-control"
                      formControlName="confirmPassword"
                      autocomplete="off"
                      required
                    />
                    <div class="showEye">
                      <i
                        aria-hidden="true"
                        [ngClass]="[!shouldShowConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye']"
                        (click)="shouldShowConfirmPassword = !shouldShowConfirmPassword"
                      ></i>
                    </div>
                  </div>
                  <div *ngIf="newPasswordForm.controls.confirmPassword.errors">
                    <div class="validationError_msg" *ngIf="newPasswordForm.controls.confirmPassword.errors.minlength">
                      Password minimum length 6 required
                    </div>
                  </div>
                  <div *ngIf="isResetPasswordFormSubmitted">
                    <div
                      class="validationError_msg"
                      *ngIf="
                        isResetPasswordFormSubmitted &&
                        newPasswordForm.errors?.passwordNotMatch &&
                        !newPasswordForm.controls.confirmPassword.errors
                      "
                    >
                      Passwords didn’t match
                    </div>
                    <div
                      class="validationError_msg"
                      *ngIf="newPasswordForm?.controls?.confirmPassword?.errors?.required"
                    >
                      Confirm password is required
                    </div>
                  </div>
                  <div class="mt-4 forget-password-btn">
                    <app-loader [spin]="loader" [btnValue]="'Set New Password'"> </app-loader>
                  </div>
                </div>
              </form>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footerSec">
  <app-footer></app-footer>
</div>
