import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@app/general/services/api/api.service';
declare const Checkout: any;
@Component({
  selector: 'app-peach-payment',
  templateUrl: './peach-payment.component.html',
  styleUrls: ['./peach-payment.component.scss'],
})
export class PeachPaymentComponent implements OnInit {
  checkoutId: any = null;
  entityId: any = null;
  constructor(public activatedRoute: ActivatedRoute, private apiService: ApiService) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((val: any) => {
      if (val) {
        this.checkoutId = val.checkoutId;
        this.entityId = val.entityId;
      }
    });
    this.appendPeachScript();
    setTimeout(() => {
      this.initPeachCheckout();
    }, 3000);
  }
  /**here we are adding external script for peach payments */
  appendPeachScript() {
    const scriptEl = window.document.createElement('script');
    let scriptSrc = this.apiService.getPeachScriptUrl();
    scriptEl.src = scriptSrc;
    document.body.appendChild(scriptEl);
  }
  /**here we are initiate peach payment with entityId & checkoutId. these are get fron initiate API response   */
  initPeachCheckout() {
    const checkout = Checkout.initiate({
      key: this.entityId,
      checkoutId: this.checkoutId,
      options: {
        theme: {
          brand: {
            primary: '#FF0000',
          },
          cards: {
            background: '#00FF00',
            backgroundHover: '#F3F3F4',
          },
        },
      },
    });
    checkout.render('#payment-form');
  }
}
