<!--d-commits-->

<div class="bg-Color">
  <div class="usersignUpSec">
    <div class="logo_sec">
      <a class="" [routerLink]="'/'" routerLinkActive="active">
        <img
          *ngIf="apiService.extractWhiteLabelDomain().TS_ORG !== 'TS_FS'"
          class="img-fluid"
          src="assets/organization-logos/{{ apiService.extractWhiteLabelDomain().TS_ORG }}.svg"
          width="160"
          alt="{{ apiService.extractWhiteLabelDomain().TS_ORG }}"
        />
        <img
          *ngIf="apiService.isFlightsiteDomain()"
          class="img-fluid"
          src="assets/organization-logos/flight-site_logo.png"
          width="160"
          alt="travelstart-logo"
        />
      </a>
    </div>
    <div class="userDetailsSec">
      <div class="loginViewSec" *ngIf="isLoginView && !isMessage && !show_OTP_sec">
        <div class="heading_content">
          <div class="heading_txt">Login to continue</div>
          <span class="signup_txt" *ngIf="!apiService.toDisplayForClubhub()"
            >Don’t have an account?
            <span class="signUpLink" (click)="switchToSignIn()">Sign up</span>
          </span>
        </div>
        <div class="login_body">
          <form [formGroup]="loginForm" novalidate>
            <div class="form-group row">
              <div class="col-12 mb-3">
                <label class="form-field d-block">Email</label>

                <input
                  type="text"
                  #email
                  pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
                  class="form-control"
                  formControlName="username"
                  autocomplete="username"
                />
                <div *ngIf="submitLogin && loginForm.get('username').errors">
                  <div
                    translate
                    class="validationError_msg"
                    *ngIf="submitLogin && loginForm.get('username').errors.required"
                  >
                    Email is required.
                  </div>
                  <div
                    translate
                    class="validationError_msg"
                    *ngIf="submitLogin && loginForm.get('username').errors.pattern"
                  >
                    Please enter a valid email
                  </div>
                </div>
              </div>
              <div class="col-12">
                <label class="form-field d-block">Password</label>
                <input
                  [type]="showEyeIcon ? 'password' : 'text'"
                  #password
                  class="form-control"
                  formControlName="password"
                  autocomplete="current-password"
                  required
                />
                <div class="showEye">
                  <i
                    aria-hidden="true"
                    [ngClass]="[showEyeIcon == true ? 'fa fa-eye-slash' : 'fa fa-eye']"
                    (click)="showPwd()"
                  ></i>
                </div>

                <div *ngIf="submitLogin && loginForm.get('password').errors">
                  <div
                    translate
                    class="validationError_msg"
                    *ngIf="submitLogin && loginForm.get('password').errors.required"
                  >
                    Password is required.
                  </div>
                </div>

                <div class="float-right mt-2 forgot_link">
                  <span translate class="reset_btn" style="cursor: pointer" [routerLink]="'/forgot-password'"
                    >Forgot your password?
                  </span>
                </div>
              </div>
            </div>
            <div class="text-center">
              <button class="btn submit_btn primary_CTA" type="submit" (click)="login()">
                <i class="fa fa-spinner fa-spin" style="font-size: 14px" [hidden]="!loader" aria-hidden="true"></i>
                <span translate>Login</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="signUpSec" *ngIf="!isLoginView && !isMessage && !show_OTP_sec">
        <div class="heading_content">
          <div class="heading_txt">Register to continue</div>
          <span class="signup_txt">
            Already have an account?
            <span class="signUpLink" (click)="switchToLogIn()">Login</span>
          </span>
        </div>
        <form
          class="form-horizontal"
          name="signUp_form mb-5"
          [formGroup]="signUpForm"
          (ngSubmit)="signUp()"
          #f="ngForm"
          novalidate
          autocomplete="off"
        >
          <div class="d-none">
            <mat-form-field class="w-100 emaiId" appearance="standard" select>
              <mat-label>Select Product</mat-label>
              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
              <mat-select formControlName="product">
                <mat-option *ngFor="let product of products" [value]="1">Flights</mat-option>
              </mat-select>
              <mat-error *ngIf="isFieldInvalid('product', 'signUp')"> Please select product </mat-error>
            </mat-form-field>
          </div>
          <div class="row mb-1">
            <div class="col-lg-6" *ngIf="isCompany">
              <label>Agency Name</label>
              <input class="form-control" formControlName="agency_name" type="text" required />

              <div *ngIf="submitSignup && signUpForm.get('agency_name').errors">
                <div
                  translate
                  class="validationError_msg"
                  *ngIf="submitSignup && signUpForm.get('agency_name').errors.required"
                >
                  Agency Name is required.
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <label>Contact Person</label>
              <input class="form-control" formControlName="person_name" required />

              <div *ngIf="submitSignup && signUpForm.get('person_name').errors">
                <div
                  translate
                  class="validationError_msg"
                  *ngIf="submitSignup && signUpForm.get('person_name').errors.required"
                >
                  Please enter contact person name
                </div>
                <div
                  translate
                  class="validationError_msg"
                  *ngIf="submitSignup && signUpForm.get('person_name').errors.minlength"
                >
                  person name must be at least 3 characters
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-lg-6">
              <label>Email Address</label>
              <input class="form-control" formControlName="email" required autocomplete="off" />
              <div *ngIf="submitSignup && signUpForm.get('email').errors">
                <div
                  translate
                  class="validationError_msg"
                  *ngIf="submitSignup && signUpForm.get('email').errors.required"
                >
                  Email is required.
                </div>
                <div
                  translate
                  class="validationError_msg"
                  *ngIf="submitSignup && signUpForm.get('email').errors.pattern"
                >
                  Please enter a valid email
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <label>Confirm Email Address</label>
              <input class="form-control" formControlName="confirm_email" required autocomplete="off" />
              <div *ngIf="submitSignup && signUpForm.get('confirm_email').errors">
                <div
                  translate
                  class="validationError_msg"
                  *ngIf="submitSignup && signUpForm.get('confirm_email').errors.required"
                >
                  Email is required.
                </div>
                <div
                  translate
                  class="validationError_msg"
                  *ngIf="submitSignup && signUpForm.get('confirm_email').errors.pattern"
                >
                  Please enter a valid email
                </div>
              </div>
              <div class="validationError_msg" *ngIf="hasDifferentConfirmEmail() && formSubmitAttempt">
                Confirm email doesn't match with email address
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-lg-6">
              <label>Phone number</label>
              <input
                class="form-control"
                appNumbersonly
                type="text"
                ng2TelInput
                [ng2TelInputOptions]="{ separateDialCode: true, formatOnDisplay: true }"
                (intlTelInputObject)="telInputObject($event)"
                (countryChange)="onCountryChange($event)"
                (keypress)="onlyNumbersKeysAllowed($event)"
                formControlName="phone_number"
                name="phone"
              />
            </div>
            <div class="col-lg-6">
              <label>Mobile number</label>
              <input
                class="form-control"
                appNumbersonly
                type="text"
                placeholder=""
                ng2TelInput
                [ng2TelInputOptions]="{ separateDialCode: true, formatOnDisplay: true }"
                (intlTelInputObject)="telInputObject($event)"
                (countryChange)="onCountryChange($event)"
                (keypress)="onlyNumbersKeysAllowed($event)"
                formControlName="mobile_number"
                name="mobile"
                required
              />

              <div *ngIf="submitSignup && signUpForm.get('mobile_number').errors">
                <div
                  translate
                  class="validationError_msg"
                  *ngIf="submitSignup && signUpForm.get('mobile_number').errors.required"
                >
                  Mobile number is required
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-12">
              <label>Address</label>
              <input class="form-control" formControlName="address" required />
              <div *ngIf="submitSignup && signUpForm.get('address').errors">
                <div
                  translate
                  class="validationError_msg"
                  *ngIf="submitSignup && signUpForm.get('address').errors.required"
                >
                  Address is required
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-lg-6">
              <label>Country</label>
              <input
                type="text"
                class="form-control"
                formControlName="ngCountry"
                *ngIf="apiService.extractWhiteLabelDomain().ts_Country == 'NG'"
                readonly
                required
              />
              <mat-select
                placeholder="Country"
                formControlName="country"
                class="form-control"
                (selectionChange)="selectCountry($event.value)"
                required
                *ngIf="apiService.extractWhiteLabelDomain().ts_Country !== 'NG'"
              >
                <mat-option *ngFor="let country of countryList" [value]="country.country_code">
                  {{ country.country_name }}
                </mat-option>
              </mat-select>
              <div *ngIf="submitSignup && signUpForm.get('country').errors">
                <div
                  translate
                  class="validationError_msg"
                  *ngIf="submitSignup && signUpForm.get('country').errors.required"
                >
                  Please Select Country
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <label>City</label>
              <mat-select
                class="form-control"
                formControlName="city"
                (selectionChange)="getLocalGovAreas($event.value)"
                required
              >
                <mat-option *ngFor="let city of cityList" [value]="city.name">
                  {{ city.name }}
                </mat-option>
              </mat-select>
              <div *ngIf="submitSignup && signUpForm.get('city').errors">
                <div
                  translate
                  class="validationError_msg"
                  *ngIf="submitSignup && signUpForm.get('city').errors.required"
                >
                  Please Select City
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-12 col-lg-6">
              <label translate>Local Governance Area</label>
              <mat-select
                class="form-control"
                formControlName="local_gov_area"
                required
                *ngIf="
                  !signUpForm.get('country').value ||
                  (signUpForm.get('country').value && signUpForm.get('country').value == 'NG')
                "
              >
                <mat-option *ngFor="let local_gov_area of local_gov_areas" [value]="local_gov_area.name">
                  {{ local_gov_area.name }}
                </mat-option>
              </mat-select>
              <input
                *ngIf="signUpForm.get('country').value && signUpForm.get('country').value !== 'NG'"
                class="form-control"
                formControlName="local_gov_area"
                type="text"
                required
              />

              <div *ngIf="submitSignup && signUpForm.get('local_gov_area').errors">
                <div
                  translate
                  class="validationError_msg"
                  *ngIf="submitSignup && signUpForm.get('local_gov_area').errors.required"
                >
                  <span translate>Local Governance Area</span> is required
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <label>Website Address</label>
              <input type="text" name="website" class="form-control" formControlName="website" autocomplete="false" />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <label>Password</label>
              <input
                class="form-control"
                autocomplete="new-password"
                formControlName="password"
                [type]="shouldShowPassword ? 'text' : 'password'"
                required
              />
              <div *ngIf="submitSignup && signUpForm.get('password').errors">
                <div
                  translate
                  class="validationError_msg"
                  *ngIf="submitSignup && signUpForm.get('password').errors.required"
                >
                  Please enter password
                </div>
              </div>
              <span tabindex="-1" class="eye-button" (click)="shouldShowPassword = !shouldShowPassword">
                <img
                  class="img_cls"
                  [src]="
                    shouldShowPassword
                      ? '../../../../assets/icons/eye.svg'
                      : '../../../../assets/icons/eye_cross_icon.svg'
                  "
                  alt="eye-icon"
                />
              </span>
            </div>
            <div class="col-sm-6">
              <label>Confirm Password</label>
              <input
                autocomplete="off"
                class="form-control"
                formControlName="confirmpassword"
                [type]="shouldShowConfirmPassword ? 'text' : 'password'"
                required
              />
              <div *ngIf="submitSignup && signUpForm.get('confirmpassword').errors">
                <div
                  translate
                  class="validationError_msg"
                  *ngIf="submitSignup && signUpForm.get('confirmpassword').errors.required"
                >
                  Please enter password
                </div>
              </div>
              <div class="validationError_msg" *ngIf="hasDifferentConfirmPassword() && formSubmitAttempt">
                Confirm password doesn't match with password
              </div>
              <span tabindex="-1" class="eye-button" (click)="shouldShowConfirmPassword = !shouldShowConfirmPassword">
                <img
                  class="img_cls"
                  [src]="
                    shouldShowConfirmPassword
                      ? '../../../../assets/icons/eye.svg'
                      : '../../../../assets/icons/eye_cross_icon.svg'
                  "
                  alt="eye-icon"
                />
              </span>
            </div>
          </div>
          <div class="accept_TC mt-3">
            <div class="form-element">
              <mat-checkbox formControlName="acceptTerms" required>
                <span class="text-wrap">
                  Accept <span translate>travelstart</span>
                  <a translate href="{{ 'terms&conditionsLink' | translate }}" target="blank">T&C</a>
                  and
                  <a translate href="{{ 'terms&conditionsLink' | translate }}" target="blank">Subscription Policy</a>
                </span>
              </mat-checkbox>
              <mat-error class="fs-75" *ngIf="formSubmitAttempt && signUpForm.controls.acceptTerms.errors">
                Please read the T&C and Subscription Policy
              </mat-error>
            </div>
          </div>
          <div class="marginTop20 text-center">
            <button type="submit" class="btn submit_btn primary_CTA">
              <i class="fa fa-spinner fa-spin" [hidden]="!isSigningUp" aria-hidden="true"></i>
              Register
            </button>
          </div>
          <div class="marginTop20 text-center" *ngIf="apiService.isFlightsiteDomain()">
            <a
              class="btn KYC_Sec"
              (click)="downloadKYC_Form()"
              download="flightsite-agreement"
              target="_blank"
              href="/assets/PDF-files/FS-Agreement.pdf"
            >
              Complete full <span class="kyc_txt">KYC</span> verification
            </a>
          </div>
        </form>
      </div>
      <div class="loginViewSec" *ngIf="show_OTP_sec">
        <app-otp-verification></app-otp-verification>
      </div>

      <div class="signUp_page" *ngIf="isMessage">
        <div class="mb-4 marginTop50 d-none d-lg-block success-msg-lg">
          {{ successMsg }}
        </div>
        <div class="d-lg-none marginTop50 success-msg-md">
          {{ successMsg }}
          <div class="mt-5 d-flex justify-content-center">
            <div class="long-arrow-left d-lg-none" [routerLink]="'/'"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer_sec">
  <app-footer></app-footer>
</div>
