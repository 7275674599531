import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { isEmployee, isSuperAdmin } from '@app/@shared/role.constants';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  http: HttpClient;

  // private showHeader: BehaviorSubject<boolean>;
  showHeader$ = new BehaviorSubject<any>(true);
  public userObj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public userObj$ = this.userObj.asObservable();
  userValue = new BehaviorSubject(this.getUser());
  constructor(http: HttpClient) {
    this.http = http;
    this.showHeader$ = new BehaviorSubject<boolean>(true);
  }

  getUser() {
    return sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
  }

  setUser(user: any) {
    this.userValue.next(user);
    sessionStorage.setItem('user', JSON.stringify(user));
  }

  setAuthToken(res: any) {
    sessionStorage.setItem('accessTokenData', JSON.stringify(res));
  }

  getAuthToken() {
    return sessionStorage.getItem('accessTokenData')
      ? JSON.parse(sessionStorage.getItem('accessTokenData'))?.access
      : null;
  }

  clearAuthToken() {
    sessionStorage.clear();
  }

  logout() {
    this.clearAuthToken();
    //this.router.navigate(['/']);
  }

  highlightMutltipleListItems(arr: any, item: any) {
    var pos = arr.indexOf(item);
    if (pos == -1) {
      return arr.push(item);
    } else {
      return arr.splice(pos, 1);
    }
  }

  getInitials = (name: any) => {
    return name
      ?.trim()
      .split(' ')
      .reduce((acc: string, curr: string, index: number) => {
        if (index === 0 || index === 1) acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        return acc;
      }, '');
  };

  hasPermission(permission: number) {
    const user = this.getUser();
    return user.permission.some((each: number) => each === permission);
  }
  isAdminUser() {
    const user = this.getUser();
    return Boolean(isSuperAdmin(user?.role) || isEmployee(user?.role));
  }
}
