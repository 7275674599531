export const emailRegularExpression =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const numberRegularExpression = /^\d+$/;
// restrict user to not to type any numbers and special chars allow  alphabets & space only
export function keyboardAllowCharsOnly(event: any) {
  return (
    (event.charCode > 64 && event.charCode < 91) ||
    (event.charCode > 96 && event.charCode < 123) ||
    event.charCode == 32
  );
}
// restrict user to not to type characters for number inputs
export function numInputNoChars(event: any) {
  return event.charCode == 8 || event.charCode == 0 ? null : event.charCode >= 48 && event.charCode <= 57;
}
