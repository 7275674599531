<div>
  <ul class="nav nav-tabs justify-content-between" id="tabId" role="tablist">
    <li class="nav-item" *ngFor="let tab of tabs">
      <a
        class="nav-link"
        [ngClass]="tab === selectedTab ? 'active' : ''"
        [id]="tab"
        data-toggle="tab"
        role="tab"
        aria-controls="all"
        aria-selected="true"
        (click)="handleTabSelection(tab)"
      >
        {{ tab }}
      </a>
    </li>
  </ul>
</div>
