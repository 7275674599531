export const THEMES = {
  default: {
    /**text Colors */
    text_primary_Color: '#062231',
    text_secondary_Color: '#1db2f5',
    text_info_Color: '#6d7a83',
    text_link_Color: '#1db2f5',
    text_warning_Color: '#CE7E07',
    text_success_Color: '#2aa04a',
    text_danger_Color: '#f51b46',
    /**bgColors */
    body_Bg_Color: '#eef3f8',
    secondary_Body_bg: '#E9ECEF',
    active_Bg_Color: '#1db2f5',
    sidenav_Bg_Color: '#0e0e23',
    addMarkup_Bg_Color: '#e9eef6',
    tab_default_Bg_Color: '#e9ecef',
    whySec_Bg_Color: '#e4f0fe',
    footer_Bg_Color: '#1e3945',
    sidenavTab_Active_Bg_Color: '#0e2133',
    walletcard_Bg_Color: 'linear-gradient(150deg, #464589 29%, #0f0f44 82%)',
    datatable_Row_Bg_Color: '#fafafc',
    paymentHeader_Bg_Color: '#E9EEF6',
    /*input Color*/
    input_Label_Color: '#667085',
    input_Border_Color: '#d8d5cd',
    /**Button Colors */
    primary_btn_Bg_Color: '#1db2f5',
    success_btn_Bg_Color: '#15c4a1',
    btn_outline_Color: '#1db2f5',
    /**borders */
    border_radius: '8px',
    border_default_Color: '#e7e8ea',
    border_light_Color: '#f2f4f3',
    border_active_Color: '#1db2f5',
    /**fa-Icons Colors */
    fa_eye_Color: '#b4bdc1',
    /**layover Colors */
    layover_bg_color: '#FFFAEB',
    layover_text_color: '#B54708',
  },
  flightsite: {
    /**text Colors */
    text_primary_Color: '#062231',
    text_secondary_Color: '#1db2f5',
    text_info_Color: '#6d7a83',
    text_link_Color: '#1db2f5',
    text_warning_Color: '#CE7E07',
    text_success_Color: '#2aa04a',
    text_danger_Color: '#f51b46',
    /**bgColors */
    body_Bg_Color: '#eef3f8',
    secondary_Body_bg: '#E9ECEF',
    active_Bg_Color: '#1db2f5',
    sidenav_Bg_Color: '#0e0e23',
    addMarkup_Bg_Color: '#e9eef6',
    tab_default_Bg_Color: '#e9ecef',
    whySec_Bg_Color: '#e4f0fe',
    footer_Bg_Color: '#1e3945',
    sidenavTab_Active_Bg_Color: '#0e2133',
    walletcard_Bg_Color: 'linear-gradient(150deg, #464589 29%, #0f0f44 82%)',
    datatable_Row_Bg_Color: '#fafafc',
    paymentHeader_Bg_Color: '#E9EEF6',
    /*input Color*/
    input_Label_Color: '#667085',
    input_Border_Color: '#d8d5cd',
    /**Button Colors */
    primary_btn_Bg_Color: '#1db2f5',
    success_btn_Bg_Color: '#15c4a1',
    btn_outline_Color: '#1db2f5',
    /**borders */
    border_radius: '8px',
    border_default_Color: '#e7e8ea',
    border_light_Color: '#f2f4f3',
    border_active_Color: '#1db2f5',
    /**fa-Icons Colors */
    fa_eye_Color: '#b4bdc1',
    /**layover Colors */
    layover_bg_color: '#FFFAEB',
    layover_text_color: '#B54708',
  },
  clubhub: {
    /**text Colors */
    text_primary_Color: '#030000',
    text_secondary_Color: '#9D1F22',
    text_info_Color: '#6d7a83',
    text_link_Color: '#9D1F22',
    text_warning_Color: '#CE7E07',
    text_success_Color: '#2aa04a',
    text_danger_Color: '#f51b46',
    /**bgColors */
    body_Bg_Color: '#eef3f8',
    secondary_Body_bg: '#E9ECEF',
    active_Bg_Color: '#9D1F22',
    sidenav_Bg_Color: '#000000',
    addMarkup_Bg_Color: '#e9eef6',
    tab_default_Bg_Color: '#e9ecef',
    whySec_Bg_Color: '#e4f0fe',
    footer_Bg_Color: '#1e3945',
    sidenavTab_Active_Bg_Color: '#9D1F22',
    walletcard_Bg_Color: 'linear-gradient(28deg, #5B616E 6%, #030000 95%)',
    datatable_Row_Bg_Color: '#F2F4F7',
    paymentHeader_Bg_Color: '#E1E6EF',
    /*input Color*/
    input_Label_Color: '#667085',
    input_Border_Color: '#C6D0D8',
    /**Button Colors */
    primary_btn_Bg_Color: '#9D1F22',
    success_btn_Bg_Color: '#15c4a1',
    btn_outline_Color: '#9D1F22',
    /**borders */
    border_radius: '8px',
    border_default_Color: '#e7e8ea',
    border_light_Color: '#f2f4f3',
    border_active_Color: '#9D1F22',
    /**fa-Icons Colors */
    fa_eye_Color: '#b4bdc1',
    /**layover Colors */
    layover_bg_color: '#FFFAEB',
    layover_text_color: '#B54708',
  },
};
