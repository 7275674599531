import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from '@app/@core/services/rest.service';
import { AuthenticationService } from '../authentication.service';
import { ResponsiveService } from '@app/@core/services/responsive.service';
import { passwordValidator } from '@app/general/services/validations/passwordValidators';
import { get } from 'lodash';
import { ApiService } from '@app/general/services/api/api.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  uid: any;
  token: any;
  loader: boolean = false;
  newPasswordForm: FormGroup;
  forgetPasswordForm: FormGroup;
  shouldShowPassword: boolean = false;
  shouldShowConfirmPassword: boolean = false;
  isResetPasswordScreen: boolean = false;
  isPasswordResetLinkSent: boolean = false;
  isResetPasswordFormSubmitted: boolean = false;
  isForgotPasswordFormSubmitted: boolean = false;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  constructor(
    public router: Router,
    private fb: FormBuilder,
    public route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    public restService: RestService,
    public authService: AuthenticationService,
    public responsiveService: ResponsiveService,
    public apiService: ApiService
  ) {
    if (location.pathname == '/resetPassword') {
      this.isResetPasswordScreen = true;
      const params = this.route.snapshot.queryParams;
      this.uid = params.id;
      this.token = params.token;
      this.resetPasswordReqValidator();
      this.initNewPasswordForm();
    }
  }

  get form() {
    return this.forgetPasswordForm.controls;
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.initForgotPasswordForm();
  }

  initForgotPasswordForm() {
    this.forgetPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9._+-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
    });
  }

  initNewPasswordForm() {
    this.newPasswordForm = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      },
      { validators: passwordValidator.bind(this) }
    );
  }

  forgetPasswordHandler() {
    this.isForgotPasswordFormSubmitted = true;
    this.isPasswordResetLinkSent = false;
    if (this.forgetPasswordForm.invalid) return;
    else {
      this.loader = true;
      const reqObj = {
        email: this.forgetPasswordForm.get('email').value,
      };
      this.restService.forgotPasswordRequest(reqObj).subscribe(
        (res: any) => {
          if (res) {
            this.loader = false;
            this.isPasswordResetLinkSent = true;
          } else {
            this.loader = false;
          }
        },
        (errors) => {
          console.log(errors);
          this.loader = false;
          this._snackBar.open(
            `${get(errors, 'error.message', 'Unable to login right now, Please try again later.')}`,
            'X'
          );
        }
      );
    }
  }

  resetPasswordReqValidator() {
    const reqObj = {
      uid: this.uid,
      token: this.token,
    };
    this.restService.forgotPasswordValidation(reqObj).subscribe(
      (res: any) => {},
      (errors) => {
        this._snackBar.open('Password reset link is expired. please try again ', 'X');
        setTimeout(() => {
          this._snackBar.dismiss();
          this.isResetPasswordScreen = false;
        }, 10000);
      }
    );
  }

  newPasswordFormSubmissionHandler() {
    this.isResetPasswordFormSubmitted = true;
    if (this.newPasswordForm.invalid) return;
    else {
      this.loader = true;
      const reqObj = {
        uid: this.uid,
        token: this.token,
        new_password: this.newPasswordForm.get('password').value,
        confirm_password: this.newPasswordForm.get('confirmPassword').value,
      };
      this.restService.resetPassword(reqObj).subscribe(
        (res: any) => {
          if (res.success) {
            this.loader = false;
            this._snackBar.open('Password updated successfully.', 'X');
            this.navigateToLoginPage();
          }
        },
        (errors) => {
          this.loader = false;
          this._snackBar.open('Password and Confirm password does not match', 'X');
        }
      );
    }
  }

  navigateToLoginPage() {
    this.router.navigate(['register'], {
      queryParams: {
        tab: 'login',
      },
    });
  }
}
