import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from '@app/@core/services/utility.service';
import { AgentService } from '@app/agent/services/agent.service';
import { AuthenticationService } from '@app/auth/authentication.service';
import { CredentialsService } from '@app/auth/credentials.service';
import { homepagePath } from '@app/auth/routing_path';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { isAgent, isSuperAdmin, isEmployee, PERMISSIONS } from '@app/@shared/role.constants';
import { ResponsiveService } from '@app/@core/services/responsive.service';
import { ApiService } from '../../../general/services/api/api.service';
import { SharedCommonService } from '@app/@shared/services/shared-common.service';

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss'],
})
export class LoginHeaderComponent implements OnInit {
  isLoggedIn: any;
  showHeadActions$: Observable<boolean>;
  @Output() buttonClicked = new EventEmitter();
  bodyDiv = false;
  user: any;
  emails: any = [];
  isAgent: boolean;
  isSuperAdmin: boolean;
  isEmployee: boolean;
  queryParams: any = {};
  selected_tab: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    public utilityService: UtilityService,
    public authService: AuthenticationService,
    public agentService: AgentService,
    public sharedService: SharedCommonService,
    public responsiveservice: ResponsiveService,
    public apiService: ApiService
  ) {
    this.showHeadActions$ = this.authService.isHeaderActions;
    this.isLoggedIn = this.utilityService.getAuthToken();
    this.user = this.utilityService.getUser();
    this.isAgent = isAgent(this.user.role);
    this.isSuperAdmin = isSuperAdmin(this.user.role);
    this.isEmployee = isEmployee(this.user.role);
  }

  ngOnInit(): void {
    this.user = this.utilityService.getUser();
    if (!this.isSuperAdmin && this.hasPermissionForMailInbox()) this.fetchEmails();
  }

  fetchEmails() {
    this.sharedService.getPriceUpdationEmails(this.queryParams).subscribe((res: any) => {
      if (res.success) this.emails = get(res, 'data', []);
    });
  }

  routToSignUp() {}

  logout() {
    this.authenticationService.logout();
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.username : null;
  }

  createUser() {
    if (this.isSuperAdmin || this.isEmployee) {
      this.router.navigate([`${homepagePath(this.user)}/create-user-panel`], {
        queryParams: {
          tab: 'tsEmployee',
        },
      });
    } else {
      this.router.navigate([`${homepagePath(this.user)}/create-user`]);
    }
  }
  profile() {
    this.router.navigate([`${homepagePath(this.user)}/create-user`], {
      queryParams: {
        tab: 'Profile',
      },
    });
  }
  homePage() {
    this.agentService.selectedView = 'booking_history';
    this.router.navigate([this.route.snapshot.queryParams.redirect || homepagePath(this.user)], { replaceUrl: true });
    sessionStorage.removeItem('priceData');
  }
  staffList() {
    if (this.isSuperAdmin || this.isEmployee) {
      this.router.navigate([`${homepagePath(this.user)}/user-list-panel`], {
        queryParams: {
          tab: 'tsEmployee',
        },
      });
    } else {
      this.router.navigate([`${homepagePath(this.user)}/company-staff`]);
    }
  }

  getLoggedInUserInitials() {
    return this.utilityService.getInitials(this.user.name);
  }

  navigateToEmailsInbox() {
    this.router.navigate([`${homepagePath(this.user)}/mails`]);
  }

  navigateToAuditLogs() {
    this.router.navigate([`${homepagePath(this.user)}/audit-logs`]);
  }
  hasPermissionToViewLogsTSEmp() {
    return this.utilityService.hasPermission(PERMISSIONS.logAdminTSE);
  }
  hasPermissionToViewLogsAgent() {
    return this.utilityService.hasPermission(PERMISSIONS.logAdmin);
  }
  hasPermissionToCreateUserTSEmp() {
    return this.utilityService.hasPermission(PERMISSIONS.myAccountAdminTSE);
  }
  hasPermissionForStaffCreationAgent() {
    return this.utilityService.hasPermission(PERMISSIONS.myAccountAdmin);
  }
  hasPermissionForProfileAgent() {
    return this.utilityService.hasPermission(PERMISSIONS.logAdmin);
  }
  hasPermissionForWalletAgent() {
    return this.utilityService.hasPermission(PERMISSIONS.walletAdmin);
  }
  hasPermissionForMailInbox() {
    return false;
    /**we can enable once create deeplinks for itin in mails */
    return this.utilityService.hasPermission(PERMISSIONS.editflightprice);
  }
  hasPermissionForUserCreation() {
    return this.hasPermissionToCreateUserTSEmp() || this.hasPermissionForStaffCreationAgent() || this.isSuperAdmin;
  }
  hasPermissionForStaffList() {
    return this.hasPermissionToCreateUserTSEmp() || this.hasPermissionForStaffCreationAgent() || this.isSuperAdmin;
  }
  hasPermissionForAuditLog() {
    return this.hasPermissionToViewLogsTSEmp() || this.hasPermissionToViewLogsAgent() || this.isSuperAdmin;
  }
  hasPermissionToAccessFlightSearch() {
    return this.utilityService.hasPermission(PERMISSIONS.flightSearch);
  }
  hasPermissionForReserveBookings() {
    return this.utilityService.hasPermission(PERMISSIONS.HOLD_AND_PAY);
  }
  hasPermissionForCardLink() {
    return this.utilityService.hasPermission(PERMISSIONS.PAYMENT_LINK);
  }
  get isAgentLoginUserHeader(): boolean {
    return window.location.href.includes('agent') && !this.isSuperAdmin && !this.isEmployee ? true : false;
  }
  hasPermissionToAddMarkup() {
    return this.utilityService.hasPermission(PERMISSIONS.markupAdmin);
  }
  navigateToFlights() {
    this.router.navigate(['/bookingFlights']);
  }
  navigateToBuses() {
    window.open(`http://buses.flightsite.co.za/index.jsp?referral=${this.user?.frag_id} `, '_blank');
  }
  navigateToAccomodation() {
    window.open(`https://www.ebedsportal.com/profile.php `, '_blank');
  }
  navigateToReservedBookings() {
    this.router.navigate([`${homepagePath(this.user)}/reserved-bookings`]);
  }
  navigateToPaymentLink() {
    this.router.navigate([`${homepagePath(this.user)}/generate-paymentlink`]);
  }
  navigateToDocuments() {
    this.router.navigate([`${homepagePath(this.user)}/documents`]);
  }
  navigateToAddmarkup() {
    this.router.navigate([`${homepagePath(this.user)}/add-markup`]);
  }
  navigateToBookings() {
    this.router.navigate([`${homepagePath(this.user)}/bookings`]);
  }
  navigateToPayments() {
    this.router.navigate([`${homepagePath(this.user)}/payments`]);
  }
  navigateToTravellers() {
    this.router.navigate([`${homepagePath(this.user)}/travellers`]);
  }
  navigateToWallet() {
    this.router.navigate([`${homepagePath(this.user)}/wallet`]);
  }
  navigateToRejectionList() {
    this.router.navigate([`${homepagePath(this.user)}/ticketing-rejections`]);
  }
  navigateTocontactUs() {
    this.router.navigate([`${homepagePath(this.user)}/contact-us`]);
  }
  hasPermissionForReservedBookings() {
    return this.utilityService.hasPermission(PERMISSIONS.HOLD_AND_PAY);
  }

  hasPermissionToAccessDashboardAPIs() {
    return this.utilityService.hasPermission(PERMISSIONS.analyticsAdmin);
  }
  hasPermissionForTicketingRejections() {
    return this.utilityService.hasPermission(PERMISSIONS.TICKETING_REJECTION);
  }
  openLink(link: any) {
    window.open(link, '_blank');
  }
  /**To Navigate to meili widget component for book crs */
  navigateToBookCars() {
    this.router.navigate(['/bookingCars']);
  }
}
