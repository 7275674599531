<div class="homepageSec">
  <app-header (scrollToAboutUs)="scrollToAboutUs()"></app-header>

  <div class="position-relative bannerSec">
    <img
      src="../../assets/images/landing-hero-bg.svg"
      class="position-absolute w-100 h-100 banner-bg"
      alt="Landing image"
    />
    <div class="container">
      <div class="row banner-content">
        <div class="col-lg-5 text-center text-lg-left">
          <h1 class="mb-3 titleTxt">
            <span translate>Your all-in-one</span>

            <br class="d-block d-md-none" />
            <span translate>B2B travel platform</span>
          </h1>
          <p class="mb-sm-5 mb-4 title_subTxt" translate>Get everything your business needs, And more.</p>
          <button
            (click)="routToSignUp()"
            class="btn primary_CTA mb-3 px-3 getStarted_CTA"
            *ngIf="!apiService.toDisplayForClubhub()"
          >
            <div class="d-flex" translate>Get started</div>
          </button>
        </div>
        <div class="col-lg-7 text-center snippet-p-sm">
          <img
            *ngIf="apiService.extractWhiteLabelDomain().TS_ORG"
            class="TSORG_Logo"
            src="../../assets/landing-page-images/{{ apiService.extractWhiteLabelDomain().TS_ORG }}-dashboard.svg"
            alt="{{ apiService.extractWhiteLabelDomain().TS_ORG }}"
          />
        </div>
      </div>
    </div>
  </div>
  <app-signin></app-signin>

  <div #aboutUs class="aboutSec" *ngIf="!apiService.toDisplayForClubhub()">
    <div class="container">
      <div class="text-center pb-4">
        <h1 class="aboutSecTitle mb-2 mt-4 mt-lg-0" translate>Travelstart Business</h1>
      </div>

      <div class="row">
        <div class="col-lg-6 mb-lg-0 mb-4">
          <div class="platform-card h-100">
            <div class="row">
              <div class="col-md-8">
                <h4 class="cardHeadTxt" translate>
                  All-In-One <br class="d-none d-lg-block" />
                  Platform
                </h4>
                <p class="mb-5 card_desc" translate>
                  <span translate>Travelstart Business allows you to book flights, accommodation,</span>
                  <span translate> car rental & more - book and complete your entire trip all at once.</span>
                </p>
                <button (click)="routToSignUp()" class="btn primary_CTA getStarted_CTA" translate>
                  Register Now
                  <img src="./../../assets/icons/right-arrow-white.svg" class="ml-3" width="20px" alt="arrow" />
                </button>
              </div>
              <div class="col-md-4 img-aside mb-5 mb-md-0 d-none d-md-block">
                <img
                  src="./../../assets/landing-page-images/all-in-one-platform.svg"
                  class="w-100"
                  alt="all-in-one-platform"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 d-flex flex-column">
          <div class="platform-card mb-4">
            <div class="row">
              <div class="col-md-8">
                <h4 translate class="cardHeadTxt">Pay in Local Currency</h4>
                <p translate class="card_desc">ZAR, NG, USD, EUR, GBP & much more.</p>
              </div>
              <div class="col-md-4 text-right img-aside mb-5 mb-md-0 d-none d-md-block">
                <img
                  src="./../../assets/landing-page-images/home-paymentadmin.svg"
                  width="76"
                  class="img-fluid"
                  alt="home-paymentadmin"
                />
              </div>
            </div>
          </div>

          <div class="platform-card mt-auto">
            <div class="row">
              <div class="col-md-8">
                <h4 translate class="cardHeadTxt">Guaranteed Savings</h4>
                <p translate class="card_desc">Exclusive deals and rewards, no booking fees and no platform cost.</p>
              </div>
              <div class="col-md-4 text-right img-aside mb-5 mb-md-0 d-none d-md-block">
                <img
                  src="./../../assets/landing-page-images/home_guaranteedsavings.svg"
                  width="76"
                  class="img-fluid"
                  alt="home_guaranteedsavings"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="why-section mt-5">
      <div class="container-fluid">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
              <div class="why-item d-lg-flex text-center text-lg-left">
                <div class="icon mr-md-4 mb-3 mb-md-0">
                  <img src="./../../assets/landing-page-images/contact-support-icon.svg" alt="contact-support" />
                </div>
                <div class="descSec">
                  <h6 translate class="headTxt">CUSTOMER SUPPORT</h6>
                  <span translate class="subTxt">Multilingual customer </span>
                  <span translate class="subTxt">support service</span>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg-3 mb-5 mb-lg-0">
              <div class="why-item">
                <div class="icon mr-md-4 mb-3 mb-md-0">
                  <img src="./../../assets/landing-page-images/savemore-icon.svg" alt="savemore" />
                </div>
                <div class="descSec">
                  <h6 translate class="headTxt">SAVE MORE</h6>
                  <span translate class="subTxt"
                    >Save more with the best deals and cashbacks across flights and hotels</span
                  >
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg-3 mb-5 mb-md-0">
              <div class="why-item">
                <div class="icon mr-md-4 mb-3 mb-md-0">
                  <img src="./../../assets/landing-page-images/safe-secure-icon.svg" alt="secure-payment" />
                </div>
                <div class="descSec">
                  <h6 translate class="headTxt">SAFE AND SECURE</h6>
                  <span translate class="subTxt">100% safe and secure payments</span>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="why-item">
                <div class="icon mr-md-4 mb-3 mb-md-0">
                  <img src="./../../assets/landing-page-images/trusted-many-icon.svg" alt="trust" />
                </div>
                <div class="descSec">
                  <h6 translate class="headTxt">TRUSTED BY MANY</h6>
                  <span translate class="subTxt">Over 500,000+ satisfied customer bookings and counting</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
