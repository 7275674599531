import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomMaterialModule } from './custom-material/custom-material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { environment } from '@env/environment';
import { CoreModule } from '@core';
import { SharedModule } from '@shared/shared.module';
import { AuthModule } from '@app/auth/auth.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { TranslateModule } from '@ngx-translate/core';
import { WINDOW_PROVIDERS } from 'src/assets/window.providers';
import { I18nModule } from './i18n/i18n.module';
import { PaymentService } from './payment/service/payment.service';
import { DatePipe } from '@angular/common';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CustomMaterialModule,
    ReactiveFormsModule,
    RouterModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    HttpClientModule,
    NgbModule,
    CoreModule,
    SharedModule,
    AuthModule,
    AppRoutingModule,
    NgxDatatableModule,
    RichTextEditorAllModule,
    NgxChartsModule,
    I18nModule,
    TranslateModule.forRoot(),
    NgxWebstorageModule.forRoot(), // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent, HomeComponent],
  providers: [PaymentService, WINDOW_PROVIDERS, DatePipe],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
