export const WEB_API_PATH = '/website-services/api';
export const WEB_APP_TRANSLATIONS = '/webAppTranslations';
export const AUTOCOMPLETE_PATH = '/servlet/lc.json';
export const AUTOCOMPLETE_AIRLINE_PATH = '/servlet/al.json';
export const AUTOCOMPLETE_DISPLAY_ITEM_PATH = '/ac-location';
export const LOG_ERROR_PATH = 'log-error';
export const AFFILIATE_PATH = '/affiliate';
export const LOYALTY_SUBSCRIBE_PATH = '/validate-loyalty';
export const TESTS_PATH = '/web-app-tests';
export const COUNTRIES_PATH = '/countries';
export const SEARCH_PATH = '/search';
export const PRICE_PATH = '/price';
export const START_WEB_CHAT_PATH = '/start-chat';
export const SEND_CHAT_PATH = '/send-chat-message';
export const ENQUIRIES_PATH = '/enquiries';
export const NEWSLETTER_SIGNUP_PATH = '/newsletter-sign-up';
export const SEND_QUOTE_EMAIL_PATH = '/send-quote';
export const VIEW_ITINERARY_PATH = '/view-itinerary';
export const PAYMENT_METHODS_PATH = '/payment-methods';
export const BOOK_FLIGHTS_PATH = '/book';
export const VALIDATE_VOUCHER_PATH = '/validate-voucher';
export const GATEWAY_AUTH_BOOK_FLIGHTS_PATH = '/gateway-auth-book';
export const REDIRECT_GATEWAY_AUTH_PATH = '/redirect-gateway-auth';
export const APPLY_UNBUNDLED_TRAVEL_POLICY = '/apply-unbundled-travel-policy';
export const FOOTER_PATH = '/api/footers';
export const WEBAPI_PATH = '/webapi';
export const PAYMEMT_GATEWAY = 'payment-gateway';
export const PAYMENT_LINK = '/payment-link';

/**
 * api server path
 */
export const API_SERVER_LIVE_PATH = 'https://wapi.travelstart.com';
export const API_SERVER_PREPROD_PATH = 'https://wapi.travelstart.com';
export const API_SERVER_BETA_PATH = 'https://beta-wapi.travelstart.com';

/**
 * Airports path
 */
export const AIRPORTS_LIVE_PATH = 'https://lapi.travelstart.com';
export const AIRPORTS_PREPROD_PATH = 'https://lapi.travelstart.com';
export const AIRPORTS_BETA_PATH = 'https://beta-lapi.travelstart.com';

export const COUNTRIES_BETA_PATH = 'https://betaza.travelstart.com';

/**
 * Loco paths
 */
export const LOCO_API_BETA_PATH = 'https://beta-loco.travelstart.com/';
export const LOCO_API_ALPHA_PATH = 'https://alpha-loco.travelstart.com/';
export const LOCO_API_PREPROD_PATH = 'https://preprod-loco.travelstart.com/';
export const LOCO_API_LIVE_PATH = 'https://loco.travelstart.com/';
export const LOCO_TRANSLATIONS_PATH = 'translations/web';

/**
 * Marketing CMS paths
 */
export const MARKETING_CMS_LIVE_PATH = 'cms.travelstart.com/';
export const MARKETING_CMS_TEST_PATH = 'betacms.travelstart.com/';

/**
 * Account paths
 */
export const MY_ACCOUNT_LIVE_PATH: string = 'https://mag.travelstart.com/';
export const MY_ACCOUNT_TEST_PATH: string = 'https://dev-mag.travelstart.com/';
export const MY_ACCOUNT_PREPROD_PATH: string = 'https://alpha-mag.travelstart.com/';
export const ACCOUNT_AUTH_SIGNIN_PATH = 'api/v3/login';
export const ACCOUNT_AUTH_SIGNOUT_PATH = 'api/v3/logout';
export const ACCOUNT_AUTH_SIGNUP_PATH = 'api/v3/signup';
export const ACCOUNT_FORGOT_PASSWORD_PATH = 'api/v3/reset_password';
export const ACCOUNT_CHANGE_PASSWORD_PATH = 'api/v3/update_password';
export const ACCOUNT_RESET_PASSWORD_PATH = 'api/v3/validate_reset';
export const ACCOUNT_AUTH_ACTIVATE_PATH = 'api/v3/validate_activation';
export const ACCOUNT_DETAILS_PATH = 'api/v3/account';
export const ACCOUNT_BOOKING_HISTORY_PATH = 'api/v3/booking';
export const ACCOUNT_BOOKING_STATUS_PATH = 'api/v3/booking/status';
export const ACCOUNT_SEARCH_HISTORY_PATH = 'api/v3/searches';
export const ACCOUNT_TRAVELLER_PATH = 'api/v3/traveller';
export const ACCOUNT_PAYMENT_CARD_PATH = 'api/v3/payment/card';
export const ACCOUNT_RESEND_ACTIVATION_EMAIL_PATH = 'api/v3/resend-activation-email';

/**
 * Travelstart Account paths
 */
export const TRAVELSTART_ACCOUNT_ALPHA_PATH = 'https://alpha-tsacc.travelstart.com/';
export const TRAVELSTART_ACCOUNT_BETA_PATH = 'https://beta-tsacc.travelstart.com/';
export const TRAVELSTART_ACCOUNT_PREPROD_PATH = 'https://preprod-tsacc.travelstart.com/';
export const TRAVELSTART_ACCOUNT_LIVE_PATH = 'https://tsacc.travelstart.com/';

export const TS_ACCOUNT_CREATE_BUSINESS = '/v1/business-profile/signup';
export const TS_ACCOUNT_SIGNIN_TOKEN_BUSINESS = '/v1/business-profile/signin-token';
export const TS_ACCOUNT_ACTIVATE_BUSINESS = '/v1/business-profile/activate';
export const TS_ACCOUNT_ACTIVATE_BUSINESS_USER = '/v1/business-profile/activate-user';
export const TS_ACCOUNT_RESEND_ACTIVATION_EMAIL_BUSINESS = '/v1/business-profile/resend-activation-email';
export const TS_ACCOUNT_ADD_TRAVELLER_BUSINESS = '/v1/business/traveller/add-traveller';
export const TS_ACCOUNT_EDIT_TRAVELLER_BUSINESS = '/v1/business/traveller/edit-traveller';
export const TS_ACCOUNT_DELETE_TRAVELLER_BUSINESS = '/v1/business/traveller/delete-traveller';
export const TS_ACCOUNT_FETCH_TRAVELLER_BUSINESS = '/v1/business/traveller/fetch-travellers';
export const TS_ACCOUNT_FETCH_TRAVELLER_WITH_LOYALTY_BUSINESS = '/v1/business/traveller/fetch-travellers-with-loyalty';
export const TS_ACCOUNT_FETCH_MY_TRAVELLER_BUSINESS = '/v1/business/traveller/fetch-my-traveller';
export const TS_ACCOUNT_EDIT_MY_TRAVELLER_BUSINESS = '/v1/business/traveller/edit-my-traveller';
export const TS_ACCOUNT_FETCH_EMERGENCY_CONTACT_DETAILS_BUSINESS =
  '/v1/business/traveller/fetch-emergency-contact-details';
export const TS_ACCOUNT_EDIT_EMERGENCY_CONTACT_DETAILS_BUSINESS =
  '/v1/business/traveller/edit-emergency-contact-details';
export const TS_ACCOUNT_RESET_PASSWORD_BUSINESS = '/v1/business-profile/reset-password';
export const TS_ACCOUNT_SEND_RESET_PASSWORD_MAIL_BUSINESS = '/v1/business-profile/send-reset-password-email';
export const TS_ACCOUNT_EDIT_MY_PROFILE_DETAILS_BUSINESS = '/v1/business/traveller/edit-contact-details';
export const TS_ACCOUNT_ADD_CARD_BUSINESS = '/v1/business/card/add-card';
export const TS_ACCOUNT_DELETE_CARD_BUSINESS = '/v1/business/card/delete-card';
export const TS_ACCOUNT_FETCH_CARDS_BUSINESS = '/v1/business/card/fetch-cards';
export const TS_ACCOUNT_FETCH_MY_BOOKING_SUMMARIES_BUSINESS = '/v1/business/booking/get-my-booking-summaries';
export const TS_ACCOUNT_FETCH_MY_BOOKING_DETAILS_BUSINESS = '/api/v3/business/booking/get-my-booking-details';
export const TS_ACCOUNT_FETCH_BOOKING_SUMMARIES_BUSINESS = '/v1/business/booking/get-booking-summaries';
export const TS_ACCOUNT_FETCH_BOOKING_DETAILS_BUSINESS = '/api/v3/business/booking/get-booking-details';
export const TS_ACCOUNT_FETCH_USER_BUSINESS = '/v1/business/user/fetch-users';
export const TS_ACCOUNT_RESEND_ETICKET_BUSINESS = '/v1/business/booking/resend-eticket';
export const TS_ACCOUNT_RESEND_MY_ETICKET_BUSINESS = '/v1/business/booking/resend-my-eticket';
export const TS_ACCOUNT_SET_USER_ROLE_BUSINESS = '/v1/business/user/set-role';
export const TS_ACCOUNT_ADD_USER_BUSINESS = '/v1/business/user/add-user';
export const TS_ACCOUNT_DELETE_USER_BUSINESS = '/v1/business/user/delete-user';
export const TS_ACCOUNT_RESEND_INVITE_BUSINESS = '/v1/business/user/resend-invite';
export const TS_ACCOUNT_FETCH_MY_COMPANY_CONTACT_BUSINESS = '/v1/business/traveller/fetch-my-company-contact-details';
export const TS_ACCOUNT_FETCH_TRAVEL_APPROVERS_BUSINESS = '/v1/business/user/fetch-travel-approvers';
export const TS_ACCOUNT_FETCH_TRAVEL_ARRANGERS_BUSINESS = '/v1/business/user/fetch-travel-arrangers';
export const TS_ACCOUNT_FETCH_CABIN_POLICY_BUSINESS = '/v1/business/policy/fetch-cabin-policy';
export const TS_ACCOUNT_EDIT_CABIN_POLICY_BUSINESS = '/v1/business/policy/edit-cabin-policy';
export const TS_ACCOUNT_FETCH_TRAVEL_POLICY_BUSINESS = '/v1/business/policy/fetch-policy';
export const TS_ACCOUNT_EDIT_DEFAULT_BUDGET_POLICY_BUSINESS = '/v1/business/policy/edit-default-budget-policy';
export const TS_ACCOUNT_EDIT_DURATION_BUDGET_POLICY_BUSINESS = '/v1/business/policy/edit-duration-budget-policy';
export const TS_ACCOUNT_EDIT_TIME_TO_DEPART_POLICY_BUSINESS = '/v1/business/policy/edit-time-to-depart-policy';
export const TS_ACCOUNT_FETCH_BUDGET_ROUTE_POLICY_BUSINESS = '/v1/business/policy/fetch-budget-route-policy';
export const TS_ACCOUNT_ADD_BUDGET_ROUTE_POLICY_BUSINESS = '/v1/business/policy/add-budget-route-policy';
export const TS_ACCOUNT_DELETE_BUDGET_ROUTE_POLICY_BUSINESS = '/v1/business/policy/delete-budget-route-policy';
export const TS_ACCOUNT_EDIT_BUDGET_ROUTE_POLICY_BUSINESS = '/v1/business/policy/edit-budget-route-policy';
export const TS_ACCOUNT_ADD_FREQUENT_FLYER_DETAILS = '/v1/business/traveller/add-my-frequent-flyer-detail';
export const TS_ACCOUNT_EDIT_FREQUENT_FLYER_DETAILS = '/v1/business/traveller/edit-my-frequent-flyer-detail';
export const TS_ACCOUNT_FETCH_MY_FREQUENT_FLYER_DETAILS = '/v1/business/traveller/fetch-my-frequent-flyer-details';
export const TS_ACCOUNT_DELETE_FREQUENT_FLYER_DETAILS = '/v1/business/traveller/delete-my-frequent-flyer-detail';
export const TS_ACCOUNT_EDIT_APPROVAL_PROCESS_POLICY_BUSINESS = '/v1/business/policy/edit-approval-process-policy';
export const TS_ACCOUNT_FETCH_COMPANY_DETAILS = '/v1/business-profile/fetch-company-details';
export const TS_ACCOUNT_EDIT_COMPANY_DETAILS = '/v1/business-profile/edit-company-details';
export const TS_ACCOUNT_ADD_TRAVELLER_FREQUENT_FLYER_DETAILS =
  '/v1/business/traveller/add-traveller-frequent-flyer-detail';
export const TS_ACCOUNT_EDIT_TRAVELLER_FREQUENT_FLYER_DETAILS =
  '/v1/business/traveller/edit-traveller-frequent-flyer-detail';
export const TS_ACCOUNT_DELETE_TRAVELLER_FREQUENT_FLYER_DETAILS =
  '/v1/business/traveller/delete-traveller-frequent-flyer-detail';
export const TS_ACCOUNT_FETCH_INVOICE_DETAILS = '/v1/business/invoice/fetch-invoice-details';
export const TS_ACCOUNT_EDIT_INVOICE_DETAILS = '/v1/business/invoice/edit-invoice-details';

/**
 * Travelstart Analytics paths
 */
export const TS_ANALYTICS_API_LOCAL_PATH = 'http://localhost:7009';
export const TS_ANALYTICS_API_TEST_PATH = 'https://beta-anna.travelstart.com';
export const TS_ANALYTICS_API_PROD_PATH = 'https://anna.travelstart.com';
export const TS_ANALYTICS_SEND_ABANDONED_CART_PATH = '/v1/abandoned-cart';

/**
 * Travelstart Guest user view bookings paths
 */
export const TS_GUEST_USER_VIEW_BOOKINGS_PATH = 'https://betago.travelstart.com';

/**
 * Travelstart Account for Apple Sign in Callback paths
 */
export const TS_APPLE_AUTH_CALLBACK_PATH = '/callback/apple/auth';

/**
 * Guest view bookings path
 */
export const GUEST_VIEW_BOOKINGS_PATH = '/booking-status';

/**
 * footer path
 */

export const FOOTER_LINKS_PATH = 'https://cms.travelstart.com';

/**
 * login path
 */
export const LOGIN_TEST_PATH = 'https://dev-mag.travelstart.com';
export const LOGIN_LIVE_PATH = 'https://mag.travelstart.com';

/**
 *
 */
export const FORGOT_PASSWORD_PATH = 'https://dev-mag.travelstart.com';

/**
 * Popular Routes path
 */

export const POPULAR_ROUTES_PATH = 'https://europe-west2-ts-dev-dataops-prod.cloudfunctions.net/cms';

/**
 * FreshDesk ApI path
 *
 */
export const FRESH_DESK_API_PATH = 'https://travelstartassist.freshdesk.com/api/v2/';
export const FRESH_DESK_EMAILCONFIG = 'email_configs';
export const FRESH_DESK_CREATETICKET = 'tickets';
export const FRESH_DESK_AUTHORIZATION_KEY = 'NmRmbWY0SkJKdXNGZzIxdk0ySA==';
export const FRESH_DESK_GETALLTICKETSBYEMAIL = 'tickets?email=';
export const FRESH_DESK_TICKETCONVERSATIONDETAILS = 'tickets/';

/**
 * B2B ApI paths
 *
 */
export const B2B_API_URL_BETA = 'https://beta-b2b-api.travelstart.com';
export const B2B_API_URL_PREPROD = 'https://preprod-b2b-api.travelstart.com';
export const B2B_API_URL_LIVE = 'https://b2b-api.travelstart.com';
export const B2B_STAGE_PATH = '/b2b-stage';
