import { DomSanitizer } from '@angular/platform-browser';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { AgentService } from '@app/agent/services/agent.service';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '@app/general/services/api/api.service';
import { numInputNoChars } from '@app/general/utils/regex-utils';
declare let $: any;
const CURRENCY = 'currency';
const FAILED = 'failed';

@Component({
  selector: 'app-paystack-payment',
  templateUrl: './paystack-payment.component.html',
  styleUrls: ['./paystack-payment.component.scss'],
})
export class PaystackPaymentComponent implements OnInit {
  currency_code: string;
  paymentGatewayForm = new FormControl('');
  isLoading: boolean = false;
  isSubmitted: boolean = false;
  isAddMoneyViaPaystackInProgress: boolean = false;
  processingDetails: any;
  canProceedToInitiatePayment: boolean = true;
  showPaymentGatewayIframe: boolean = false;
  paystackTargetIFrameUrl: any = null;
  three3DIFrame: boolean = false;
  isPaystackCallbackUrlLoading: boolean = false;
  paymentVerificationDetails: any = null;
  intervalID: any;
  isPaymentProcessing: boolean = false;
  isVaildAmount: boolean = false;
  proceedToAddMoney: boolean = false;
  @Input() paymentDetails: any;
  @Output() closeAddMoneySection = new EventEmitter<any>();
  @Output() IframeDisplayed: EventEmitter<any> = new EventEmitter<boolean>();
  ZA_default_Amount: any = [2000, 5000, 10000];
  NG_default_Amount: any = [2000000, 500000, 1000000];
  constructor(
    private agentService: AgentService,
    private _snackbar: MatSnackBar,
    private _sanitizationService: DomSanitizer,
    public apiService: ApiService
  ) {
    this.currency_code = JSON.parse(sessionStorage.getItem(CURRENCY));
  }
  ngOnInit(): void {}

  selectAmountToBeAdded(data: any) {
    this.paymentGatewayForm.setValue(data);
    this.isVaildAmount = false;
  }

  askConsentToProceedPaymentHandler(event: Event) {
    this.isVaildAmount = event['checked'];
  }

  paymentGatewayHandler() {
    this.isSubmitted = true;
    if (this.paymentGatewayForm.invalid || !this.isVaildAmount) return;
    else {
      this.isLoading = true;
      const reqObj = {
        id: this.paymentDetails[0].id,
        amount: this.paymentGatewayForm.value,
        currency: this.currency_code,
      };
      this.agentService.getProcessingFee(reqObj).subscribe(
        (res: any) => {
          if (res.success) {
            this._snackbar.dismiss();
            this.processingDetails = res.data;
            this.isAddMoneyViaPaystackInProgress = true;
            this.paymentGatewayInitialization();
          } else {
            this.isError();
          }
        },
        (error) => {
          this.isSubmitted = false;
          this.isLoading = false;
          this.isError();
        }
      );
    }
  }
  isError() {
    this._snackbar.open('Something went wrong please try again.', '');
    setTimeout(() => {
      this._snackbar.dismiss();
    }, 3000);
  }
  paymentGatewayInitialization() {
    this.proceedToAddMoney = true;
    if (this.paymentGatewayForm.invalid || !this.isVaildAmount) return;
    else {
      this.isLoading = true;
      this.proceedToAddMoney = false;
      const reqObj = {
        id: this.processingDetails.id,
        refrence: this.processingDetails.refrence,
      };
      this.agentService.initiatePaymentTransaction(reqObj).subscribe(
        (res: any) => {
          this.isLoading = false;
          this.showPaymentGatewayIframe = true;
          this.isPaystackCallbackUrlLoading = true;
          this.paystackTargetIFrameUrl = this._sanitizationService.bypassSecurityTrustResourceUrl(res.data.url);
          this.three3DIFrame = true;
          this.IframeDisplayed.emit(this.showPaymentGatewayIframe);
          setTimeout(() => {
            this.isPaystackCallbackUrlLoading = false;
          }, 2000);
        },
        () => (this.isLoading = false)
      );
    }
  }

  paymentVerification() {
    const reqObj = {
      reference: this.processingDetails.refrence,
    };
    this.agentService.paymentVerifyCall(reqObj).subscribe((res: any) => {
      this.paymentVerificationDetails = res;

      if (this.paymentVerificationDetails.success) {
        clearInterval(this.intervalID);
        this.isPaymentProcessing = true;
        $('#iframeContainer').css({ display: 'none' });
      }
      if (!this.paymentVerificationDetails.success && this.paymentVerificationDetails.message == FAILED) {
        clearInterval(this.intervalID);
        $('#iframeContainer').css({ display: 'none' });
        this.isPaymentProcessing = true;
      }
    });
  }

  closeAddMoneySectionModal() {
    this.closeAddMoneySection.emit();
    this.showPaymentGatewayIframe = false;
    this.isAddMoneyViaPaystackInProgress = false;
    this.paymentGatewayForm.setValue(0);
  }

  ngOnDestroy() {
    $('#payment_success_modal').modal('hide');
    $('#addMoneyToWallet_Modal').modal('hide');
    $('#payment_failed_Modal').modal('hide');
    clearInterval(this.intervalID);
  }

  onSubmit(event: any) {
    event.target.submit();
    this.isPaystackCallbackUrlLoading = false;
  }

  //allows users to type only numbers
  onlyNumbers(event: any) {
    return numInputNoChars(event);
  }
  onChange(event: any) {
    this.isVaildAmount = false;
  }
  isPeachPayment() {
    return Boolean(this.paymentDetails && this.paymentDetails[0]?.name == 'Peach_Payment');
  }
}
