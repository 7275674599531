import { Component, ViewChild, ElementRef } from '@angular/core';
import { UtilityService } from '@app/@core/services/utility.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/auth/authentication.service';
import { ApiService } from '@app/general/services/api/api.service';

declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  @ViewChild('aboutUs') aboutUs: ElementRef;
  isLogin: boolean;
  isLoggedIn$: Observable<boolean>;

  constructor(
    private router: Router,
    public utilityService: UtilityService,
    public authService: AuthenticationService,
    public apiService: ApiService
  ) {
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.authService.showHeaderBtns();
    sessionStorage.removeItem('OTP');
  }

  routToSignUp() {
    this.router.navigate(['register'], {
      queryParams: {
        tab: 'register',
      },
    });
  }

  scrollToAboutUs() {
    let aboutUsOffsetTop = this.aboutUs.nativeElement.offsetTop;
    window.scroll({ top: aboutUsOffsetTop, left: 0, behavior: 'smooth' });
  }
}
