import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { THEMES } from '../config/theme.config';
export type Theme = 'default' | 'flightsite' | 'clubhub';
declare const $: any;
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  currentTheme: Theme = 'default';
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.document.body.classList.add(this.currentTheme);
  }

  setTheme(name: Theme = 'default') {
    const theme: any = THEMES[name];
    this.document.body.classList.replace(this.currentTheme, name);
    this.currentTheme = name;
    console.log(name);
    Object.keys(theme).forEach((key) => {
      this.document.documentElement.style.setProperty(`--${key}`, theme[key]);
    });
    $('#TenantCustomCss').remove();
    $('head').append(`<link id="TenantCustomCss"  href="./assets/css/${name}.css" rel="stylesheet"/>`);
  }
}
