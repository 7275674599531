import { ResponsiveService } from './../../../@core/services/responsive.service';
import { Component, Input, OnInit } from '@angular/core';
import { SharedCommonService } from '@app/@shared/services/shared-common.service';
import {
  GetLapoverTime,
  getLayoverLabels,
  getTime,
  truncateAirlineName,
} from '../../../general/utils/app-search.utils';
import { ASSETS_CDN_PATH } from 'src/constants/constants';
import {
  checkItinhasSuppliers,
  getAirlineCode,
  getCityNamesfromJson,
  getPNR_Reference,
  manageBookingsDeeplinkUrl,
  OdoListHasAirline,
} from '@app/@shared/utils/shared.utils';
import { UtilityService } from '@app/@core/services/utility.service';

@Component({
  selector: 'app-flight-details-card',
  templateUrl: './flight-details-card.component.html',
  styleUrls: ['./flight-details-card.component.scss'],
})
export class FlightDetailsCardComponent implements OnInit {
  @Input() itinerary_Data_Info: any = {};
  @Input() isShowManageBookings: boolean = false;
  public flightsList: any = [];
  flightsearchInfo: any;
  airlineCode: any;
  fareOdoList: any;
  tripType: any = null;
  itineraryInfo: any = null;
  airportsJsonData: any = null;
  assets_PATH = ASSETS_CDN_PATH;
  user: any = null;
  constructor(
    public responsiveService: ResponsiveService,
    private sharedCommonService: SharedCommonService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.itineraryInfo = this.itinerary_Data_Info;
    this.tripType = this.itinerary_Data_Info?.trip;
    this.getAirportsJsonList();
    this.user = this.utilityService.getUser();
  }

  public getAirportName(param: string) {
    if (this.responsiveService.screenWidth === 'sm' || this.responsiveService.screenWidth === 'md') {
      return param;
    } else {
      return this.getCityName(param);
    }
  }
  public getCityName(param: string) {
    return this.airportsJsonData ? getCityNamesfromJson(param, this.airportsJsonData) : param;
  }
  public getStops(segments: any) {
    const numStops = parseInt(segments.length) - 1;
    if (numStops === 0) {
      return 'Non Stop';
    } else if (numStops === 1) {
      return numStops + ' Stop';
    } else {
      return numStops + ' Stops';
    }
  }

  public getTimeInHours(ms: number) {
    return getTime(ms);
  }
  public getAirlinename(airline: string) {
    return this.responsiveService.screenWidth === 'sm' || this.responsiveService.screenWidth === 'md'
      ? truncateAirlineName(airline, true)
      : airline;
  }

  public getLayoverTxt(odo: any) {
    return getLayoverLabels(odo.bookingFlightSegmentList);
  }

  public getLayoverTime(odo: any) {
    return GetLapoverTime(odo.bookingFlightSegmentList, this.flightsList?.airportInfos);
  }
  public baggageDes(bag: any) {
    if (bag?.includes('hand baggage')) {
      return bag?.slice(0, bag.indexOf('hand baggage'));
    } else {
      return bag;
    }
  }
  handBag(bag: any) {
    return bag?.includes('hand baggage') ? true : false;
  }
  bagageDesc(bag: any) {
    if (bag?.includes('hand baggage') && bag !== 'No baggage allowance') {
      return 'Hand baggage';
    } else if (bag !== 'No baggage allowance') {
      return 'Checked baggage';
    } else if (bag == 'No baggage allowance') {
      return 'No baggage';
    }
  }
  /**To get all Airports form S3 temporary solution only */
  getAirportsJsonList() {
    this.sharedCommonService.getAirportsJsonData().subscribe((data: any) => {
      this.airportsJsonData = data;
    });
  }
  /**To get passinging airline is existed or not in odoList for displaying manage,check-in CTA's as of now we are consider only flysafair only from template param in future if we get multiple can change logic here */
  checkItinhasAirline(airlineCode: any, odoList: any) {
    return OdoListHasAirline(airlineCode, odoList);
  }
  /**To get passinging supplier is existed or not in itinerary for displaying manage CTA as of now we are consider only Thomalex only from template param in future if we get multiple can change logic here */
  checkItinhasSupplier(reservations: any, odoList: any) {
    return checkItinhasSuppliers(reservations, odoList);
  }
  /**here we redirected to flysafair airline page for managebooking and online check-in if we get multiple airline case we can change the logic  */
  manageAirlineBookings(airlineCode: any, reservations: any) {
    let Url = manageBookingsDeeplinkUrl(
      airlineCode,
      reservations?.supplierCode,
      reservations?.pnrReference,
      this.itineraryInfo,
      this.user
    );
    window.open(Url, '_blank', 'noopener');
  }
  /**here we redirected to  supplier page for managebooking and online check-in if we get multiple suppliers case we can change the logic  */
  manageSupplierBookings(reservations: any) {
    let airlineCode = getAirlineCode(reservations);
    let pnrReference = getPNR_Reference(reservations);
    let Url = manageBookingsDeeplinkUrl(
      airlineCode,
      reservations?.supplierCode,
      pnrReference,
      this.itineraryInfo,
      this.user
    );
    window.open(Url, '_blank', 'noopener');
  }
}
