import { Component, Input, OnInit, DoCheck, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentService } from '@app/payment/service/payment.service';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
})
export class CreditCardComponent implements OnInit, DoCheck {
  @Input() wallet: any;
  public flip_Card = false;
  cardForm: FormGroup;
  cardnumber: number = null;
  cardHolderName: string = null;
  cardExpiryMonth: number = null;
  cardExpiryYear: any = null;
  CVV: any;
  isSubmitted: boolean = false;
  public cardExpiryYears: any = [];
  public year = new Date().getFullYear();
  isAddingMoneyToWallet: boolean = false;
  @Output() walletBalanceUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() openAmountAddedConfirmationModal: EventEmitter<any> = new EventEmitter<any>();

  get form() {
    return this.cardForm.controls;
  }

  constructor(private fb: FormBuilder, private paymentService: PaymentService) {}

  ngOnInit(): void {
    this.initForm();
    for (let i = 0; i <= 30; i++) {
      this.cardExpiryYears.push({ year: this.year + i });
    }
  }

  ngDoCheck() {
    this.cardnumber = this.cardForm.get('cardNumber').value;
    this.cardHolderName = this.cardForm.get('cardName').value;
    this.cardExpiryMonth = this.cardForm.get('cardExpiryMonth').value;
    this.cardExpiryYear = this.cardForm.get('cardExpiry').value.slice(2, 4);
    this.CVV = this.cardForm.get('cvv').value;
  }

  initForm() {
    this.cardForm = this.fb.group({
      cardNumber: [
        '',
        [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(16), Validators.maxLength(19)],
      ],
      cardName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]{1,30}$'), Validators.minLength(3)]],
      cardExpiryMonth: [''],
      cardExpiry: [''],
      passportNumber: [''],
      cvv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
      amount: [null],
    });
  }

  proceedAddMoney() {
    this.isSubmitted = true;
    if (this.cardForm.invalid) return;
    else {
      this.isAddingMoneyToWallet = true;
      const reqObj = {
        card_id: this.cardForm.get('cardNumber').value,
        amount: this.cardForm.get('amount').value,
      };
      this.paymentService.updateWalletAmount(reqObj).subscribe((res: any) => {
        if (res.success) {
          const creditWalletObj = {
            amount: this.cardForm.get('amount').value,
            token: res.token,
          };
          this.paymentService.updateCreditWallet(creditWalletObj).subscribe((res: any) => {
            if (res.success) {
              this.isAddingMoneyToWallet = false;
              this.walletBalanceUpdated.emit('true');
              this.openAmountAddedConfirmationModal.emit();
              this.resetForm();
            }
          });
        }
      });
    }
  }

  setAmountToBeAdded(price: number) {
    this.cardForm.get('amount').setValue(price);
  }

  transformCardNumber(cardNumber: any) {
    return cardNumber
      .toString()
      .replace(/\s+/g, '')
      .replace(/(\d{4})/g, '$1 ')
      .trim();
  }

  cardFlip() {
    this.flip_Card = true;
  }

  cardFlipInit() {
    this.flip_Card = false;
  }

  resetForm() {
    this.cardForm.get('amount').setValue('');
    this.cardForm.get('cardNumber').setValue('');
    this.cardForm.get('cardName').setValue('');
    this.cardForm.get('cardExpiryMonth').setValue('');
    this.cardForm.get('cardExpiry').setValue('');
    this.cardForm.get('cvv').setValue('');
    this.isSubmitted = false;
  }
}
