import { Inject, Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { find } from 'lodash';

import { WINDOW } from '../../../../assets/window.providers';
import {
  API_SERVER_BETA_PATH,
  API_SERVER_LIVE_PATH,
  B2B_API_URL_BETA,
  B2B_API_URL_LIVE,
  B2B_STAGE_PATH,
  B2B_API_URL_PREPROD,
} from './api-paths';

import { LOCALE_DOMAINS } from './../../../i18n/locale-domains';
import { DEFAULT_LOCALE } from './../../../i18n/supported-locales';
import { extractCountry } from './../../utils/locale.utils';
import { DOMAINS } from '@app/general/services/domains';
import {
  BETA_LAMBDA_API_FUNCTION,
  LIVE_LAMBDA_API_FUNCTION,
  MEILI_BOOKING_MANAGER_LIVE,
  MEILI_BOOKING_MANAGER_TEST,
  PEACH_SCRIPT_LINK,
  PEACH_TEST_SCRIPT_LINK,
  WHITE_LABEL_LIST,
} from '../../../../constants/constants';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private httpClient: HttpClient, @Inject(WINDOW) private window: Window, private injector: Injector) {}

  /**
   *  API server host url path
   */
  public fetchApiHostUrl() {
    if (this.getEnvironment() === 'local') {
      return API_SERVER_BETA_PATH;
    } else if (this.getEnvironment() === 'beta') {
      return API_SERVER_BETA_PATH;
    } else if (this.getEnvironment() === 'preprod') {
      return API_SERVER_LIVE_PATH;
    } else if (this.getEnvironment() === 'live') {
      return API_SERVER_LIVE_PATH;
    }
  }

  public apiBaseUrl(): any {
    let api_Url;
    const hostname = this.window.location.hostname;
    console.log('hostname >>>>>>> ', hostname);
    DOMAINS.forEach((host: any) => {
      if (hostname === host.hostname) {
        if (host.env === 'live') {
          api_Url = `${B2B_API_URL_LIVE}${B2B_STAGE_PATH}/`;
        } else if (host.env === 'preprod') {
          api_Url = `${B2B_API_URL_PREPROD}${B2B_STAGE_PATH}/`;
        } else {
          api_Url = `${B2B_API_URL_BETA}${B2B_STAGE_PATH}/`;
        }
      }
    });
    return api_Url;
  }

  public getEnvironment(): string {
    /**here we can remove once everything is working fine with extractWhiteLabelDomain().env condition
     *  if (this.isLocalhost()) {
      return 'local';
    }
    if (this.isAlpha()) {
      return 'alpha';
    }
    if (this.isBeta() || window.location.href.indexOf('beta') > -1) {
      return 'beta';
    }
    if (this.isPreprod()) {
      return 'preprod';
    }
    return 'live';
      */
    return this.extractWhiteLabelDomain()?.env;
  }

  public isAlpha(): boolean {
    const hostname = this.window.location.hostname;
    if (hostname.match(/(alpha[A-z]+\.travelstart)|(alpha[A-z]*\.geziko)/)) {
      return true;
    } else {
      return false;
    }
  }
  public isPreprod(): boolean {
    const hostname = this.window.location.hostname;
    if (hostname.match(/(preprod[A-z]+\.travelstart)|(preprod[A-z]*\.geziko)/)) {
      return true;
    } else {
      return false;
    }
  }
  public isBeta(): boolean {
    const hostname = this.window.location.hostname;
    if (hostname.match(/(beta[A-z]+\.travelstart)|(beta[A-z]*\.geziko)|(testfe\.travelstart)/)) {
      return true;
    } else {
      return false;
    }
  }
  public isLocalhost(): boolean {
    const hostname = this.window.location.hostname;
    if (hostname.match(/(localhost)/i) || hostname.match(/(dev\.travelstart\.com)/i)) {
      return true;
    } else {
      return false;
    }
  }

  getPeachScriptUrl() {
    let peach_Url;
    const hostname = this.window.location.hostname;
    DOMAINS.forEach((host: any) => {
      if (hostname === host.hostname) {
        if (host.env === 'live') {
          peach_Url = `${PEACH_SCRIPT_LINK}`;
        } else if (host.env === 'preprod') {
          peach_Url = `${PEACH_TEST_SCRIPT_LINK}`;
        } else {
          peach_Url = `${PEACH_TEST_SCRIPT_LINK}`;
        }
      }
    });
    return peach_Url;
  }
  /*
   * extract the country code from the supported list of hostnames
   */
  public extractCountryFromDomain(): string {
    /**
     * domain name examples
     * var hostname = "www.travelstart.com.na";
     * var hostname = "betana.travelstart.com";
     * and of course there is a localhost in there
     */

    const hostname = this.window.location.hostname;
    const country = find(LOCALE_DOMAINS, (localeDomain) => localeDomain.domain === hostname);
    if (country) {
      return country.id;
    } else {
      return extractCountry(DEFAULT_LOCALE);
    }
  }
  /*
   * extract the country code from the supported list of hostnames
   */
  public extractWhiteLabelDomain(): any {
    const hostname = this.window.location.hostname;
    const country = find(DOMAINS, (localeDomain) => localeDomain.hostname === hostname);
    if (country) {
      return country;
    } else {
      return {
        id: 'NG',
        hostname: 'business.travelstart.com',
        b2b_iframe_Link: 'https://www.travelstart.com.ng/',
        TS_ORG: 'TS_NG',
        env: 'live',
        ts_Country: 'NG',
      };
    }
  }

  public isFlightsiteDomain() {
    return Boolean(this.extractWhiteLabelDomain()?.TS_ORG === 'TS_FS');
  }
  public toDisplayForWhitelabel() {
    let whitelabelDomain = this.extractWhiteLabelDomain().TS_ORG;
    return WHITE_LABEL_LIST.some((x: any) => x == whitelabelDomain);
  }
  public toDisplayForClubhub() {
    return Boolean(this.extractWhiteLabelDomain().TS_ORG == 'TS_CT');
  }
  getUserEnvironment() {
    return this.extractWhiteLabelDomain()?.env;
  }
  getLambdaFunctionUrl() {
    let functionName: any;
    if (this.getUserEnvironment() === 'live') {
      functionName = `${LIVE_LAMBDA_API_FUNCTION}`;
    } else if (this.getUserEnvironment() === 'preprod') {
      functionName = `${BETA_LAMBDA_API_FUNCTION}`;
    } else {
      functionName = `${BETA_LAMBDA_API_FUNCTION}`;
    }
    return functionName;
  }
  getMeiliBookingManagerUrl() {
    if (this.getEnvironment() === 'live') {
      return MEILI_BOOKING_MANAGER_LIVE;
    } else {
      return MEILI_BOOKING_MANAGER_TEST;
    }
  }
}
