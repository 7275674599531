<div (window:resize)="onResize()">
  <router-outlet></router-outlet>

  <!-- <div>
    <h1>B2C and B2B merge</h1>

    <button (click)="sendMessageToIframe()">Send Message to Iframe</button>
    <iframe #iframeRef src="http://localhost:4200/?isIframe=iframe"></iframe>

  </div> -->
</div>

<div class="offline-message" *ngIf="connectionStatus == OFFLINE">
  <div class="offline-body">
    <div class="offline-icon">
      <span></span>
    </div>
    <div class="offline_txt ml-3">
      <strong translate>Offline</strong>
      <p class="mb-0" translate>Please check your internet connection.</p>
    </div>
  </div>
</div>
