import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CredentialsService } from '@app/auth/credentials.service';
import { AuthenticationService } from '@app/auth/authentication.service';
import { UtilityService } from '@app/@core/services/utility.service';
import { Observable } from 'rxjs';
import { ApiService } from '@app/general/services/api/api.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isLoggedIn: any;
  showHeadActions$: Observable<boolean>;
  @Output() buttonClicked = new EventEmitter();
  bodyDiv = false;
  user: any;

  @Output() scrollToAboutUs = new EventEmitter<any>();
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    public utilityService: UtilityService,
    public authService: AuthenticationService,
    public apiService: ApiService
  ) {
    this.showHeadActions$ = this.authService.isHeaderActions;
    this.isLoggedIn = this.utilityService.getAuthToken();
    this.user = this.utilityService.getUser();
  }

  ngOnInit(): void {
    this.user = this.utilityService.getUser();
  }

  routToSignUp() {
    this.router.navigate(['register'], {
      queryParams: {
        tab: 'register',
      },
    });
  }
  routToSignIn() {
    this.router.navigate(['register'], {
      queryParams: {
        tab: 'login',
      },
    });
  }

  logout() {
    this.authenticationService.logout();
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.username : null;
  }

  createUser() {
    this.buttonClicked.emit(true);
  }

  scrollToAboutUsHandler() {
    this.scrollToAboutUs.emit();
  }
}
