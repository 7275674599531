// get the city name based on the code
function getCitiesNames(param: string, airports: any) {
  let airportInfos = airports;
  for (let i in airportInfos) {
    if (airportInfos[i].iataCode === param) {
      return airportInfos[i].city;
    }
  }
}
function getAirportNames(param: string, airports: any) {
  const airportInfos = airports;
  for (let i in airportInfos) {
    if (airportInfos[i].iataCode === param) {
      return airportInfos[i].airport;
    }
  }
}

function getTime(ms: number) {
  let d, h, m, s;
  s = Math.floor(ms / 1000);
  m = Math.floor(s / 60);
  s = s % 60;
  h = Math.floor(m / 60);
  m = m % 60;
  d = Math.floor(h / 24);
  h = h % 24;
  h += d * 24;
  return h + 'hrs ' + m + 'min';
}

function getBaggageInfo(id: number, param: string, bagInfos: any) {
  let baggageAllowanceInfos = bagInfos;
  let bagInfo = [];

  if (param == 'hand') {
    for (let i in baggageAllowanceInfos) {
      if (
        baggageAllowanceInfos[i].correspondingSegmentIdList.includes(id) &&
        baggageAllowanceInfos[i].included &&
        baggageAllowanceInfos[i].type == 'HAND'
      ) {
        if (baggageAllowanceInfos[i].description.includes('hand baggage')) {
          let baggageAllowance: any = baggageAllowanceInfos[i].description.replace('h', 'H');
          bagInfo.push(baggageAllowance);
        } else {
          bagInfo.push(baggageAllowanceInfos[i].description);
        }
      }
    }
  } else if (param == 'checked') {
    for (let i in baggageAllowanceInfos) {
      if (
        baggageAllowanceInfos[i].correspondingSegmentIdList.includes(id) &&
        baggageAllowanceInfos[i].included &&
        baggageAllowanceInfos[i].type == 'CHECKED'
      ) {
        if (baggageAllowanceInfos[i].description.includes('checked baggage')) {
          let baggageAllowance = baggageAllowanceInfos[i].description.replace('checked baggage', '');

          bagInfo.push(baggageAllowance);
        } else {
          bagInfo.push(baggageAllowanceInfos[i].description);
        }
      }
    }
  }
  return bagInfo;
}
function truncateAirlineName(airline: string, isMobile: boolean): string {
  return airline?.length > 16 && isMobile ? airline.slice(0, 15).concat('...') : airline;
}

// calculating layover time
function GetLapoverTime(OutBoundFlightDetails: any, airports?: any) {
  let lapOverString = [];
  if (OutBoundFlightDetails.length > 1) {
    for (let j = 1; j < OutBoundFlightDetails.length; j++) {
      let endTime = OutBoundFlightDetails[j - 1].arrivalAirportGmt
        ? OutBoundFlightDetails[j - 1].arrivalAirportGmt
        : OutBoundFlightDetails[j - 1].arrivalDateTime;
      let city = OutBoundFlightDetails[j - 1].destCode || OutBoundFlightDetails[j - 1].arrivalAirport;
      let startTime = OutBoundFlightDetails[j].departureDateTime;
      let lapovertimemins = 0;

      lapovertimemins = getLayOverHours(startTime, endTime);
      let hours = leftPad(Math.floor(Math.abs(lapovertimemins) / 60));
      let hours_Arb = leftPad(Math.floor(Math.abs(lapovertimemins) / 60));
      let mins = leftPad(lapovertimemins % 60);

      if (hours == '00') {
        hours = lapovertimemins + 'min';
      } else {
        hours = hours + 'hrs ' + mins + 'min';
      }
      if (airports) {
        lapOverString.push(' at  ' + getAirportNames(city, airports) + ' ' + '(' + hours + ')');
      } else {
        lapOverString.push(' at  ' + city + ' ' + '(' + hours + ')');
      }
    }
    return lapOverString;
  } else {
    return '';
  }
}

/**Calculating layover time in hours */
function getLayOverHours(startTime: any, endTime: any) {
  let endtimemins = new Date(endTime).getTime() / 60000;
  let starttimemins = new Date(startTime).getTime() / 60000;
  let lapovertimemins = 0;
  if (starttimemins > endtimemins) {
    lapovertimemins = starttimemins - endtimemins;
  } else {
    let firsttime = 1439 - Number(endtimemins);
    let secontime = '0000' + Number(starttimemins);
    lapovertimemins = Number(firsttime) + Number(secontime);
  }

  return lapovertimemins;
}

/**Identifying the layover and long layover based on hours */
function getLayoverLabels(OutBoundFlightDetails: any) {
  let layover_text = [];
  if (OutBoundFlightDetails.length > 1) {
    for (let j = 1; j < OutBoundFlightDetails.length; j++) {
      let endTime = OutBoundFlightDetails[j - 1].arrivalDateTime;
      let startTime = OutBoundFlightDetails[j].departureDateTime;
      const mins = getLayOverHours(startTime, endTime);
      let hours = leftPad(Math.floor(Math.abs(mins) / 60));
      let hrs = parseInt(hours);
      layover_text.push(hrs > 6 ? 'Long Layover' : 'Layover');
    }
    return layover_text;
  }
}

function leftPad(number: number) {
  return (number < 10 && number >= 0 ? '0' : '') + number;
}

export {
  getCitiesNames,
  getAirportNames,
  getTime,
  getBaggageInfo,
  truncateAirlineName,
  GetLapoverTime,
  getLayoverLabels,
};
