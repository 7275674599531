import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SuperAdminService } from '@app/super-admin/services/super-admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

declare let $: any;

@Component({
  selector: 'app-addMoney',
  templateUrl: './addMoney.component.html',
  styleUrls: ['./addMoney.component.scss'],
})
export class AddMoneyComponent implements OnInit {
  wallet: any;
  agencyDetails: any;
  agentId: number;
  walletDetails: any;
  cardForm: FormGroup;
  isSubmitted: boolean = false;
  selectedMethod: string;
  isAddingMoney: boolean = false;
  OFFLINE_DEPOSIT: string = 'Offline Deposit';
  WALLET: string = 'Wallet';

  get form() {
    return this.cardForm.controls;
  }

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private superAdminService: SuperAdminService
  ) {
    this.agentId = this.route.snapshot.params.agentId;
    if (this.agentId) this.getAgencyDetails(this.agentId);
  }

  ngOnInit(): void {
    this.initForm();
    this.getSelectedPaymentOption(this.WALLET);
  }

  getAgencyDetails(data: any) {
    this.superAdminService.getAgentDetails(data).subscribe((res: any) => {
      if (res) {
        this.agencyDetails = res.success ? res.data : {};
        this.getCreditWalletDetails(this.agencyDetails.agency_id);
      }
    });
  }

  getCreditWalletDetails(data: any) {
    this.superAdminService.getAgentWalletCreditDetails(data).subscribe((res: any) => {
      if (res) this.walletDetails = res.success ? res.data : {};
    });
  }

  initForm() {
    this.cardForm = this.fb.group({
      amount: [null],
      transactionReason: [''],
      transactionId: [null],
      type: ['credit'],
    });
  }

  setAmountToBeAdded(price: number) {
    this.cardForm.get('amount').setValue(price);
  }

  getSelectedPaymentOption(paymentmethod: string) {
    this.selectedMethod = paymentmethod;
  }

  updateWalletAmount() {
    this.isSubmitted = true;
    if (this.cardForm.invalid) return;
    else {
      this.isAddingMoney = true;
      let amount =
        this.cardForm.get('type').value == 'debit'
          ? this.cardForm.get('amount').value * -1
          : this.cardForm.get('amount').value;
      let reqObj = {
        amount: amount,
        currency: this.walletDetails.currency,
        messages: this.cardForm.get('transactionReason').value,
        transaction_id: this.cardForm.get('transactionId').value,
      };
      this.superAdminService.updateAgentWalletCredit(this.agencyDetails.agency_id, reqObj).subscribe(
        (res) => {
          $('#success_modal').modal('show');
          this.isAddingMoney = false;
        },
        () => (this.isAddingMoney = false)
      );
    }
  }
  backToHome() {
    this.location.back();
  }
}
