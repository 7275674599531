//Base Url
export const BASE_URL = 'https://beta-b2b-api.travelstart.com/b2b-stage/';

//Agent
export const ACCOUNTS = 'accounts';
export const AGENT_SIGNUP = 'accounts/signup/agent';
export const AGENT_CONFIRMATION = 'accounts/email-verification/';
export const GET_TOKENS = 'accounts/token/';
export const AGENT_ACTIVATION = 'accounts/activate/';
export const AGENT_LOGIN = 'accounts/me/';
export const WALLET_BALANCE = 'wallet/balance';
export const BOOKING_LIST = 'booking/list';
export const AGENT_PROFILE_UPDATE = 'accounts/profile-edit';
export const AGENT_TOTAL_EARNINGS = 'wallet/monthly-report';
export const AGENT_WALLET_TRANSACTIONS = 'wallet/wallet-transactions';
export const OFFLINEBANKS_LIST = 'core/get-banks';
export const ADDMONEY_OFFINE_MODE = 'wallet/ofline-credit';
export const AGENT_AUDIT_LOGS = 'logs/audit';

//Core
export const CORE = 'core';
export const GET_COUNTRIES = 'core/get-country';
export const DASHBOARD = 'core/dashboard';
export const GET_CITIES = 'core/get-city';
export const GET_PRODUCTS = 'core/get-products';
export const GET_PERMISSIONS = 'core/get-permissions';
export const GET_PAYMENT_DETAILS = 'core/payment-details';
export const GET_LOCAL_GOVERNANCE = 'core/get-lgas';

//Flight
export const FLIGHT_SEARCH = 'webapi/search';
export const LOAD_FLIGHTS = 'https://betaza.travelstart.com/servlet/ac.json/?';
export const FLIGHT_PRICE_API = 'webapi/price';
export const FLIGHT_PAYMENT = 'webapi/payment';
export const FLIGHT_BOOK = 'webapi/book';
export const FLIGHT_EDIT_PRICE = 'webapi/editprice';
export const CHECK_RATES = 'webapi/checkrates';

//super admin
export const ADMIN = 'admin';
export const ADMIN_LOGIN = 'admin/login';
export const AGENT_LIST = 'admin/user-list/agent';
export const AGENT_VERIFCATION = 'admin/agent-verification/';
export const ADMIN_DASHBOARD = 'admin/dashboard';
export const AGENT_PROFILE_EDIT = 'admin/agent-profile-edit/';
export const AGENT_PAYMENT_DETAILS = 'admin/payment-details/';
export const ADMIN_PAYMENT_DETAILS = 'admin/payment-details';
export const ADMIN_WALLET_TRANSACTIONS = 'admin/wallet-transactions';
export const ADMIN_AUDIT_LOGS = 'admin/audit';
export const ADMIN_WALLET_CREDIT = 'admin/wallet-credit';
export const AGENT_STAFF_LIST = 'admin/agent-staff-list';
export const AGENT_STAFF = '/agent-staff';
export const AGENCY_DETAILS = '/agency-details';

//Agent Markup
export const CREATE_MARKUP = 'markup/create';
export const EDIT_MARKUP_BY_ID = 'markup/edit';
export const GET_MARKUP_TYPE = 'markup/markuptype';

//get Airlines
export const GET_AIRLINES = 'core/search-airline';
//get Destinations
export const GET_DESTINATIONS = 'core/search-destination';

// create user within the  Agent

export const CREATE_AGENT_USER = 'accounts/signup/staff';
export const UPDATE_AGENT_USER = 'accounts/staff';

// booking ApI
export const BOOKING = 'booking';
export const VIEW_ITINERARY = '/itinerary';
/**To cancel bookings */
export const WEBAPI = 'webapi/';
export const CANCEL = 'cancel/';
// Invoice API
export const AGENT_INVOICE_API = '/agent-invoice';
export const CUSTOMER_INVOICE_API = '/customer-invoice';
export const EXPORT_REPORT_API = 'booking/list';
export const E_TICKET = '/e-ticket';
export const DOWNLOAD_ITINERARY = '/download-itinerary';
// email template API

export const GET_ALL_EMAILTEMPLATES = 'accounts/email-templates';
export const SAVE_EMAILTEMPLATE = 'accounts/template-edit';
export const PREVIEW_EMAILTEMPLATE = 'accounts/template-preview';

//create employee within the Super-Admin
export const TS_EMPLOYEE = '/ts-employee';
export const CREATE_ADMIN_EMPLOYEE = '/create-ts-employee';
export const ADMIN_EMPLOYEE_LIST = '/ts-employee-list';
export const UPDATE_ADMIN_EMPLOYEE = '/ts-employee';
export const CREATE_AGENT = '/agent-creation';

// add money to agent's wallet
export const CREDIT_AMOUNT_TO_WALLET = 'wallet/credit-wallet';
export const PROCESS_AMOUNT_TO_WALLET = 'wallet/get-payment-method';

// reset password
export const FORGOT_PASSWORD_REQUEST = 'accounts/forgot-password-request/';
export const FORGOT_PASSWORD_VALIDATE = 'accounts/forgot-password-validate/';
export const FORGOT_PASSWORD = 'accounts/forgot-password/';

// Travellers
export const TRAVELLER = 'booking/traveller';
export const TRAVELLER_EDIT = 'booking/traveller-edit/';

// Payments Gateway
export const GET_PAYMENT_METHODS = 'payment-gateway/get-methods';
export const GET_PROCESSING_FEE = 'payment-gateway/get-proceesing-fee';
export const INITIATE_PAYMENT_TRANSACTION = 'payment-gateway/initiate';
export const PAYMENT_VERIFY_CALL = 'payment-gateway/verify';

/**Ts website assets path */
export const ASSETS_CDN_PATH = 'https://cdn1.travelstart.com';
export const S3_FASTFIX_PATH = 'https://s3.eu-west-2.amazonaws.com/fastpix.travelstart.com';
export const AIRPORTS_JSON_PATH = '/assets/json/airports.json';

/* send OTP to user for Flightsite */
export const RESEND_OTP = '/resend-login-otp/';
export const VERIFY_OTP = '/verify-login-otp/';

/**send email Itineray for reserved bookings */
export const EMAIL_ITINERARY = '/email-itinerary';
export const WHITE_LABEL_LIST = ['TS_FS', 'TS_CT'];

export const PEACH_TEST_SCRIPT_LINK = 'https://sandbox-checkout.peachpayments.com/js/checkout.js';
export const PEACH_SCRIPT_LINK = 'https://checkout.peachpayments.com/js/checkout.js';

/**Ticketing Rejections API's */
export const CREATE_TICKETING_REJECTION = '/send-rejection';
export const TICKETING_REJECTIONS_LIST = '/rejection-list';
export const GET_TICKETING_REJECTION_BY_ID = '/rejection';
export const AGENT_SEARCH = '/agent-search';

/** keys are used to integrate lamda functions with accesskey,secret key  */
export const LAMBDA_API_SECRET_KEY = 'L+p43n4ohpJV37FGdV7xY/IS2OWgPKgZWHx6Wdbx';
export const LAMBDA_API_ACCESS_KEY = 'AKIARIBXNLAEH36IZZ5S';
export const LAMBDA_API_REGION = 'eu-west-1';
export const BETA_LAMBDA_API_FUNCTION = 'beta_notifications_validator';
export const PREPROD_LAMBDA_API_FUNCTION = 'beta_notifications_validator';
export const LIVE_LAMBDA_API_FUNCTION = 'production_notifications_validator';

/**depplink for manage booking */
export const MANAGE_BOOKING_DEEPLINK = 'https://ts.ssisa.co.za/go';

/**Staff approva list */
export const STAFF_APPROVAL_LIST = '/staff-list';
export const APPROVE_STAFF = '/staff-approval/';

/** Meili embed integration Urls */
export const MEILI_BOOKING_MANAGER_TEST = 'https://uat-booking-ux.meili.travel/index.js';
export const MEILI_BOOKING_MANAGER_LIVE = 'https://booking-ux.meili.travel/index.js';
