export const DOMAINS: any = [
  {
    id: 'NG',
    hostname: 'beta-business.travelstart.com',
    b2b_iframe_Link: 'https://beta.travelstart.com.ng/',
    TS_ORG: 'TS_NG',
    env: 'beta',
    ts_Country: 'NG',
    currency: 'NGN',
  },
  {
    id: 'NG',
    hostname: 'business.travelstart.com',
    b2b_iframe_Link: 'https://www.travelstart.com.ng/',
    TS_ORG: 'TS_NG',
    env: 'live',
    ts_Country: 'NG',
    currency: 'NGN',
  },
  {
    id: 'NG',
    hostname: 'beta-travelstart-ng.myota.travel',
    b2b_iframe_Link: 'https://beta.travelstart.com.ng/',
    TS_ORG: 'TS_NG',
    env: 'beta',
    ts_Country: 'NG',
    currency: 'NGN',
  },
  {
    id: 'ZA',
    hostname: 'beta-travelstart-za.myota.travel',
    b2b_iframe_Link: 'https://beta.travelstart.co.za/',
    TS_ORG: 'TS_ZA',
    env: 'beta',
    ts_Country: 'ZA',
    currency: 'ZAR',
  },
  {
    id: 'FS',
    hostname: 'beta-flightsite.myota.travel',
    b2b_iframe_Link: 'https://beta.travelstart.co.za/',
    TS_ORG: 'TS_FS',
    env: 'beta',
    ts_Country: 'ZA',
    currency: 'ZAR',
  },
  {
    id: 'CT',
    hostname: 'beta-clubtravel.myota.travel',
    b2b_iframe_Link: 'https://beta.travelstart.co.za/',
    TS_ORG: 'TS_CT',
    env: 'beta',
    ts_Country: 'ZA',
    currency: 'ZAR',
  },
  {
    id: 'NG',
    hostname: 'travelstart-ng.myota.travel',
    b2b_iframe_Link: 'https://www.travelstart.com.ng/',
    TS_ORG: 'TS_NG',
    env: 'live',
    ts_Country: 'NG',
    currency: 'NGN',
  },
  {
    id: 'ZA',
    hostname: 'travelstart-za.myota.travel',
    b2b_iframe_Link: 'https://www.travelstart.co.za/',
    TS_ORG: 'TS_ZA',
    env: 'live',
    ts_Country: 'ZA',
    currency: 'ZAR',
  },
  {
    id: 'FS',
    hostname: 'flightsite.myota.travel',
    b2b_iframe_Link: 'https://www.travelstart.co.za/',
    TS_ORG: 'TS_FS',
    env: 'live',
    ts_Country: 'ZA',
    currency: 'ZAR',
  },
  {
    id: 'FS',
    hostname: 'preprod-flightsite.myota.travel',
    b2b_iframe_Link: 'https://beta.travelstart.co.za/',
    TS_ORG: 'TS_FS',
    env: 'preprod',
    ts_Country: 'ZA',
    currency: 'ZAR',
  },
  {
    id: 'CT',
    hostname: 'preprod-clubtravel.myota.travel',
    b2b_iframe_Link: 'https://beta.travelstart.co.za/',
    TS_ORG: 'TS_CT',
    env: 'preprod',
    ts_Country: 'ZA',
    currency: 'ZAR',
  },
  {
    id: 'TS',
    hostname: 'preprod-travelstart-ng.myota.travel',
    b2b_iframe_Link: 'https://preprod.travelstart.com.ng/',
    TS_ORG: 'TS_NG',
    env: 'preprod',
    ts_Country: 'NG',
    currency: 'NGN',
  },
  {
    id: 'TS',
    hostname: 'preprod-travelstart-za.myota.travel',
    b2b_iframe_Link: 'https://preprod.travelstart.co.za/',
    TS_ORG: 'TS_ZA',
    env: 'preprod',
    ts_Country: 'ZA',
    currency: 'ZAR',
  },
  {
    id: 'CT',
    hostname: 'clubtravel.myota.travel',
    b2b_iframe_Link: 'https://www.travelstart.co.za/',
    TS_ORG: 'TS_CT',
    env: 'live',
    ts_Country: 'ZA',
    currency: 'ZAR',
  },
  {
    id: 'NG',
    hostname: 'preprod-business.travelstart.com',
    b2b_iframe_Link: 'https://beta.travelstart.com.ng/',
    TS_ORG: 'TS_NG',
    env: 'preprod',
    ts_Country: 'NG',
    currency: 'NGN',
  },
  {
    id: 'NG',
    hostname: 'localhost',
    b2b_iframe_Link: 'http://localhost:6200/',
    TS_ORG: 'TS_CT',
    env: 'preprod',
    ts_Country: 'ZA',
    currency: 'ZAR',
  },
];
