import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ourweb-iframe',
  templateUrl: './ourweb-iframe.component.html',
  styleUrls: ['./ourweb-iframe.component.scss'],
})
export class OurwebIframeComponent implements OnInit {
  iframeUrl: any = null;
  constructor(private _sanitizationService: DomSanitizer, private router: Router) {}

  ngOnInit(): void {
    this.iframeUrl = this.getIframeUrl();
  }
  getIframeUrl() {
    return this._sanitizationService.bypassSecurityTrustResourceUrl('https://b2b-cms.myota.travel');
  }
  /**we can enable this if we have multiple options render in iframe 
   *   generateIframeUrl(param?: any) {
    let url = this.router.url;
    switch (url) {
      case '/agent/ourweb':
        return 'https://sites.google.com/clubtravel.co.za/club-travel-ourweb/news';
      default:
        return '';
    }
  }
   */
}
