<div class="multi_select_main">
  <mat-form-field
    class="multiselect_mat-form"
    appearance="{{ appearance }}"
    [ngClass]="{ header_menuDropdown: appearance == 'fill' }"
    [ngStyle]="{ width: appearance == 'fill' ? '200px' : '300px' }"
  >
    <mat-icon matSuffix *ngIf="type != 'destination'">keyboard_arrow_down</mat-icon>
    <mat-icon matSuffix *ngIf="type == 'destination'"><img src="assets/images/table-search.svg" /></mat-icon>
    <mat-select
      placeholder="{{ placeholder }}"
      name="item"
      panelClass="multiselect_panelClass"
      [(ngModel)]="selectedItems"
      (ngModelChange)="getSelItems(selectedItems)"
      disableOptionCentering
      multiple
      #itemSelect
    >
      <mat-select-trigger *ngIf="type == 'destination'">
        <mat-chip-list>
          <mat-chip *ngFor="let topping of selectedItems" [removable]="true" (removed)="deSelectSingle(topping)">
            {{ topping.viewValue }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </mat-select-trigger>
      <div class="multi_select_panel">
        <div class="multi_select_panelHeader">
          <mat-list role="list" style="display: flex !important">
            <mat-list-item role="listitem">
              <mat-form-field class="Search_field" appearance="outline">
                <input matInput placeholder="Search" [(ngModel)]="searchedString" />
                <mat-icon matSuffix style="z-index: 999 !important"
                  ><img src="assets/images/table-search.svg"
                /></mat-icon>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item class="selected_count">
              {{ selectedItems ? selectedItems.length : '' }} SELECTED
            </mat-list-item>
            <mat-list-item role="listitem" style="color: #1bb3f5; cursor: pointer" (click)="deselectAll(itemSelect)">
              CLEAR ALL
            </mat-list-item>
          </mat-list>
        </div>
        <div class="multi_select_panelBody">
          <div class="row">
            <div class="col-md-6 mulsel_items marginTop20">
              <mat-option *ngFor="let item of items" [value]="item" class="multiselect_mat-option">
                {{ item.viewValue }}
              </mat-option>
            </div>
            <div class="col-md-6 marginTop20">
              <ul class="delete_selItems">
                <li *ngFor="let deselectItem of selectedItems" class="marginTop10" style="margin-right: 20px">
                  <span class="">
                    {{ deselectItem.viewValue }}
                  </span>
                  <span class="pull-right">
                    <a (click)="deSelectSingle(deselectItem)">x</a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </mat-select>
  </mat-form-field>
</div>
