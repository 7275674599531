import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';

@Component({
  selector: 'multi-select',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
})
export class MultiSelectComponent implements AfterViewInit {
  @Input() items: any;
  @Input() placeholder: string;
  @Input() type: string;
  @Input() mulselFor: string;
  @Input() appearance: string;

  @Output() postSelItemsEvent = new EventEmitter<any>();

  @ViewChild(MatSelect, { static: false })
  selectComponent: any;

  ngAfterViewInit() {
    this.selectComponent.overlayDir.backdropClick.subscribe((evt: any) => console.log(evt));
    this.selectComponent.overlayDir.backdropClass = 'myCustomBackdropClass';
    this.selectComponent.overlayDir.classList = 'my-class-name';
  }
  selectedItems: any = [];
  searchedString: any = '';

  /**
   *
   * @param select deselects all elemtns
   */
  deselectAll(select: MatSelect) {
    this.selectedItems = [];
    select.value = [];
    this.postSelItemsEvent.emit({ selectedItems: this.selectedItems, type: this.type }); //post selectitems after deselecting an item
  }
  /**
   *
   * @param item deselects unchecked item
   */
  deSelectSingle(item: any) {
    const selectedIndex = this.selectedItems && this.selectedItems.indexOf(item);

    if (selectedIndex > -1) {
      const newToppingsValue = this.selectedItems.slice();
      newToppingsValue.splice(selectedIndex, 1);
      this.selectedItems = newToppingsValue;
      this.postSelItemsEvent.emit({ selectedItems: this.selectedItems, type: this.type }); //post selectitems after deselecting an item
    }

    //deletes from rightside listed selected array
    const listIndex = this.selectedItems.indexOf(item);
    if (listIndex > -1) {
      this.selectedItems.splice(listIndex, 1);
    }
  }

  /**
   *
   * @param value onchange event to pass selecteditems to its parent component
   */
  getSelItems(value: any) {
    this.postSelItemsEvent.emit({ selectedItems: this.selectedItems, type: this.type });
  }
  ngOnInit() {}
}
