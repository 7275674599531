import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedCommonService } from '@app/@shared/services/shared-common.service';
import { ApiService } from '@app/general/services/api/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  date = new Date();
  email = new FormControl('', [
    Validators.required,
    Validators.pattern('[a-zA-Z0-9.+-_]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'),
  ]);
  submitted: boolean = false;
  isLoading: boolean = false;
  constructor(
    private router: Router,
    public apiService: ApiService,
    private sharedService: SharedCommonService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}
  getNewsLetterSubscribtion() {
    this.submitted = true;
    if (this.email.valid) {
      this.isLoading = true;
      /**here we are pick newsletter reciver email based on organization from translate json files */
      let NewsLetterReciverEmail = this.translateService.instant('NewsLettersReciverEmail');
      let userEmail = this.email.value;
      this.sharedService.subscribeToNewsLetter(userEmail, NewsLetterReciverEmail);
      setTimeout(() => {
        this.isLoading = false;
        this.submitted = false;
        this.email.reset();
      }, 2000);
    } else {
      return;
    }
  }
}
