import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy } from '@core';
import { UtilityService } from '@app/@core/services/utility.service';
import { RestService } from '@app/@core/services/rest.service';
import { AuthenticationService } from '../authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { get } from 'lodash';
import { ApiService } from '../../general/services/api/api.service';
import { homepagePath } from '../routing_path';
declare let $: any;

@UntilDestroy()
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss', './../../../theme/bottom_wrapper.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SigninComponent implements OnInit {
  showHeader$: boolean;
  error: string | undefined;
  loginForm: FormGroup;
  isLoading = false;
  private formSubmitAttempt: boolean;
  hide = true;
  public signInUser: any = {};
  isAgentLoggingIn: boolean = false;
  isLoginFormSubmitted: boolean = false;
  shouldShowPassword: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public utilityService: UtilityService,
    public restService: RestService,
    public authService: AuthenticationService,
    private _snackBar: MatSnackBar,
    public apiService: ApiService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  signIn() {
    this._snackBar.dismiss();
    this.isLoginFormSubmitted = true;
    this.formSubmitAttempt = true;
    if (this.loginForm.valid) {
      this.isAgentLoggingIn = true;
      this.restService.getTokens(this.loginForm.value).subscribe(
        (res: any) => {
          if (res) {
            let response = res;
            response['email'] = this.loginForm.value?.username;
            this.utilityService.setAuthToken(response);
            if (res.mfa) {
              this.router.navigate(['/OTP-verification']);
            } else {
              this.userLogin();
            }
            $('#signin-modal').modal('hide');
            this.isAgentLoggingIn = false;
          }
        },
        (errors) => {
          this.isAgentLoggingIn = false;
          if (errors.error) {
            this._snackBar.open(
              `${get(errors, 'error.message', 'Unable to login right now, Please try again later.')}`,
              'X'
            );
          }
        }
      );
    }
  }

  userLogin() {
    this.restService.login().subscribe((res: any) => {
      if (res) {
        this.authService.setIsloggedIn();
        this.utilityService.setUser(res.data);
        this.router.navigate([this.route.snapshot.queryParams.redirect || homepagePath(res.data)], {
          replaceUrl: true,
        });
      }
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.loginForm.get(field).valid && this.loginForm.get(field).touched) ||
      (this.loginForm.get(field).untouched && this.formSubmitAttempt)
    );
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$'),
        ],
      ],
      password: ['', Validators.required],
    });
  }

  navigateToSignUp() {
    this.router.navigate(['register'], {
      queryParams: { tab: 'register' },
    });
  }

  ngOnDestroy() {
    $('#signin-modal').modal('hide');
    this._snackBar.dismiss();
  }
}
