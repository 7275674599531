<div class="matcard_tiles">
  <div class="row matcard_tilesUl mx-0">
    <div class="col-lg-3 col-6 mb-2 mb-lg-0 px-1">
      <div class="matcard_topLeft">
        <div class="cardData topLeft">
          <ng-content select="[topLeftTile]"></ng-content>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-6 mb-2 mb-lg-0 px-1">
      <div class="matcard_topRight">
        <div class="cardData topRight">
          <ng-content select="[topRightTile]"></ng-content>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-6 px-1">
      <div class="matcard_bottomLeft">
        <div class="cardData bottomLeft">
          <ng-content select="[bottomLeftTile]"></ng-content>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-6 px-1">
      <div class="matcard_bottomRight">
        <div class="cardData bottomRight">
          <ng-content select="[bottomRightTile]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
