import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class GenerateInvoicePDF {
  constructor(private datePipe: DatePipe) {}

  customerInfo(user: any) {
    const customerInfo = {
      style: 'invoiceDetails',
      table: {
        body: [
          [
            { text: 'Customer:', style: 'subheader' },
            { text: `${user.customer.name}`, style: 'text', margin: 1 },
          ],
          [
            { text: 'Ship To:', style: 'subheader' },
            { text: `${user.ship_to.address}`, style: 'text', margin: 1 },
          ],
        ],
      },
      layout: 'noBorders',
    };
    return customerInfo;
  }

  customerBillTo(user: any) {
    const billTo = {
      style: 'invoiceDetails',
      table: {
        widths: [55, 150],
        body: [
          [
            { text: 'Bill To:', style: 'subheader' },
            { text: `${user.bill_to.address}`, style: 'text', margin: 1 },
          ],
        ],
      },
      layout: 'noBorders',
    };
    return billTo;
  }

  invoiceDescription(invoiceDetail: any) {
    const invoiceDesc = {
      style: 'invoiceDetails',
      table: {
        body: [
          ['Number', ''],
          ['Invoice Data', invoiceDetail.invoice_date],
          ['Data and Time', invoiceDetail.date_and_time],
          ['Sales order', invoiceDetail.sales_order],
        ],
      },
      layout: 'noBorders',
    };
    return invoiceDesc;
  }

  invoiceInfoSection(invoiceInfo: any) {
    const invoiceData = [
      ['Requisition', invoiceInfo.requisition],
      ['Affiliate Id', invoiceInfo.affiliate_iD],
      ['Our reference', invoiceInfo.our_reference],
      ['Payment', invoiceInfo.payment],
      ['Invoice Account', invoiceInfo.invoice_account],
    ];
    return invoiceData;
  }

  invoiceContactDetails(user: any) {
    const contactDetails = {
      style: 'invoiceDetails',
      table: {
        body: [
          [{ text: `${user.name}`, style: 'text' }],
          [{ text: `${user.address}`, style: 'text' }],
          [{ text: user.zipcode != null ? `${user.zipcode}` : '', style: 'text' }],
          [{ text: `E: ${user.email}`, style: 'text' }],
          [{ text: `${user.email}`, style: 'text' }],
          [{ text: `${user.city}`, style: 'text' }],
          [{ text: `${user.country}`, style: 'text' }],
          [{ text: `${user.tax_registration_number}`, style: 'text' }],
          [{ text: `${user.tax_identification_number}`, style: 'text' }],
        ],
      },
      layout: 'noBorders',
    };
    return contactDetails;
  }

  flightDetailsTable(product: any, flightdetail: any) {
    const detailsTable = {
      table: {
        headerRows: 1,
        widths: [110, '*', 80, 60, 60],
        body: [
          [
            { text: 'Booking Reference', style: 'priceDetailsHeader' },
            { text: 'Flights Details', style: 'priceDetailsHeader' },
            { text: 'Passengers', style: 'priceDetailsHeader' },
            { text: 'Discount', style: 'priceDetailsHeader' },
            { text: 'Total Fare', style: 'priceDetailsHeader' },
          ],
          ['', '', '', '', ''],
          [
            { text: `${product?.reference}`, style: 'text' },
            {
              stack: [
                flightdetail.map((each: any) => {
                  return {
                    text: `${each.origin} -> ${each.destination} ${this.datePipe.transform(
                      flightdetail[0].depature,
                      'dd/MM/yyyy'
                    )}`,
                    style: 'text',
                  };
                }),
              ],
            },
            {
              stack: [
                { text: product?.adult > 0 ? `${product?.adult} Adult ` : null, style: 'text' },
                { text: product?.child > 0 ? `${product?.child} Child ` : null, style: 'text' },
                { text: product?.infant > 0 ? `${product?.infant} infant ` : null, style: 'text' },
              ],
            },
            { text: `${product?.dynamic_discount}`, style: 'text' },
            { text: `${product?.final_price}`, style: 'text' },
          ],
        ],
      },
      layout: 'headerLineOnly',
    };
    return detailsTable;
  }

  tableBottomLineUI() {
    const tableBottomLine = {
      hLineWidth: function (i: any, node: any) {
        return i === 0 || i === node.table.body.length ? 0 : 2;
      },
      vLineWidth: function (i: any, node: any) {
        return 0;
      },
    };
    return tableBottomLine;
  }

  priceBreakdownTable(product: any) {
    const priceTable = [
      [
        { text: 'Currency:', style: 'priceDetailsHeader' },
        { text: `${product?.currency}`, style: 'text', margin: 1 },
      ],
      [
        { text: 'Subtotal:', style: 'priceDetailsHeader' },
        { text: `${product?.price}`, style: 'text', margin: 1 },
      ],
      [
        { text: 'Discount:', style: 'priceDetailsHeader' },
        { text: `${product?.dynamic_discount}`, style: 'text', margin: 1 },
      ],
      [
        { text: 'SalesTax:', style: 'priceDetailsHeader' },
        { text: '0', style: 'text', margin: 1 },
      ],
      [
        { text: 'Total:', style: 'priceDetailsHeader' },
        { text: `${product?.final_price}`, style: 'text', margin: 1 },
      ],
    ];
    return priceTable;
  }
}
