import { Component, OnInit } from '@angular/core';
import { UtilityService } from '@app/@core/services/utility.service';
import { homepagePath } from '@app/auth/routing_path';
import { isSuperAdmin, isAgent } from '@app/@shared/role.constants';

@Component({
  selector: 'app-tabs-header',
  templateUrl: './tabs-header.component.html',
  styleUrls: ['./tabs-header.component.scss'],
})
export class TabsHeaderComponent implements OnInit {
  isBookingsTabActive: boolean = false;
  isPaymentsTabActive: boolean = false;
  isTravellersTabActive: boolean = false;
  isTravellersEditMode: boolean = false;
  isItineraryView: boolean = false;
  user: any;
  isSuperAdmin: boolean;
  isAgent: boolean;

  constructor(public utilityService: UtilityService) {}

  ngOnInit(): void {
    this.user = this.utilityService.getUser();
    this.isSuperAdmin = isSuperAdmin(this.user.role);
    this.isAgent = isAgent(this.user.role);
    if (location.pathname.includes(`${homepagePath(this.user)}/bookings`)) this.isBookingsTabActive = true;
    if (location.pathname.includes(`${homepagePath(this.user)}/travellers`)) this.isTravellersTabActive = true;
    if (location.pathname.includes(`${homepagePath(this.user)}/payments`)) this.isPaymentsTabActive = true;
    if (location.pathname.includes(`${homepagePath(this.user)}/itineraryView`)) this.isItineraryView = true;
  }
}
