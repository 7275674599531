<div>
  <app-login-header></app-login-header>
</div>

<div class="download_documents_sec">
  <div class="download_documentsCard">
    <div class="sec_head">
      <div class="head_txt">Download documents</div>
      <div class="head_sub_txt">Click to download the documents</div>
    </div>
    <div class="sec_body">
      <ul>
        <li>
          <div>
            <div class="doc_img">
              <img src="../../../../assets/icons/important-Info-document.svg" alt="important-Info" />
            </div>
            <div class="doc_desc">Important Information</div>
            <div class="doc_link">
              <a
                download="flightsite-important-Info-document"
                target="_blank"
                href="/assets/PDF-files/Important-Information.pdf"
              >
                Click Here
              </a>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div class="doc_img">
              <img src="../../../../assets/icons/pay-us-document.svg" alt="pay-us" />
            </div>
            <div class="doc_desc">How to Pay Us</div>
            <div class="doc_link">
              <a download="flightsite-pay-us-document" target="_blank" href="/assets/PDF-files/How-to-Pay-Us.pdf">
                Click Here
              </a>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div class="doc_img">
              <img src="../../../../assets/icons/faqs-document.svg" alt="faqs-document" />
            </div>
            <div class="doc_desc">FAQs</div>
            <div class="doc_link">
              <a download="flightsite-FAQ-document" target="_blank" href="/assets/PDF-files/FAQ.pdf"> Click Here </a>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div class="doc_img">
              <img src="../../../../assets/icons/card-link-document.svg" alt="" />
            </div>
            <div class="doc_desc">Card Link</div>
            <div class="doc_link">
              <a download="flightsite-Card-link-document" target="_blank" href="/assets/PDF-files/Card-Link.pdf">
                Click Here
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
