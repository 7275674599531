<div id="pause" class="d-flex align-items-center justify-content-center" *ngIf="!isPaymentProcessing">
  <div id="spinner"></div>
  <div class="ml-3 process_txt">please wait we are processing your payment</div>
</div>
<div class="text-center payment_response" *ngIf="isPaymentProcessing && paymentVerificationDetails">
  <div class="paymentSuccess" *ngIf="paymentVerificationDetails.success">
    <img class="img_section" src="../../../../assets/icons/success-green-tick.svg" alt="success_img" width="100px" />
    <div class="success_text align-self-center">Money added successfully to wallet</div>
    <button class="mt-3 click_btn primary_CTA" type="button" data-dismiss="modal" (click)="closeAddMoneySectionModal()">
      Ok
    </button>
  </div>
  <div
    class="paymentFailed"
    *ngIf="
      (!paymentVerificationDetails.success && paymentVerificationDetails.message == 'FAILED') ||
      !paymentVerificationDetails.success
    "
  >
    <div class="text-center">
      <img class="failure_img" src="../../../../../assets/icons/something_went_wrong_icon.svg" alt="payment failed" />
      <div class="mt-3">
        <div><strong translate>Sorry something went wrong. please try again.</strong></div>
      </div>
      <button
        class="mt-3 click_btn primary_CTA"
        type="button"
        data-dismiss="modal"
        (click)="closeAddMoneySectionModal()"
      >
        Ok
      </button>
    </div>
  </div>
</div>
