import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilityService } from '@app/@core/services/utility.service';
import { PERMISSIONS, isAgent, isEmployee, isSuperAdmin } from '@app/@shared/role.constants';
import { AuthenticationService } from '@app/auth/authentication.service';
import { homepagePath } from '@app/auth/routing_path';
import { ApiService } from '@app/general/services/api/api.service';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss'],
})
export class SidenavListComponent implements OnInit {
  user: any;
  selected_tab: string = 'dashboard';
  isAgent: boolean;
  isSuperAdmin: boolean;
  isEmployee: boolean;
  constructor(
    public utilityService: UtilityService,
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthenticationService,
    public apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.user = this.utilityService.getUser();
    this.isSuperAdmin = isSuperAdmin(this.user.role);
    this.isEmployee = isEmployee(this.user.role);
    this.isAgent = isAgent(this.user.role);
  }

  selectedTab(param: string) {
    this.selected_tab = param;
    this.manageNavigation(param);
  }
  isActive(param: string) {
    return this.selected_tab == param;
  }
  homePage() {
    this.router.navigate([this.route.snapshot.queryParams.redirect || homepagePath(this.user)], { replaceUrl: true });
  }
  logout() {
    this.authService.logout();
  }
  hasPermissionForUserCreation() {
    return this.hasPermissionForStaffCreationAgent();
  }
  hasPermissionForStaffList() {
    return this.hasPermissionForStaffCreationAgent();
  }
  hasPermissionForAuditLog() {
    return this.hasPermissionToViewLogsAgent();
  }
  hasPermissionForProfileAgent() {
    return this.utilityService.hasPermission(PERMISSIONS.myAccountAdmin);
  }
  hasPermissionForWalletAgent() {
    return this.utilityService.hasPermission(PERMISSIONS.walletAdmin);
  }
  hasPermissionForStaffCreationAgent() {
    return this.utilityService.hasPermission(PERMISSIONS.myAccountAdmin);
  }
  hasPermissionToViewLogsAgent() {
    return this.utilityService.hasPermission(PERMISSIONS.logAdmin);
  }
  hasPermissionToAddMarkup() {
    return this.utilityService.hasPermission(PERMISSIONS.markupAdmin);
  }
  hasPermissionForReservedBookings() {
    return this.utilityService.hasPermission(PERMISSIONS.HOLD_AND_PAY);
  }
  hasPermissionForCardLink() {
    return this.utilityService.hasPermission(PERMISSIONS.PAYMENT_LINK);
  }
  hasPermissionToAccessDashboardAPIs() {
    return this.utilityService.hasPermission(PERMISSIONS.analyticsAdmin);
  }
  openLink(link: any) {
    window.open(link, '_blank');
  }
  navigateToCreateUser() {
    if (this.isSuperAdmin || this.isEmployee) {
      this.router.navigate([`${homepagePath(this.user)}/create-user-panel`], {
        queryParams: {
          tab: 'tsEmployee',
        },
      });
    } else {
      this.router.navigate([`${homepagePath(this.user)}/create-user`]);
    }
  }
  navigateToProfile() {
    this.router.navigate([`${homepagePath(this.user)}/create-user`], {
      queryParams: {
        tab: 'Profile',
      },
    });
  }

  navigateToStaffList() {
    if (this.isSuperAdmin || this.isEmployee) {
      this.router.navigate([`${homepagePath(this.user)}/user-list-panel`], {
        queryParams: {
          tab: 'tsEmployee',
        },
      });
    } else {
      this.router.navigate([`${homepagePath(this.user)}/company-staff`]);
    }
  }
  navigateToEmailsInbox() {
    this.router.navigate([`${homepagePath(this.user)}/mails`]);
  }

  navigateToAuditLogs() {
    this.router.navigate([`${homepagePath(this.user)}/audit-logs`]);
  }
  navigateToReservedBookings() {
    this.router.navigate([`${homepagePath(this.user)}/reserved-bookings`]);
  }
  navigateToPaymentLink() {
    this.router.navigate([`${homepagePath(this.user)}/generate-paymentlink`]);
  }
  navigateToDocuments() {
    this.router.navigate([`${homepagePath(this.user)}/documents`]);
  }
  navigateToAddmarkup() {
    this.router.navigate([`${homepagePath(this.user)}/add-markup`]);
  }
  navigateToBookings() {
    this.router.navigate([`${homepagePath(this.user)}/bookings`]);
  }
  navigateToPayments() {
    this.router.navigate([`${homepagePath(this.user)}/payments`]);
  }
  navigateToTravellers() {
    this.router.navigate([`${homepagePath(this.user)}/travellers`]);
  }
  navigateToWallet() {
    this.router.navigate([`${homepagePath(this.user)}/wallet`]);
  }
  navigateOurWeb() {
    this.router.navigate([`${homepagePath(this.user)}/ourweb`]);
  }
  manageNavigation(param: any) {
    switch (param) {
      case 'Dashboard':
        this.homePage();
        break;
      case 'Profile':
        this.navigateToProfile();
        break;
      case 'AddMarkup':
        this.navigateToAddmarkup();
        break;
      case 'Bookings':
        this.navigateToBookings();
        break;
      case 'Payments':
        this.navigateToPayments();
        break;
      case 'Travellers':
        this.navigateToTravellers();
        break;
      case 'MyWallet':
        this.navigateToWallet();
        break;
      case 'CreateUser':
        this.navigateToCreateUser();
        break;
      case 'StaffList':
        this.navigateToStaffList();
        break;
      case 'AuditLogs':
        this.navigateToAuditLogs();
        break;
      case 'ReservedBookings':
        this.navigateToReservedBookings();
        break;
      case 'CardLink':
        this.navigateToPaymentLink();
        break;
      case 'documents':
        this.navigateToDocuments();
        break;
      case 'ourweb':
        this.navigateOurWeb();
        break;
      default:
        this.homePage();
    }
  }
}
