import { ImageSrcDirective } from './directives/ImageSrcDirective';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MultiSelectComponent } from './components/multi-select/multiselect.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { EditEmailTemplateComponent } from './components/edit-email-template/edit-email-template.component';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { CurrencyCodePipe } from './pipes/currency-code.pipe';
import { LoginHeaderComponent } from './components/login-header/login-header.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CustomMaterialModule } from '@app/custom-material/custom-material.module';
import { ItineraryViewComponent } from './components/itinerary-view/itinerary-view.component';
import { PermissionsComponent } from './components/permissions/permissions.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { CardComponent } from './components/card/card.component';
import { TilesComponent } from './components/tiles/tiles.component';
import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TabsHeaderComponent } from './components/tabs-header/tabs-header.component';
import { BookingHistoryTabComponent } from './components/booking-history-tab/booking-history-tab.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AgentInvoiceComponent } from './components/invoice/agent-invoice/agent-invoice.component';
import { CustomerInvoiceComponent } from './components/invoice/customer-invoice/customer-invoice.component';
import { AuditLogsComponent } from './components/audit-logs/audit-logs.component';
import { AddMoneyComponent } from './components/addMoney/addMoney.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PaystackPaymentComponent } from './components/paystack-payment/paystack-payment.component';
import { NumbersonlyDirective } from '@app/@shared/directives/numbersonly.directive';
import { IframeComponent } from './components/iframe/iframe.component';
import { GeneratePaymentLinkComponent } from './components/generate-payment-link/generate-payment-link.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { ViewReservedItineraryComponent } from './components/view-reserved-itinerary/view-reserved-itinerary.component';
import { FlightDetailsCardComponent } from './components/flight-details-card/flight-details-card.component';
import { PeachPaymentComponent } from './components/peach-payment/peach-payment.component';
import { TranslateModule } from '@ngx-translate/core';
import { DownloadDocumentsComponent } from './components/download-documents/download-documents.component';
import { BookingDataCardComponent } from './components/booking-data-card/booking-data-card.component';
import { DataTableComponent } from './components/data-table/datatable.component';
import { BankTransferComponent } from './components/bank-transfer/bank-transfer.component';
import { SidenavListComponent } from './components/sidenav-list/sidenav-list.component';
import { OurwebIframeComponent } from './components/ourweb-iframe/ourweb-iframe.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { MeiliWidgetComponent } from './components/meili-widget/meili-widget.component';
import { ManageMeiliBookingsComponent } from './components/manage-meili-bookings/manage-meili-bookings.component';
const maskConfig: Partial<IConfig> = {
  validation: true,
};

const routes: Routes = [];
@NgModule({
  imports: [
    CommonModule,
    CustomMaterialModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    RichTextEditorAllModule,
    NgxSkeletonLoaderModule,
    NgxMaskModule.forRoot(maskConfig),
    NgxDatatableModule,
    NgxPaginationModule,
    Ng2TelInputModule,
    TranslateModule,
  ],
  declarations: [
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    MultiSelectComponent,
    DialogComponent,
    EditEmailTemplateComponent,
    CurrencyCodePipe,
    LoginHeaderComponent,
    ItineraryViewComponent,
    PermissionsComponent,
    TabsComponent,
    CardComponent,
    TilesComponent,
    CreditCardComponent,
    TabsHeaderComponent,
    BookingHistoryTabComponent,
    AgentInvoiceComponent,
    CustomerInvoiceComponent,
    AuditLogsComponent,
    AddMoneyComponent,
    SpinnerComponent,
    PaystackPaymentComponent,
    NumbersonlyDirective,
    IframeComponent,
    GeneratePaymentLinkComponent,
    ViewReservedItineraryComponent,
    FlightDetailsCardComponent,
    ImageSrcDirective,
    PeachPaymentComponent,
    DownloadDocumentsComponent,
    BookingDataCardComponent,
    DataTableComponent,
    BankTransferComponent,
    SidenavListComponent,
    OurwebIframeComponent,
    SnackbarComponent,
    ContactUsComponent,
    MeiliWidgetComponent,
    ManageMeiliBookingsComponent,
  ],
  exports: [
    LoaderComponent,
    CardComponent,
    TabsComponent,
    TilesComponent,
    HeaderComponent,
    FooterComponent,
    MultiSelectComponent,
    DialogComponent,
    CurrencyCodePipe,
    EditEmailTemplateComponent,
    LoginHeaderComponent,
    ItineraryViewComponent,
    PermissionsComponent,
    CreditCardComponent,
    TabsHeaderComponent,
    BookingHistoryTabComponent,
    AgentInvoiceComponent,
    CustomerInvoiceComponent,
    SpinnerComponent,
    NumbersonlyDirective,
    PaystackPaymentComponent,
    IframeComponent,
    ImageSrcDirective,
    PeachPaymentComponent,
    DownloadDocumentsComponent,
    BookingDataCardComponent,
    DataTableComponent,
    SidenavListComponent,
    SnackbarComponent,
    MeiliWidgetComponent,
    ManageMeiliBookingsComponent,
  ],
  providers: [],
})
export class SharedModule {}
