import { Directive, ElementRef, AfterViewInit } from '@angular/core';
import { ApiService } from '@app/general/services/api/api.service';

@Directive({
  selector: '[ImageSrc]',
})
export class ImageSrcDirective implements AfterViewInit {
  el: ElementRef;

  constructor(_el: ElementRef, private apiService: ApiService) {
    this.el = _el;
  }

  ngAfterViewInit() {
    if (this.apiService.extractWhiteLabelDomain().TS_ORG === 'TS_CT') {
      let imageURL = this.el.nativeElement.src.replace('assets', 'assets/clubhub-assets');
      this.el.nativeElement.src = imageURL;
    } else {
      this.el.nativeElement.src = this.el.nativeElement.src;
    }
  }
}
