import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AgentService } from '@app/agent/services/agent.service';
import { get } from 'lodash';
import { GenerateInvoicePDF } from '../utils/generateInvoicePdf';
import type { TDocumentDefinitions } from 'pdfmake/interfaces';
import { DatePipe } from '@angular/common';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-customer-invoice',
  templateUrl: './customer-invoice.component.html',
})
export class CustomerInvoiceComponent {
  public bookingInformation: any;
  public flightsResultsResponse: any;
  custItineraryId: any;
  customerDetails: any;
  agencyDetails: any;
  flightDetails: any;
  productDetails: any;
  invoiceDetails: any;
  imageData: any;
  @ViewChild('customerpdfNote') customerpdfNote: any;
  @Input() set customerInvoiceData(data: any) {
    if (data) {
      this.custItineraryId = data.Id;
      this.getCustomerInvoice(this.custItineraryId);
    }
  }

  constructor(
    private agentService: AgentService,
    private route: Router,
    private datePipe: DatePipe,
    private generateCustomerInvoice: GenerateInvoicePDF
  ) {
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  getCustomerInvoice(itineraryId: any) {
    let data = {
      reference: itineraryId,
    };
    this.agentService.getCustomerInvoiceData(data).subscribe((response: any) => {
      if (response) {
        let blob = new Blob([response], { type: 'application/pdf' });
        let pdfUrl = window.URL.createObjectURL(blob);

        var PDF_link = document.createElement('a');
        PDF_link.href = pdfUrl;
        // TO OPEN PDF ON BROWSER IN NEW TAB
        window.open(pdfUrl, '_blank');
        // TO DOWNLOAD PDF TO YOUR COMPUTER
        PDF_link.download = 'Customer-Invoice.pdf';
        PDF_link.click();

        // this.customerDetails = data.data;
        // this.agencyDetails = get(this.customerDetails, 'agency', {});
        // this.invoiceDetails = get(this.customerDetails, 'invoice', {});
        // this.productDetails = get(this.customerDetails, 'product_deatails', {});
        // this.flightDetails = get(this.customerDetails, 'product_deatails.flight_details', {});
        // setTimeout(() => {
        //   this.generatePdf();
        // }, 500);
      }
    });
  }

  generatePdf() {
    const documentDefinition = this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).download(`${this.custItineraryId}.pdf`);
  }

  getDocumentDefinition = (): TDocumentDefinitions => {
    return {
      content: [
        {
          columns: [
            {
              stack: [
                {
                  text: this.agencyDetails?.name,
                  style: 'header',
                  margin: [0, 5],
                },
                '\n\n',
                this.generateCustomerInvoice.customerInfo(this.customerDetails),
              ],
            },
            this.generateCustomerInvoice.invoiceContactDetails(this.agencyDetails),
          ],
        },
        {
          columns: [
            { text: '' },
            {
              text: 'Invoice',
              style: 'invoice',
              margin: [0, 10],
            },
          ],
        },
        {
          columns: [
            this.generateCustomerInvoice.customerBillTo(this.customerDetails),
            this.generateCustomerInvoice.invoiceDescription(this.invoiceDetails),
          ],
        },
        {
          columns: [
            {
              text: '',
            },
            {
              style: 'invoiceDetails',
              table: {
                body: this.generateCustomerInvoice.invoiceInfoSection(this.customerDetails?.invoice),
              },
              layout: 'noBorders',
              margin: [0, 10],
            },
          ],
        },
        '\n\n',
        this.generateCustomerInvoice.flightDetailsTable(this.productDetails, this.flightDetails),
        {
          table: {
            widths: ['*'],
            body: [[' '], [' ']],
          },
          layout: this.generateCustomerInvoice.tableBottomLineUI(),
          margin: [0, -10],
        },
        '\n\n',
        {
          columns: [
            { text: '' },
            { text: '' },
            {
              style: 'priceDetails',
              table: {
                body: this.generateCustomerInvoice.priceBreakdownTable(this.productDetails),
              },
              layout: 'noBorders',
              margin: [30, 0],
            },
          ],
        },
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
        },
        agencyDetails: {
          fontSize: 12,
        },
        invoice: {
          fontSize: 14,
        },
        invoiceDetails: {
          fontSize: 10,
        },
        PriceDetails: {
          alignment: 'right',
        },
        subheader: {
          fontSize: 12,
          bold: true,
        },
        text: {
          fontSize: 12,
        },
        priceDetailsHeader: {
          fontSize: 12,
          bold: true,
        },
      },
    };
  };
}
