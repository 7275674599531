<div class="d-none d-lg-block">
  <section class="">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 mx-auto">
          <ng-container *ngTemplateOutlet="login"> </ng-container>
        </div>
      </div>
    </div>
  </section>
</div>
<div class="modal bottom fade" id="signin-modal" tabindex="-1" role="dialog" aria-labelledby="signin-modal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <ng-container *ngTemplateOutlet="login"> </ng-container>
      </div>
    </div>
  </div>
</div>
<ng-template #login>
  <div class="bg-white shadow-lg p-4 pb-3 login-section">
    <div class="d-sm-flex mb-3">
      <h5 class="login_head">Login</h5>
      <div class="ml-auto d-none d-lg-block signup_sec" *ngIf="!apiService.toDisplayForClubhub()">
        <span class="mr-1 sub_Txt" translate>Not a Travelstart member?</span>
        <span (click)="navigateToSignUp()" class="signup_Txt"> Signup </span>
      </div>
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="signIn()" #f="ngForm">
      <div class="row mx-n2">
        <div class="col-md-5 mb-4 mb-md-0 px-2">
          <input type="text" class="form-control" placeholder="Email ID" formControlName="username" required />
          <div class="validationError_msg" *ngIf="f.form.controls['username']?.errors && isLoginFormSubmitted">
            <span *ngIf="f.form.controls['username'].errors.required">Please enter valid email </span>
          </div>
        </div>
        <div class="password-input col-md-5 mb-4 mb-md-0 px-2">
          <input
            [type]="shouldShowPassword ? 'text' : 'password'"
            class="form-control"
            placeholder="Password"
            formControlName="password"
            required
          />
          <mat-icon (click)="shouldShowPassword = !shouldShowPassword">
            <img
              [src]="
                shouldShowPassword ? '../../../../assets/icons/eye.svg' : '../../../../assets/icons/eye_cross_icon.svg'
              "
              alt="eye-icon"
            />
          </mat-icon>
          <div class="validationError_msg" *ngIf="f.form.controls['password']?.errors && isLoginFormSubmitted">
            <span *ngIf="f.form.controls['password'].errors.required">Please enter your password</span>
          </div>
        </div>
        <div class="col-md-2 px-2">
          <button class="btn primary_CTA loginCTA" [disabled]="isAgentLoggingIn">
            <i class="fa fa-spinner fa-spin mr-2" aria-hidden="true" [hidden]="!isAgentLoggingIn"></i>Login
          </button>
        </div>
      </div>
    </form>
    <div class="text-right signup_sec mt-2">
      <a class="link forgotPwd_Txt" routerLink="/forgot-password">Forgot password</a>
    </div>

    <div class="ml-auto mt-3 d-lg-none signup_sec text-center" *ngIf="!apiService.toDisplayForClubhub()">
      <span class="mr-2" translate>Not a Travelstart member?</span>
      <a (click)="navigateToSignUp()" class="fw-semibold text-theme"> Signup </a>
    </div>
  </div>
</ng-template>
