import { ApiService } from './../../../general/services/api/api.service';
import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-manage-meili-bookings',
  templateUrl: './manage-meili-bookings.component.html',
  styleUrls: ['./manage-meili-bookings.component.scss'],
})
export class ManageMeiliBookingsComponent implements OnInit {
  constructor(private renderer: Renderer2, private el: ElementRef, private apiService: ApiService) {}

  ngOnInit(): void {
    this.loadMeiliScript();
  }
  loadMeiliScript() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = this.apiService.getMeiliBookingManagerUrl();
    script.defer = true;
    this.renderer.appendChild(this.el.nativeElement, script);
  }
}
