<div class="pb-5 main">
  <app-login-header></app-login-header>

  <div class="audit-card container-fluid">
    <div class="audit_sec_head">
      <div class="audit-text">
        <img
          src="../../../../assets/icons/back-arrow.svg"
          class="mr-3 back-page d-lg-none"
          alt="left-short-arrow"
          (click)="backToDashboard()"
        />
        Audit Logs
      </div>
    </div>
    <div class="audit_card_body">
      <div class="search_head">
        <div class="d-flex"></div>
        <div class="d-flex">
          <div class="input-group form-filter-control mr-2 d-none d-lg-flex">
            <div class="input-group-prepend position-relative selectGroup z-index-1">
              <select class="fiter-type pl-2" [formControl]="filterType">
                <option disabled value="">Select</option>
                <option value="agent">Agent Email</option>
                <option value="module">Module Name</option>
                <option value="agency_id" [hidden]="!isSuperAdmin">Agency Id</option>
              </select>
            </div>
            <input
              type="text"
              class="search-input pl-2"
              placeholder="Search..."
              [formControl]="inputSearchBox"
              (keyup)="
                $event.target.value.length > 3 || $event.target.value.length == 0
                  ? handleSearch($event.target.value)
                  : ''
              "
            />
            <span class="cancelIcon" *ngIf="inputSearchBox.value">
              <img src="../../../../assets/icons/cross-nt-available.svg" alt="round" (click)="clearSearchBox()" />
            </span>
          </div>
          <div class="mr-2">
            <input
              type="text"
              class="form-control dates form-filter-control"
              placeholder="{{ 'From Date...' }}"
              name="dp"
              autocomplete="off"
              [formControl]="fromDate"
              onfocus="(this.type='date')"
              (change)="handleDateRangeSelection({ from_date: $event.target.value })"
            />
          </div>
          <div class="mr-2">
            <input
              type="text"
              class="form-control dates form-filter-control"
              placeholder="{{ 'To Date...' }}"
              name="dp"
              autocomplete="off"
              [formControl]="toDate"
              onfocus="(this.type='date')"
              (change)="handleDateRangeSelection({ to_date: $event.target.value })"
            />
          </div>
        </div>
      </div>
      <div class="d-none d-lg-block">
        <app-spinner [isLoading]="isLoading"></app-spinner>
        <div class="auditLogTable d-none d-lg-block" *ngIf="rows.length != 0">
          <ngx-datatable
            #auditTable
            class="material"
            [rows]="rows"
            [columnMode]="'force'"
            [headerHeight]="40"
            [footerHeight]="50"
            [rowHeight]="55"
            [scrollbarV]="false"
            [externalPaging]="true"
            [limit]="pages.limit"
            [count]="pages.totalCount"
            [offset]="pages.offset"
            (page)="handlePagination($event.offset + 1)"
            [reorderable]="false"
          >
            <ngx-datatable-column name="USER">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="d-flex">
                  <div>
                    <span class="d-inline">
                      <span class="logo" [ngClass]="{ username2char: getUserInitials(row.user_name)?.length >= 2 }">
                        {{ getUserInitials(row.user_name) }}
                      </span>
                    </span>
                  </div>
                  <div>
                    <div class="user-name">
                      {{ row.user_name }}
                    </div>
                    <div class="user-email">
                      {{ row.user_email }}
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="ACTION">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="user-name">{{ row.action }}</div>
                <div class="user-email">{{ row.msg }}</div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="DATE AND TIME">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span class="date-time">{{ row.timestamp | date: 'medium' }} </span>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
        <div class="container err_section mb-4 pt-4 bg-white" *ngIf="rows.length == 0 && !isLoading">
          <div class="err_page text-center">
            <div class="container error_body">
              <div class="sec_img d-flex justify-content-center">
                <img
                  class="img-fluid d-none d-lg-block"
                  src="../../../../assets/icons/nff-web.svg"
                  alt="server Error"
                />
              </div>
              <div class="err_msg">
                <p translate class="Trip_head">{{ errMessage }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="second-card d-block d-lg-none">
        <app-spinner [isLoading]="isLoading"></app-spinner>
        <div class="mt-3" class="card-detail" *ngIf="rows.length != 0">
          <div
            class="cardItem"
            *ngFor="
              let row of rows
                | paginate
                  : {
                      id: 'auditPagination',
                      itemsPerPage: pages.limit,
                      currentPage: activePage,
                      totalItems: pages.totalCount
                    }
            "
          >
            <div body>
              <div class="d-flex mb-2">
                <div>
                  <span class="card-label">User</span>
                  <h6 class="card-text">{{ row.user_name }}</h6>
                </div>
                <div class="ml-auto text-right">
                  <span class="card-label">Date</span>
                  <h6 class="card-text">
                    {{ row.timestamp | date: 'EEEE h:mm a' }}
                  </h6>
                </div>
              </div>

              <div class="d-flex">
                <div>
                  <span class="card-label">Email</span>
                  <h6 class="card-text">
                    {{ row.user_email }}
                  </h6>
                </div>
              </div>

              <div class="d-flex">
                <div>
                  <span class="card-label">Action</span>
                  <h6 class="card-text">
                    {{ row.action }}
                  </h6>
                  <div class="card-text action-msg">
                    {{ row.msg }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container err_section mb-4 pt-4 bg-white" *ngIf="rows.length == 0 && !isLoading">
          <div class="err_page text-center">
            <div class="container error_body">
              <div class="sec_img d-flex justify-content-center">
                <img class="img-fluid d-lg-none" src="../../../../assets/icons/nff-web.svg" alt="nff-web" />
              </div>
              <div class="err_msg">
                <p translate class="Trip_head">{{ errMessage }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center" *ngIf="rows.length != 0">
          <pagination-controls
            id="auditPagination"
            directionLinks="true"
            autoHide="true"
            responsive="true"
            (pageChange)="handlePagination($event)"
          >
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
