import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/auth/authentication.service';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {}
  snackbarAction(param: any) {
    this.snackBarRef.dismiss();
    this.authenticationService.logout();
    this.navigateLoginPage();
  }
  navigateLoginPage() {
    this.router.navigate(['register'], {
      queryParams: {
        tab: 'login',
      },
    });
  }
}
