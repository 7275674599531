import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from '../service/payment.service';
import { AgentService } from '@app/agent/services/agent.service';
declare let $: any;
@Component({
  selector: 'app-redirectgateway',
  templateUrl: './redirectgateway.component.html',
  styleUrls: ['./redirectgateway.component.scss'],
})
export class RedirectgatewayComponent implements OnInit {
  bookApiPayload: any;
  redirectGatewayAuth: any = null;
  isLoading = true;
  paymentVerificationDetails: any;
  isPaymentProcessing: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private router: Router,
    private agentService: AgentService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((param) => {
      console.log(param);
      this.redirectGatewayAuth = param;
    });
    if (this.redirectGatewayAuth) {
      this.paymentVerification();
    }
  }
  proceedPayment() {
    delete this.bookApiPayload['paymentReturnURL'];
    this.bookApiPayload.gatewayPostResponseParameters = JSON.parse(this.redirectGatewayAuth);
    this.paymentService.GatewayPaymentBookFlight(this.bookApiPayload).subscribe(
      (data: any) => {
        let bookingInfo = data;
        if (bookingInfo.bookingInformation) {
          sessionStorage.setItem('bookingDetails', JSON.stringify(bookingInfo));
          this.router.navigate(['/payments/bookingConfirm']);
        } else if (!bookingInfo.bookingInformation) {
          this.router.navigate(['/payments']);
        }
      },
      (error) => {
        if (error.error) {
          this.router.navigate(['/payments']);
        }
      }
    );
  }
  paymentVerification() {
    const reqObj = {
      reference: this.redirectGatewayAuth.reference,
    };
    this.agentService.paymentVerifyCall(reqObj).subscribe(
      (res: any) => {
        if (res.success) {
          window.parent.document.getElementById('threeDSecureIFrame').style.minHeight = '250px';
        }
        this.paymentVerificationDetails = res;
        this.isPaymentProcessing = true;
      },
      (errors) => {
        this.paymentVerificationDetails.message = 'FAILED';
        this.paymentVerificationDetails.success = false;
      }
    );
  }
  closeAddMoneySectionModal() {
    if (window.parent.document.getElementById('closeAddMoneyToWallet_Modal')) {
      window.parent.document.getElementById('closeAddMoneyToWallet_Modal').click();
    }
  }
}
