<div class="container-fluid mt-3 mx-0 permission_card">
  <div class="d-flex pb-3">
    <span class="permission-label">Permissions</span>
  </div>
  <div class="permission-tab">
    <ul class="nav nav-tabs" id="myTab">
      <li class="nav-item">
        <a
          href="#widget"
          id="widget-tab"
          class="nav-link"
          [ngClass]="{ active: selectedActive('widget') }"
          data-toggle="tab"
          rel="nofollow"
          role="tab"
          aria-controls="widget"
          aria-selected="false"
          (click)="selectTab('widget')"
        >
          <span class="tabLabel">Widget</span></a
        >
      </li>
      <li class="nav-item">
        <a
          href="#permissions"
          id="permissions-tab"
          [ngClass]="{ active: selectedActive('permissions'), disabled: isPermissionsTabDisabled }"
          class="nav-link"
          data-toggle="tab"
          rel="nofollow"
          role="tab"
          aria-controls="permissions"
          aria-selected="false"
          (click)="selectTab('permissions')"
        >
          <span class="tabLabel">Permissions</span></a
        >
      </li>
    </ul>
    <div class="tab-content" id="tabcontentData">
      <div class="tab-pane" id="widget" role="tabpanel" [ngClass]="{ active: selectedActive('widget') }">
        <div *ngIf="selectedTab == 'widget'">
          <app-spinner [isLoading]="areProductsLoading"></app-spinner>
          <div>
            <ul class="list-unstyled d-flex flex-wrap pt-4 permission-widget">
              <li class="px-2" *ngFor="let product of products">
                <div class="custom-pills_product">
                  <label>
                    <input
                      type="checkbox"
                      name="product"
                      class="d-none"
                      [checked]="true"
                      value="{{ product.id }}"
                      (change)="productSelectionHandler($event, product.id)"
                    />
                    <span>{{ product.name }}</span>
                  </label>
                </div>
              </li>
            </ul>
            <div class="d-lg-flex">
              <div class="ml-lg-auto permission-next-btn">
                <button
                  class="btn btn-primary primary_CTA"
                  [disabled]="isPermissionsTabDisabled"
                  (click)="switchToNextTab()"
                >
                  {{ nextButtonText }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane" id="permissions" role="tabpanel" [ngClass]="{ active: selectedActive('permissions') }">
        <div *ngIf="selectedTab == 'permissions'">
          <div class="permissionsList p-0">
            <ul class="list-unstyled d-lg-flex flex-wrap pt-4">
              <li class="col-lg-6">
                <div class="custom-pills_permissions">
                  <span class="mr-3" matTooltip="{{ ALL_PERMISSIONS }}" [matTooltipPosition]="'below'"> All </span>
                  <label class="toggle-switch float-right">
                    <input
                      type="checkbox"
                      name="All"
                      class="d-none"
                      (click)="permissionsSelectionHandler($event.target.value)"
                      value="all"
                      [(ngModel)]="areAllPermissionsSelected"
                    />
                    <div class="slider_switch"></div>
                  </label>
                </div>
              </li>
              <li *ngFor="let permission of permissions" class="col-lg-6">
                <div class="custom-pills_permissions">
                  <div>
                    <span class="mr-3" matTooltip="{{ permission.description }}" [matTooltipPosition]="'below'">
                      {{ permission.permission_name }}
                    </span>
                    <label class="toggle-switch float-right">
                      <input
                        type="checkbox"
                        name="{{ permission.permission_name }}"
                        class="d-none"
                        (click)="permissionsSelectionHandler($event.target.value)"
                        value="{{ permission.id }}"
                        [(ngModel)]="permission.isSelected"
                      />
                      <div class="slider_switch"></div>
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="d-lg-flex">
            <div class="permission-create-btn d-flex ml-lg-auto">
              <button *ngIf="shouldShowCancelButton" class="btn default_CTA mr-3 text-capitalize" data-dismiss="modal">
                Cancel
              </button>
              <button class="btn primary_CTA text-capitalize" (click)="submitForm()">
                <i class="fa fa-spinner fa-spin mr-2" *ngIf="isSubmitting" aria-hidden="true"></i>
                <span>{{ submitButtonText }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
