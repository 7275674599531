import { FormGroup } from '@angular/forms';

const PASSWORD = 'password';
const CONFIRM_PASSWORD = 'confirmPassword';

export const passwordValidator = (formGroup: FormGroup) => {
  const { value: password } = formGroup.get(PASSWORD);
  const { value: confirmPassword } = formGroup.get(CONFIRM_PASSWORD);
  return password === confirmPassword ? null : { passwordNotMatch: true };
};
