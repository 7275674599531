import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { UtilityService } from '@app/@core/services/utility.service';
import { Router } from '@angular/router';
import { AgentService } from '@app/agent/services/agent.service';
import { homepagePath } from '@app/auth/routing_path';
import { isSuperAdmin, isAgent } from '@app/@shared/role.constants';
import { ApiService } from '../../../general/services/api/api.service';

const PENDING = 'Pending';
const FAILED = 'failed';

@Component({
  selector: 'app-booking-history-tab',
  templateUrl: './booking-history-tab.component.html',
  styleUrls: ['./booking-history-tab.component.scss', '../../../../theme/ngx-datatable.scss'],
})
export class BookingHistoryTabComponent {
  @ViewChild('myTable') table: any;

  rows: any = [];
  temp: any;
  filterVal: any;
  public count = 100;
  public pageSize = 3;
  public limit = 10;
  public offset = 0;
  public selected: any;
  rowsData: any = [];
  fromDate = new FormControl('');
  toDate = new FormControl('');
  searchAll = new FormControl('');
  minDate: any;
  maxDate: any;
  startDate: any;
  endDate: any;
  currentDate = new Date();
  dateFilterData: any = [];
  user: any;
  viewAgentInvoice: boolean = false;
  customerInvoiceData: any = {};
  agentInvoiceData: any = {};
  viewCustomerInvoice: boolean = false;
  activePage: number = 1;
  invoiceOptionsIdForBookings: string = null;
  ALL: string = 'All';
  UPCOMING: string = 'Upcoming';
  CANCELLED: string = 'Cancelled';
  PAID: string = 'Paid';
  bookingHist: any;
  isSuperAdmin: boolean;
  isAgent: boolean;
  isLoading: boolean = true;
  isBookingHistoryLoading: boolean = false;
  downloadable_Link: string = null;

  pages = {
    limit: 10,
    totalCount: 0,
    offset: 0,
  };
  queryParams: any = {};

  constructor(
    private utilityService: UtilityService,
    private router: Router,
    private agentService: AgentService,
    public apiService: ApiService
  ) {}

  ngOnInit() {
    this.rows = this.bookingHist;
    this.temp = this.rows;
    this.filterVal = this.rows;
    this.selectSort(1);
    this.user = this.utilityService.getUser();
    this.bookingHistory();
    this.isSuperAdmin = isSuperAdmin(this.user.role);
    this.isAgent = isAgent(this.user.role);
  }
  public onPage(event: any): void {
    this.count = event.count;
    this.pageSize = event.pageSize;
    this.limit = event.limit;
    this.offset = event.offset + 1;
    this.pages.offset = event.offset;
    this.updateFilter();
  }
  bookingHistory(params?: any) {
    this.isBookingHistoryLoading = true;
    this.agentService.getBookingHistory(params).subscribe((res: any) => {
      if (res) {
        this.downloadable_Link = res.link;
        this.isBookingHistoryLoading = false;
        this.bookingHist = res.data.sort((a: any, b: any) => +new Date(b.timestamp) - +new Date(a.timestamp));
        this.rows = this.bookingHist;
        this.pages.totalCount = res.total_count;
        this.temp = this.rows;
        this.filterVal = this.rows;
        this.isLoading = false;
      } else {
        this.isBookingHistoryLoading = false;
        this.bookingHist = [];
      }
    });
  }

  toggleExpandRow(row: any) {
    console.log(row);
    this.table.rowDetail.toggleExpandRow(row);
  }
  onDetailToggle(event: any) {
    return event;
  }
  isActive(item: any) {
    return this.selected === item;
  }
  public selectSort(paramId: any): void {
    this.selected = paramId;
  }
  updateSearchFilter() {
    if (this.searchAll.value.length > 3) {
      this.pages.offset = 0;
      this.fromDate.setValue('');
      this.toDate.setValue('');
      this.updateFilter();
    } else if (this.searchAll.value.length == 0) {
      this.pages.offset = 0;
      this.updateFilter();
    }
  }
  updateDateFilter() {
    if (this.fromDate.value && this.toDate.value) {
      this.updateFilter();
    }
  }
  updateFilter() {
    let searchval: any = '';
    let fromDate: any = '';
    let toDate: any = '';
    if (this.searchAll.value.length > 3) {
      searchval = this.searchAll.value.toString().toLowerCase();
    }
    if (this.fromDate.value && this.toDate.value) {
      fromDate = this.fromDate.value;
      toDate = this.toDate.value;
    }
    this.queryParams = {
      ...this.queryParams,
      page: this.pages.offset + 1,
      refrence_search: searchval,
      from_date: fromDate,
      to_date: toDate,
    };
    this.bookingHistory(this.queryParams);
  }
  ngAfterViewInit() {
    this.fromDate.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          if (
            this.fromDate.value != null ||
            (this.fromDate.value != '' && this.toDate.value != null) ||
            this.toDate.value != ''
          ) {
            this.updateDateFilter();
          }
        })
      )
      .subscribe();
    this.toDate.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          if (
            this.toDate.value != null ||
            (this.toDate.value != '' && this.fromDate.value != null) ||
            this.fromDate.value != ''
          ) {
            this.updateDateFilter();
          }
        })
      )
      .subscribe();
  }

  generateAgentInvoice(row: any) {
    this.viewAgentInvoice = true;
    this.viewCustomerInvoice = false;
    this.agentInvoiceData = {
      Id: row.reference,
    };
  }
  generateCustomerInvoice(row: any) {
    this.viewCustomerInvoice = true;
    this.viewAgentInvoice = false;
    this.customerInvoiceData = {
      Id: row.reference,
    };
  }
  viewItinerary(row: any) {
    this.router.navigate([`${homepagePath(this.user)}/itineraryView`], {
      queryParams: {
        Id: row.reference,
        dept_city: row.from_location,
        arr_city: row.to_location,
        triptype: row.trip,
      },
    });
  }
  onPageChange(event: any) {
    this.activePage = event;
    this.pages.offset = event - 1;
    this.updateFilter();
  }
  showInvoiceOptions(booking: any) {
    if (this.invoiceOptionsIdForBookings) {
      this.invoiceOptionsIdForBookings = null;
    } else this.invoiceOptionsIdForBookings = booking.from_location + booking.to_location + booking.reference;
  }
  shouldShowInvoiceOptions(booking: any) {
    return this.invoiceOptionsIdForBookings === booking.from_location + booking.to_location + booking.reference;
  }
  backToDashboard() {
    this.router.navigate([`${homepagePath(this.user)}`]);
  }

  downloadFile(filename: string): void {
    this.agentService.downloadExcelFiles(filename, this.downloadable_Link);
  }
}
