<footer class="footer_area">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6 mb-2 mb-lg-0 px-sm-30">
        <div class="single_ftr">
          <h4 class="sf_title" translate>PLAN YOUR TRIP</h4>
          <ul>
            <li>
              <a translate href="{{ 'products&servicesLink' | translate }}" rel="noopener" target="_blank">
                Products and services</a
              >
            </li>
            <li><a translate href="{{ 'FAQLink' | translate }}" rel="noopener" target="_blank"> FAQ</a></li>
            <li><a translate href="{{ 'blogLink' | translate }}" rel="noopener" target="_blank"> Blog</a></li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mb-2 mb-lg-0 px-sm-30">
        <div class="single_ftr">
          <h4 class="sf_title" translate>PARTNERS</h4>
          <ul>
            <li><a translate href="{{ 'affillateLink' | translate }}">Become an affillate</a></li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mb-2 mb-lg-0 px-sm-30">
        <div class="single_ftr">
          <h4 class="sf_title" translate>NEED HELP?</h4>
          <ul>
            <li translate>telephoneNumber</li>
            <li translate>emailAddress</li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mb-2 mb-lg-0 px-sm-30">
        <div class="single_ftr" *ngIf="!apiService.isFlightsiteDomain()">
          <h4 class="sf_title">SUBSCRIBE TO OUR NEWSLETTER</h4>
          <div class="newsletter_form d-lg-flex">
            <div class="">
              <input
                name="EMAIL"
                id="email"
                placeholder="Your Email"
                class="form-control"
                type="email"
                [formControl]="email"
              />
              <div class="validation_msg" *ngIf="submitted && email.errors">
                <div *ngIf="submitted && email.errors.required">Email is required</div>
                <div *ngIf="submitted && email.errors.pattern">Email is Invalid</div>
              </div>
            </div>
            <button
              type="submit"
              class="btn submitBtn primary_CTA"
              [disabled]="isLoading"
              (click)="apiService.toDisplayForClubhub() ? getNewsLetterSubscribtion() : ''"
            >
              <i class="fa fa-spinner fa-spin" [hidden]="!isLoading" aria-hidden="true"></i>Submit
            </button>
          </div>
        </div>
        <div class="single_ftr" *ngIf="apiService.isFlightsiteDomain()">
          <h4 class="sf_title">SUBSCRIBE TO OUR NEWSLETTER</h4>
          <ul>
            <li translate>
              <a translate href="{{ 'NewsletterLink' | translate }}" rel="noopener" target="_blank"
                >Subscribe to our newsletter</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="ftr_btm_area" *ngIf="!apiService.toDisplayForWhitelabel()">
    <div class="container">
      <div class="row">
        <div class="col-sm-2">
          <div class="ftr_social_icon"></div>
        </div>
        <div class="col-sm-8">
          <p class="copyright_text text-center">
            Copyright © 1999-{{ date | date: 'yyyy' }} Travelstart online Travel Operations Pty LTD
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
