import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bank-transfer',
  templateUrl: './bank-transfer.component.html',
  styleUrls: ['./bank-transfer.component.scss'],
})
export class BankTransferComponent implements OnInit {
  offlinebanksList = [
    {
      name: 'Nedbank',
      url: 'https://personal.nedbank.co.za/home.html',
      branch_code: '198765',
      account_number: '1198338121',
      account_holder: 'CLUB TRAVEL SA PTY LTD',
      SWIFTcode: 'NEDSZAJJ',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
