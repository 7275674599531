import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@app/general/services/api/api.service';
import { RestService } from '@app/@core/services/rest.service';
import { FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../authentication.service';
import { UtilityService } from '@app/@core/services/utility.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { get } from 'lodash';
import { numInputNoChars } from '@app/general/utils/regex-utils';
@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss'],
})
export class OTPVerificationComponent implements OnInit {
  isVerified: boolean = false;
  OTP_Control = new FormControl('', [Validators.required]);
  submitted: boolean = false;
  isResend_OTP: boolean = false;
  tokenData: any = null;
  constructor(
    public apiService: ApiService,
    private router: Router,
    private restService: RestService,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private utilityService: UtilityService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.tokenData = sessionStorage.getItem('accessTokenData')
      ? JSON.parse(sessionStorage.getItem('accessTokenData'))
      : null;
  }
  switchToLogIn() {
    this.router.navigate(['register'], {
      queryParams: {
        tab: 'login',
      },
    });
  }
  verify_OTP() {
    this.submitted = true;
    this.isVerified = true;
    if (this.OTP_Control.invalid) {
      this.isVerified = false;
      return;
    } else if (this.OTP_Control.valid) {
      this.verifyOTP();
    }
  }
  verifyOTP() {
    let otpReq = {
      email: this.tokenData.email,
      verify_otp: this.OTP_Control.value,
    };
    this.restService.verify_OTP_SMS(otpReq).subscribe(
      (res: any) => {
        if (res?.access) {
          let tokenData = this.tokenData;
          tokenData['access'] = res.access;
          tokenData['refresh'] = res.refresh;
          this.utilityService.setAuthToken(tokenData);
          this.agentLogin();
        } else {
          this.isVerified = false;
          this._snackBar.open(`${res.message}`, 'X');
        }
      },
      (errors) => {
        this.isVerified = false;
        if (errors.error) {
          this._snackBar.open(`${get(errors, 'error.message', 'OTP has expired or invalid')}`, 'X');
        }
      }
    );
  }
  navigateToDashboard() {
    this.router.navigate([this.route.snapshot.queryParams.redirect || '/agent'], { replaceUrl: true });
  }
  resendOTP() {
    let resendReq = {
      email: this.tokenData?.email,
    };
    this.restService.send_OTP_SMS(resendReq).subscribe((data: any) => {
      if (data.success) {
        this._snackBar.open(`${data.message}`, 'X');
      }
    });
  }
  onlyNumbers(event: any) {
    return numInputNoChars(event);
  }
  agentLogin() {
    this.restService.login().subscribe((res: any) => {
      if (res) {
        this.isVerified = false;
        this.authService.setIsloggedIn();
        this.utilityService.setUser(res.data);
        this.router.navigate([this.route.snapshot.queryParams.redirect || '/agent'], { replaceUrl: true });
      }
    });
  }
}
