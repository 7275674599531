import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AgentService } from '@app/agent/services/agent.service';
import { GenerateInvoicePDF } from '../utils/generateInvoicePdf';
import type { TDocumentDefinitions } from 'pdfmake/interfaces';
import { DatePipe } from '@angular/common';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-agent-invoice',
  templateUrl: './agent-invoice.component.html',
})
export class AgentInvoiceComponent {
  public bookingInformation: any;
  public flightsResultsResponse: any;
  @ViewChild('pdfNote') pdfNote: any;
  @Input() set agentInvoiceData(x: any) {
    this.itineraryId = x.Id;
    this.getInvoice(this.itineraryId);
  }
  itineraryId: any;
  agentDetails: any;
  flightDetails: any;
  productDetails: any;
  imageData: any;
  constructor(
    private route: Router,
    private agentService: AgentService,
    private datePipe: DatePipe,
    private generateAgentInvoice: GenerateInvoicePDF
  ) {
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  getInvoice(itineraryId: any) {
    let data = {
      reference: itineraryId,
    };
    this.agentService.getAgentInvoiceData(data).subscribe((response: any) => {
      if (response) {
        let blob = new Blob([response], { type: 'application/pdf' });
        let pdfUrl = window.URL.createObjectURL(blob);

        var PDF_link = document.createElement('a');
        PDF_link.href = pdfUrl;
        // TO OPEN PDF ON BROWSER IN NEW TAB
        window.open(pdfUrl, '_blank');
        // TO DOWNLOAD PDF TO YOUR COMPUTER
        PDF_link.download = 'agent-invoice.pdf';
        PDF_link.click();

        // this.agentDetails = data.data;
        // this.flightDetails = this.agentDetails.product_deatails.flight_details;
        // this.productDetails = this.agentDetails.product_deatails;
        // this.getBase64ImageFromUrl(this.agentDetails.agency.agency_logo).then((result: any) => this.imageData =  result)
        // setTimeout(() => {
        //   this.generatePdf();
        // }, 500);
      }
    });
  }

  async getBase64ImageFromUrl(imageUrl: any) {
    let res = await fetch(imageUrl);
    let blob = await res.blob();

    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  generatePdf() {
    const documentDefinition = this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).download(`${this.itineraryId}.pdf`);
  }

  getDocumentDefinition = (): TDocumentDefinitions => {
    return {
      content: [
        {
          columns: [
            {
              stack: [
                {
                  image: `${this.imageData}`,
                  width: 100,
                  margin: [0, 5],
                },
                '\n\n',
                this.generateAgentInvoice.customerInfo(this.agentDetails),
              ],
            },
            this.generateAgentInvoice.invoiceContactDetails(this.agentDetails?.agency),
          ],
        },
        {
          columns: [
            { text: '' },
            {
              text: 'Invoice',
              style: 'invoice',
              margin: [0, 10],
            },
          ],
        },
        {
          columns: [
            this.generateAgentInvoice.customerBillTo(this.agentDetails),
            this.generateAgentInvoice.invoiceDescription(this.agentDetails?.invoice),
          ],
        },
        {
          columns: [
            {
              text: '',
            },
            {
              style: 'invoiceDetails',
              table: {
                body: this.generateAgentInvoice.invoiceInfoSection(this.agentDetails?.invoice),
              },
              layout: 'noBorders',
              margin: [0, 10],
            },
          ],
        },
        '\n\n',
        this.generateAgentInvoice.flightDetailsTable(this.productDetails, this.flightDetails),
        {
          table: {
            widths: ['*'],
            body: [[' '], [' ']],
          },
          layout: this.generateAgentInvoice.tableBottomLineUI(),
          margin: [0, -10],
        },
        '\n\n',
        {
          columns: [
            { text: '' },
            { text: '' },
            {
              style: 'priceDetails',
              table: {
                body: this.generateAgentInvoice.priceBreakdownTable(this.productDetails),
              },
              layout: 'noBorders',
              margin: [30, 0],
            },
          ],
        },
      ],
      styles: {
        agentDetails: {
          fontSize: 12,
        },
        invoice: {
          fontSize: 14,
        },
        invoiceDetails: {
          fontSize: 10,
        },
        PriceDetails: {
          alignment: 'right',
        },
        subheader: {
          fontSize: 12,
          bold: true,
        },
        text: {
          fontSize: 12,
        },
        priceDetailsHeader: {
          fontSize: 12,
          bold: true,
        },
      },
    };
  };
}
