<div class="position-absolute w-100 nav-container">
  <div class="container px-lg-3 px-0">
    <nav class="navbar navbar-light">
      <a class="navbar-brand" [routerLink]="'/'" routerLinkActive="active">
        <img
          *ngIf="apiService.extractWhiteLabelDomain().TS_ORG !== 'TS_FS'"
          class="img-fluid"
          src="assets/organization-logos/{{ apiService.extractWhiteLabelDomain().TS_ORG }}.svg"
          width="160"
          alt="{{ apiService.extractWhiteLabelDomain().TS_ORG }}"
        />
        <img
          *ngIf="apiService.isFlightsiteDomain()"
          class="img-fluid"
          src="assets/organization-logos/flight-site_logo.png"
          width="160"
          alt="travelstart-logo"
        />
      </a>

      <div class="d-flex flex-fill" id="navbarNav">
        <ul class="d-flex list-unstyled mb-0 ml-auto" *ngIf="showHeadActions$ | async">
          <li class="nav-item d-none d-lg-block" *ngIf="!apiService.toDisplayForClubhub()">
            <button (click)="scrollToAboutUsHandler()" class="btn btn-link text-uppercase fs-12">About us</button>
          </li>
          <li class="ml-3 d-lg-none d-inline" *ngIf="!apiService.toDisplayForClubhub()">
            <button
              mat-raised-button
              class="agent_signup primary_CTA"
              [routerLink]="'/register'"
              routerLinkActive="active"
              (click)="routToSignUp()"
            >
              Sign Up
            </button>
          </li>
          <li class="ml-3 d-none d-lg-inline">
            <button
              mat-raised-button
              class="agent_signup primary_CTA"
              [routerLink]="'/register'"
              routerLinkActive="active"
              (click)="routToSignIn()"
            >
              Sign In
            </button>
          </li>
          <li class="ml-3 d-lg-none">
            <button class="loginBtn primary_CTA" mat-raised-button data-toggle="modal" data-target="#signin-modal">
              Login
            </button>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
