import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilityService } from '@app/@core/services/utility.service';
import { AgentService } from '@app/agent/services/agent.service';
declare let $: any;
@Component({
  selector: 'app-edit-email-template',
  templateUrl: './edit-email-template.component.html',
  styleUrls: ['./edit-email-template.component.scss'],
})
export class EditEmailTemplateComponent implements OnInit {
  constructor(
    private utilityService: UtilityService,
    private sanitizer: DomSanitizer,
    private agentService: AgentService
  ) {
    this.user = this.utilityService.getUser();
  }
  @Output() showListEvnt = new EventEmitter<any>();
  @Input() set contentData(val: any) {
    if (val) {
      this.templateData = val;
      this.templateId = val.id;
      this.editContent = val.html;
      console.log(this.editContent);
    }
  }
  editTemplate: boolean = true;
  editContent: any;
  templateId: number;
  previewtemplate: any;
  templateData: any;
  showLiveBtn: boolean = false;
  public tools: object = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      '|',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      '|',
      'LowerCase',
      'UpperCase',
      '|',
      'Undo',
      'Redo',
      '|',
      'Formats',
      'Alignments',
      '|',
      'OrderedList',
      'UnorderedList',
      '|',
      'Indent',
      'Outdent',
      '|',
      'CreateLink',
      'CreateTable',
      'Image',
      '|',
      'ClearFormat',
      'Print',
      'SourceCode',
      '|',
      'FullScreen',
    ],
  };
  public val = 'sdfsdf';
  public user: any = '';
  public values: any = '';
  ngOnInit(): void {}
  getData(html: any) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  viewTemplete() {
    this.showListEvnt.emit(true);
  }
  viewEditTemplete() {
    this.editTemplate = true;
  }
  previewTemplate() {
    this.agentService.previewEmailTemplates(this.templateId).subscribe((data: any) => {
      if (data.success == true) {
        this.previewtemplate = data.data;
        console.log(this.previewtemplate);
        this.editTemplate = false;
      }
    });
  }
  savetemplate() {
    $('#confirmEdit_Modal').modal('show');
    console.log(this.editContent);
  }
  publishTemplate() {
    this.agentService.publishEmailTemplates(this.templateId).subscribe((data: any) => {
      console.log(data);
    });
  }
  continue() {
    $('#confirmEdit_Modal').modal('hide');
    let template = {
      name: this.templateData.name,
      description: this.templateData.description,
      edited_html: this.editContent,
    };
    this.agentService.saveEmailTemplates(this.templateId, template).subscribe((data: any) => {
      console.log(data);
      if (data.success == true) {
        this.showLiveBtn = true;
      }
    });
  }
}
