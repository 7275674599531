import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UtilityService } from '@app/@core/services/utility.service';

import { Logger } from '@core';
import { AuthenticationService } from './authentication.service';
import { CredentialsService } from './credentials.service';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    public authService: AuthenticationService,
    private utilityService: UtilityService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.islogged) {
      const currentUser = this.utilityService.getUser();
      if (currentUser) {
        // check if route is restricted by role
        if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
          // role not authorised so redirect to home page

          this.router.navigate(['/']);
          return false;
        } else {
          // if(route.data.permission && route.data.permission.indexOf(currentUser.role) === -1){
          //   this.router.navigate(['/']);
          // }
          return true;
        }

        // authorised so return true
      }
      // return true;
    }

    log.debug('Not authenticated, redirecting and adding redirect url...');
    this.router.navigate(['/'], { queryParams: { redirect: state.url }, replaceUrl: true });
    return false;
  }
}
