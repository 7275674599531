import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AgentService } from '@app/agent/services/agent.service';
import { ApiService } from '@app/general/services/api/api.service';
import { SharedCommonService } from '@app/@shared/services/shared-common.service';
import { getCityNamesfromJson } from '@app/@shared/utils/shared.utils';

@Component({
  selector: 'app-itinerary-view',
  templateUrl: './itinerary-view.component.html',
  styleUrls: ['./itinerary-view.component.scss', '../../../../theme/ngx-datatable.scss'],
})
export class ItineraryViewComponent implements OnInit {
  ONE_WAY: string = 'OneWay';
  OPEN_JAW: string = 'OpenJaw';
  MULTI_CITY: string = 'MultiCity';
  public bookingInformation: any;
  public flightsResultsResponse: any;
  viewResultData: any = null;
  itineraryId: any;
  deptCity: any;
  arrCity: any;
  tripType: any;
  userAgentDetails: any;
  todayDate = new Date();
  allTickets: any;
  viewInvoice: boolean = false;
  agentBookingReferenceId: any;
  ticketId = new Map<string, string>();
  allowDownloadingAgentInvoice: boolean = false;
  allowDownloadingCustomerInvoice: boolean = false;
  customerBookingReferenceId: any;
  activeTab: any = null;
  isLoading: boolean = false;
  airportsJsonData: any = null;
  constructor(
    private router: ActivatedRoute,
    private location: Location,
    private route: Router,
    private agentService: AgentService,
    private sharedCommonService: SharedCommonService,
    public apiService: ApiService
  ) {
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.getAirportsJsonList();
    this.router.queryParams.subscribe((x: any) => {
      if (x) {
        this.itineraryId = x.Id;
        this.deptCity = x.dept_city;
        this.arrCity = x.arr_city;
        this.tripType = x.triptype;
      }
      this.getInvoice(this.itineraryId);
    });
  }

  getInvoice(itineraryId: any) {
    this.isLoading = true;
    this.agentService.viewItinerary(itineraryId).subscribe(
      (res: any) => {
        this.isLoading = false;
        if (res.success) {
          this.viewResultData = res.data;
          /**
           *  this.userAgentDetails = res.agencyDetail;
           */
        }
      },
      (error: any) => {
        this.isLoading = false;
      }
    );
  }
  goToProfile() {
    if (this.activeTab) this.activeTab = null;
    else this.location.back();
  }

  downloadCustomerInvoiceHandler() {
    this.allowDownloadingCustomerInvoice = true;
    this.allowDownloadingAgentInvoice = false;
    this.customerBookingReferenceId = {
      Id: this.itineraryId,
    };
  }

  downloadAgentInvoiceHandler() {
    this.allowDownloadingAgentInvoice = true;
    this.agentBookingReferenceId = {
      Id: this.itineraryId,
    };
  }
  /**To get all Airports form S3 temporary solution only */
  getAirportsJsonList() {
    this.sharedCommonService.getAirportsJsonData().subscribe((data: any) => {
      this.airportsJsonData = data;
    });
  }
  public getAirports(param: string) {
    return this.airportsJsonData ? getCityNamesfromJson(param, this.airportsJsonData) : param;
  }
}
