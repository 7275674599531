<!-- <div [hidden]="!isLoading" class="text-xs-center">
  <i class="fas fa-cog fa-spin fa-3x"></i> <span>{{ message }}</span>
</div> -->
<button
  [disabled]="disabled"
  class="btn primary_CTA submitBtn"
  [style.pointer-events]="spin ? 'none' : ''"
  id="submitBtn"
>
  <i class="fa fa-spinner fa-spin" *ngIf="spin"> </i>
  <span class="d-none d-lg-inline text-uppercase">&nbsp; {{ spin ? 'wait...' : btnValue }} &nbsp;</span>
  <span class="d-lg-none text-capitalize">&nbsp; {{ spin ? 'wait...' : btnValue }} &nbsp;</span>
</button>
