import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthRoutingModule } from './auth-routing.module';
import { CustomMaterialModule } from './../custom-material/custom-material.module';
import { SignupComponent } from './signup/signup.component';
import { SharedModule } from './../@shared/shared.module';
import { SigninComponent } from './signin/signin.component';
import { RestService } from '@app/@core/services/rest.service';
import { AgentConfirmationComponent } from './agent-confirmation/agentconfirmation.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { TranslateModule } from '@ngx-translate/core';
import { OTPVerificationComponent } from './otp-verification/otp-verification.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule,
    AuthRoutingModule,
    CustomMaterialModule,
    Ng2TelInputModule,
    TranslateModule,
  ],
  declarations: [
    SignupComponent,
    SigninComponent,
    AgentConfirmationComponent,
    ForgotPasswordComponent,
    OTPVerificationComponent,
  ],
  exports: [SigninComponent, OTPVerificationComponent],
  providers: [RestService],
})
export class AuthModule {}
