import { ApiService } from './../general/services/api/api.service';
import { Inject, Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '../../assets/window.providers';

import { Logger } from '@core/logger.service';
import enGO from '../../translations/en-GO.json';
import enNG from '../../translations/en-NG.json';
import enZA from '../../translations/en-ZA.json';
import enFS from '../../translations/en-FS.json';
import enCT from '../../translations/en-CT.json';

import { HttpClient } from '@angular/common/http';
import { LOCO_API_LIVE_PATH, LOCO_TRANSLATIONS_PATH } from '@app/general/services/api/api-paths';
import { Title } from '@angular/platform-browser';
import { updateFavIcon } from '../general/utils/favIcon';
import { SharedCommonService } from '@app/@shared/services/shared-common.service';
import { CountryCodes } from '@app/general/utils/country-code';

const log = new Logger('I18nService');
const languageKey = 'language';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  defaultLanguage!: string;
  supportedLanguages!: string[];
  lang: any;
  country: any;
  userCountry: any;
  userCountryCode: any;
  currencyVal: any = 'NGN';
  applicationval: any;
  countryDialCode: any;
  selectedCountry: any;
  linksData: any;
  countryDomain: any = null;
  private langChangeSubscription!: Subscription;

  constructor(
    private translateService: TranslateService,
    @Inject(DOCUMENT) private doc: Document,
    @Inject(WINDOW) private window: Window,
    private sharedService: SharedCommonService,
    private title: Title,
    private apiService: ApiService,
    private httpClient: HttpClient
  ) {
    // Embed languages to avoid extra HTTP requests
    this.countryDomain = 'en-' + this.apiService.extractWhiteLabelDomain().TS_ORG.split('_')[1];
    translateService.setTranslation('en-GO', enGO);
    translateService.setTranslation('en-NG', enNG);
    translateService.setTranslation('en-ZA', enZA);
    translateService.setTranslation('en-FS', enFS);
    translateService.setTranslation('en-CT', enCT);
    updateFavIcon(this.apiService.extractWhiteLabelDomain().TS_ORG);
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  init(defaultLanguage: string, supportedLanguages: string[]) {
    this.updateCanonicalUrl();
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages;
    this.language = this.countryDomain;
    // Warning: this subscription will always be alive for the app's lifetime
    this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      // localStorage.setItem(languageKey, event.lang);
    });

    this.getB2cLinks().subscribe((data) => {
      sessionStorage.removeItem('b2c_linksData');
      sessionStorage.setItem('b2c_linksData', JSON.stringify(data));
    });
    this.sharedService.getUserCountry().subscribe((data: any) => {
      for (let x in CountryCodes) {
        if (CountryCodes[x].code === data.country) {
          this.countryDialCode = CountryCodes[x].dial_code;
        }
      }
      this.userCountry = 'en-' + data.country;

      this.language = this.userCountry;
      this.selectedCountry = 'en-' + data.country;
      // this.setURLPath(this.selectedCountry);
    });
  }

  /**
   * Cleans up language change subscription.
   */
  destroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  /**
   * Sets the title related to the domain
   */
  setTitle(countryName: any) {
    if (countryName) {
      let appTitle = this.getCountryTranslations(countryName).APP_TITLE;
      this.title.setTitle(appTitle);
    }
  }

  /**
   * It adds the canonical tag related to the domain and removes the other link tag specific to the domain

   */
  updateCanonicalUrl() {
    const hostname = this.window.location.origin;
    const hostLink = hostname + '/';
    let links = this.doc.getElementsByTagName('link');
    for (let x in links) {
      if (links[x] && links[x].rel == 'alternate' && links[x].href == hostLink) {
        links[x].remove();
      }
    }
    links[1].setAttribute('href', hostname);
  }

  getCountryTranslations(countryValue: any) {
    switch (countryValue) {
      case 'en-GO': {
        return enGO;
      }

      case 'en-NG': {
        return enNG;
      }
      case 'en-ZA': {
        return enZA;
      }
      case 'en-FS': {
        return enFS;
      }
      case 'en-CT': {
        return enCT;
      }
      default: {
        return enNG;
      }
    }
  }
  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param language The IETF language code to set.
   */
  set language(language: string) {
    language = this.countryDomain || language;
    if (this.supportedLanguages && !this.supportedLanguages.includes(language)) {
      language = 'en-GO';
    }
    // B2C: TO-DO if we need to enable redirection based on language
    // this.setURLPath(language);
    this.selectedCountry = language;
    let isSupportedLanguage = this.supportedLanguages.includes(language);
    this.country = language.split('-')[1];
    this.lang = language.split('-')[0];
    this.updateCanonicalUrl();
    if (language) {
      this.setTitle(language);
    }

    this.getB2cLinks().subscribe((data) => {
      if (data) {
        this.linksData = data;
        if (sessionStorage.getItem('b2c_linksData')) {
          sessionStorage.removeItem('b2c_linksData');
          sessionStorage.setItem('b2c_linksData', JSON.stringify(data));
        }
      }
    });
    // If no exact match is found, search without the region
    if (language && !isSupportedLanguage) {
      language = language.split('-')[0];
      language = this.supportedLanguages.find((supportedLanguage) => supportedLanguage.startsWith(language)) || '';
      isSupportedLanguage = Boolean(language);
    }

    // Fallback if language is not supported
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }
    log.debug(`Language set to ${language}`);
    this.translateService.use(language);
  }
  getB2cLinks() {
    return this.httpClient.get(
      `${LOCO_API_LIVE_PATH}${LOCO_TRANSLATIONS_PATH}?country=${this.country}&language=${this.lang}&`
    );
  }
  /**
   * Gets the current language.
   * @return The current language code.
   */
  get language(): string {
    return this.countryDomain;
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   currency The IETF language code to set.
   */
  set currencyCode(currency: string) {
    if (sessionStorage.getItem('currencycode')) {
      sessionStorage.removeItem('currencycode');
      sessionStorage.setItem('currencycode', currency);
    }
  }

  // B2C: TO-DO if we need to enable redirection based on language
  // setURLPath(country: any) { }
  /**update favicon based on domain */
}
