import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from '@app/@core/services/rest.service';
import { UtilityService } from '@app/@core/services/utility.service';
import { get } from 'lodash';
import { AuthenticationService } from '../authentication.service';
import { homepagePath } from '../routing_path';
import { ResponsiveService } from '@app/@core/services/responsive.service';
import { ApiService } from '@app/general/services/api/api.service';
import { SharedCommonService } from '../../@shared/services/shared-common.service';
import { numInputNoChars } from '@app/general/utils/regex-utils';
const ZA = 'ng';
const IDENTIFIER = 'identifier';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  signUpForm: FormGroup;
  formSubmitAttempt: boolean = false;
  products: any = [];
  hidTicICon: boolean;
  inputVal: string;
  selFormTypeRadio: string;
  formType: any;
  isLoginTab: boolean;
  isCompany: boolean = true;
  countryList: any = [];
  cityList: any = [];
  tabIndex: any;
  isMessage: boolean = false;
  successMsg: string;
  loader: boolean = false;
  isFilled: boolean = false;
  focused = false;
  isSuperAdmin: boolean;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  stateChanges: any;
  isAgentVerified: boolean;
  tab: any;
  selected: any;
  shouldShowPassword = false;
  shouldShowConfirmPassword = false;
  isSigningUp: boolean = false;
  countryCode: any;
  countryName: any;
  local_gov_areas: any = [];
  isLoginView: boolean = true;
  submitLogin: boolean = false;
  submitSignup: boolean = false;
  showEyeIcon: boolean = true;
  userCountry: any = 'Nigeria';
  show_OTP_sec: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    public utilityService: UtilityService,
    public restService: RestService,
    public route: ActivatedRoute,
    public router: Router,
    public authService: AuthenticationService,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private _snackBar: MatSnackBar,
    public responsiveService: ResponsiveService,
    private sharedService: SharedCommonService,
    public apiService: ApiService
  ) {
    if (location.pathname == '/superadmin') {
      this.isSuperAdmin = true;
    }

    if (location.pathname == '/confirmation') {
      let params = this.route.snapshot.queryParams;
      this.confirmAgent(params);
    }
    this.authService.hideHeaderBtns();
  }

  get f() {
    return this.signUpForm.controls;
  }

  ngOnInit(): void {
    this.scrollToTop();
    this.route.queryParams.subscribe((x: any) => {
      if (x.tab && x.tab == 'register') {
        this.isLoginView = false;
      } else {
        this.isLoginView = true;
      }
    });
    this.loadInitials();
    sessionStorage.removeItem('OTP');
  }

  getLocalGovAreas(param: string) {
    this.restService.getLocalGovAreas(param).subscribe((res: any) => {
      this.local_gov_areas = res.data;
    });
  }

  resetFieldValue(fieldName: string) {
    this.signUpForm.addControl(fieldName, new FormControl(''));
    this.signUpForm.controls[fieldName].setValue(null);
  }

  signUp() {
    this.formSubmitAttempt = true;
    this.submitSignup = true;
    if (this.signUpForm.valid && !this.hasDifferentConfirmEmail()) {
      this.isSigningUp = true;
      this.addMandatoryFields();
      if (this.signUpForm.get('phone_number').value == '') this.resetFieldValue('phone_number');
      if (this.signUpForm.get('website').value == '') this.resetFieldValue('website');
      this.signUpForm.get('product').setValue([this.signUpForm.value.product]);
      let signupData = this.signUpForm.value;
      let selectedCountry = 'Nigeria';
      this.countryList?.map((x: any) => {
        if (x.country_code == this.signUpForm.get('country').value) {
          selectedCountry = x.country_name;
        }
      });
      signupData['country'] = selectedCountry;
      delete signupData['ngCountry'];
      this.restService.agentSignUp(this.signUpForm.value).subscribe(
        (res: any) => {
          this.signUpForm.get('product').setValue(1);
          if (res) {
            this.isSigningUp = false;
            this.isMessage = true;
            this.scrollToTop();
            this.successMsg =
              'Thank you for registering. Confirmation link has been sent to your registered mail: ' +
              this.signUpForm.get('email').value;
            this.formSubmitAttempt = false;
            setTimeout(() => this.signUpForm.reset(), 0);
          }
        },
        (errors) => {
          this.signUpForm.get('product').setValue(1);
          this.isSigningUp = false;
          if (errors.error) {
            let config = new MatSnackBarConfig();
            config.panelClass = ['error-msg-snack'];
            const errerObject = errors.error;
            let errorMessage = '';
            for (const key in errerObject) {
              if (key !== IDENTIFIER) {
                errorMessage += errerObject[key];
                errorMessage += '\n';
              }
            }
            this._snackBar.open(`${errorMessage}`, 'X', config);
          }
        }
      );
    }
  }

  login() {
    this.submitLogin = true;
    this._snackBar.dismiss();
    if (this.loginForm.valid) {
      this.loader = true;
      this.submitLogin = false;
      this.restService.getTokens(this.loginForm.value).subscribe(
        (res: any) => {
          if (res) {
            this.loader = false;
            let response = res;
            response['email'] = this.loginForm.value?.username;
            this.utilityService.setAuthToken(response);
            if (res.mfa) {
              this.router.navigate(['/OTP-verification']);
            } else {
              this.agentLogin();
            }
          } else this.loader = false;
        },
        (errors) => {
          if (errors.error) {
            this._snackBar.open(
              `${get(errors, 'error.message', 'Unable to login right now, Please try again later.')}`,
              'X'
            );
            this.loader = false;
          }
        }
      );
    } else {
      return;
    }
  }

  agentLogin() {
    this.restService.login().subscribe((res: any) => {
      if (res) {
        setTimeout(() => this.formGroupDirective.resetForm(), 0);
        this.authService.setIsloggedIn();
        this.utilityService.setUser(res.data);
        this.router.navigate([this.route.snapshot.queryParams.redirect || homepagePath(res.data)], {
          replaceUrl: true,
        });
      }
    });
  }

  agentTempActivate(username: any) {
    let tempObj = {
      email: username.value,
    };
    this.restService.activateAgent(tempObj).subscribe((data: any) => {
      console.log(data);
    });
  }

  tabClick() {
    this.isLoginTab = !this.isLoginTab;
  }

  changeFormType(type: any) {
    console.log(type);
    setTimeout(() => this.formGroupDirective.resetForm(), 0);
    if (type === 'Company') {
      this.isCompany = true;
      this.signUpForm.controls['agency_name'].setValidators([Validators.required]);
    } else {
      this.signUpForm.get('agency_name').clearValidators();
      this.isCompany = false;
    }
  }

  telInputObject(value: any) {
    this.countryName = value;
    this.sharedService.getUserCountry().subscribe((country: any) => {
      country ? value.setCountry(country.country) : value.setCountry(ZA);
    });
  }

  onCountryChange(q: any) {
    this.signUpForm.addControl('country_code', new FormControl(''));
    this.signUpForm.controls['country_code'].setValue(q.dialCode);
    this.countryCode = Object.values(q)[2];
  }

  getCountries() {
    /**if user want select countries from list we can enable it not NG except NG all can get list */
    if (this.apiService.extractWhiteLabelDomain().ts_Country == 'NG') {
      this.signUpForm?.get('country')?.setValue('NG');
      this.signUpForm?.get('ngCountry')?.setValue('Nigeria');
      this.selectCountry('NG');
    } else {
      this.restService.getCountryList().subscribe(
        (res: any) => {
          if (res) {
            // temp = res.data.map((a: { country_code: any }) => a.country_code.toLowerCase());
            this.countryList = res.data;
            this.countryList = this.countryList.sort((a: any, b: any) => a.country_name.localeCompare(b.country_name));
            this.countryList.map((x: any) => {
              if (x.country_code == this.apiService.extractWhiteLabelDomain().ts_Country) {
                this.userCountry = x.country_name;
              }
            });
            this.signUpForm?.get('country')?.setValue(this.apiService.extractWhiteLabelDomain().ts_Country);
            this.selectCountry(this.apiService.extractWhiteLabelDomain().ts_Country);
          } else {
            this.signUpForm?.get('country')?.setValue('NG');
            this.selectCountry('NG');
          }
        },
        (error: any) => {
          this.signUpForm?.get('country')?.setValue('NG');
          this.selectCountry('NG');
        }
      );
    }
  }
  getCities(country: any) {
    this.signUpForm.get('city').setValue(null);
    this.signUpForm.get('local_gov_area').setValue(null);
    this.restService.getCityList(country).subscribe((data: any) => {
      if (data.success == true) {
        this.cityList = data.data;
      } else {
        this.cityList = [];
      }
    });
  }
  confirmAgent(params: any) {
    params = this.route.snapshot.queryParams;
    this.restService.confirmAgent(params).subscribe(
      (res: any) => {
        if (res) {
          this.isMessage = true;
          this.successMsg = 'Verified Successfully';
        }
      },
      (errors) => {
        if (errors.error) {
          this._snackBar.open(`${get(errors, 'error.message', 'This link has been expired.')}`, 'X');
          setTimeout(() => {
            this._snackBar.dismiss();
          }, 2000);
        }
      }
    );
  }
  isFieldInvalid(field: string, form: string) {
    if (form == 'login') {
      return (
        (!this.loginForm.get(field).valid && this.loginForm.get(field).touched) ||
        (this.loginForm.get(field).untouched && this.formSubmitAttempt)
      );
    } else {
      return (
        (!this.signUpForm.get(field).valid && this.signUpForm.get(field).touched) ||
        (this.signUpForm.get(field).untouched && this.formSubmitAttempt)
      );
    }
  }

  loadInitials() {
    this.hidTicICon = false;
    this.formType = [
      // { id: 1, label: 'Individual', checked: false },
      { id: 2, label: 'Company', checked: true },
    ];
    this.products = [{ id: 1, name: 'Flights' }];
    this.userSignUpForm();
    this.userLoginForm();
    this.getCountries();
  }
  addMandatoryFields() {
    this.signUpForm.addControl('role', new FormControl(''));
    this.signUpForm.controls['role'].setValue(3);
    this.signUpForm.addControl('parent', new FormControl(''));
    this.signUpForm.controls['parent'].setValue(null);
    this.signUpForm.addControl('is_company', new FormControl(''));
    this.signUpForm.controls['is_company'].setValue(this.isCompany);
    // this.signUpForm.controls['product'].setValue(this.products[0].id);
  }
  userLoginForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  userSignUpForm() {
    this.signUpForm = this.formBuilder.group({
      product: [this.products[0].id, Validators.required],
      agency_name: [null],
      person_name: [null, [Validators.required, Validators.minLength(3)]],
      phone_number: [null],
      mobile_number: [null, Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$'),
        ],
      ],
      confirm_email: [
        null,
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$'),
        ],
      ],
      password: [null, [Validators.required]],
      confirmpassword: [null, [Validators.required]],
      local_gov_area: ['', Validators.required],
      address: ['', Validators.required],
      city: [null, [Validators.required, Validators.minLength(3)]],
      country: ['', Validators.required],
      ngCountry: [''],
      website: [null],
      acceptTerms: [false, Validators.requiredTrue],
    });
  }

  hasDifferentConfirmEmail() {
    const email = this.signUpForm.get('email').value;
    const confirmEmail = this.signUpForm.get('confirm_email').value;
    return Boolean(confirmEmail && email.toLowerCase() !== confirmEmail.toLowerCase());
  }
  hasDifferentConfirmPassword() {
    const password = this.signUpForm.get('password').value;
    const confirmpassword = this.signUpForm.get('confirmpassword').value;
    return Boolean(confirmpassword && password.toLowerCase() !== confirmpassword.toLowerCase());
  }

  selectCountry(country: any, event?: any) {
    if (country) this.getCities(country);
  }
  tabVal(data: any) {
    this.tab = data;
    this.selected = data;
    this.isLoginTab = data === 'login';
  }
  isActive(item: any) {
    return this.selected === item;
  }
  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
  // allows users to type only numbers
  onlyNumbersKeysAllowed(event: any) {
    return numInputNoChars(event);
  }
  ngOnDestroy() {
    this._snackBar.dismiss();
  }
  showPwd() {
    this.showEyeIcon = !this.showEyeIcon;
  }
  switchToSignIn() {
    this.isLoginView = false;
  }
  switchToLogIn() {
    this.isLoginView = true;
    this.show_OTP_sec = false;
  }
  /**To download kyc form for flightsite as of now */
  downloadKYC_Form() {}
}
