import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WEBAPI_PATH, GATEWAY_AUTH_BOOK_FLIGHTS_PATH } from './../../general/services/api/api-paths';
import { BehaviorSubject } from 'rxjs';
import {
  ADDMONEY_OFFINE_MODE,
  AGENT_WALLET_TRANSACTIONS,
  OFFLINEBANKS_LIST,
  PROCESS_AMOUNT_TO_WALLET,
  CREDIT_AMOUNT_TO_WALLET,
} from 'src/constants/constants';
import { ApiService } from '@app/general/services/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  public processingfee = new BehaviorSubject(0);
  currentProcessingfee = this.processingfee.asObservable();
  private BASE_URL: any;

  changeProcessingFee(value: number) {
    this.processingfee.next(value);
  }

  constructor(private httpClient: HttpClient, private apiService: ApiService) {
    this.BASE_URL = this.apiService.apiBaseUrl();
  }

  public bookFlight(data: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    /**we can enable if we want book flight from b2b code
     * const url = `${this.apiService.fetchApiHostUrl()}${WEB_API_PATH}${BOOK_FLIGHTS_PATH}/?language=en&correlation_id=${this.sessionUtils.getCorrelationId()}`;
     * return this.httpClient.post('', data);
     */
    return this.httpClient.post('', data, { headers: headers });
  }
  getOfflinebankList() {
    return this.httpClient.get(this.BASE_URL + OFFLINEBANKS_LIST);
  }
  addOfflineMoney(data: any) {
    return this.httpClient.post(this.BASE_URL + ADDMONEY_OFFINE_MODE, data);
  }
  getWalletTransactions() {
    return this.httpClient.get(this.BASE_URL + AGENT_WALLET_TRANSACTIONS);
  }
  loadPaymentIframeView(url: string, data: any, httpMethod: any) {
    let headers = new HttpHeaders({
      Accept: 'text/html',
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    if (httpMethod && httpMethod == 'GET') {
      return this.httpClient.get(url, { headers: headers, responseType: 'text' });
    } else {
      return this.httpClient.post(url, data, { headers: headers, responseType: 'text' });
    }
  }
  GatewayPaymentBookFlight(data: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    const url = `${this.apiService.fetchApiHostUrl()}${WEBAPI_PATH}${GATEWAY_AUTH_BOOK_FLIGHTS_PATH}`;
    return this.httpClient.post(url, data, { headers: headers });
  }

  public bookFlightViaWallet(data: any) {
    /**we can enable if we want book flight from b2b code
     * const url = `${this.BASE_URL}${FLIGHT_BOOK}?${this.queryString.getParamApiPath()}`;
     * return this.httpClient.post('', data);
     */
    return this.httpClient.post('', data);
  }

  updateWalletAmount(data: any) {
    const url = `${this.BASE_URL}${PROCESS_AMOUNT_TO_WALLET}`;
    return this.httpClient.post(url, data);
  }

  updateCreditWallet(data: any) {
    const url = `${this.BASE_URL}${CREDIT_AMOUNT_TO_WALLET}`;
    return this.httpClient.post(url, data);
  }
}
