import { v4 as uuidv4 } from 'uuid';
import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';

@Injectable()
export class SessionUtils {
  public static readonly CORRELATION_ID: string = 'correlation_id';

  constructor(
    private sessionStorageService: SessionStorageService //private apiUtils: ApiUtils,
  ) {}

  public getCorrelationId(): string {
    let correlationId;
    if (!sessionStorage.getItem('correlationId')) {
      correlationId =
        // 1st priority would be to use the query parameter
        // this.apiUtils.getQueryParameterValue(SessionUtils.CORRELATION_ID) ||
        // 2nd priority would be to use the one is angular session storage
        this.sessionStorageService.retrieve(SessionUtils.CORRELATION_ID) ||
        // 3rd priority would be to generate one
        this.createCorrelationId();
      this.sessionStorageService.store(SessionUtils.CORRELATION_ID, correlationId);
    } else if (sessionStorage.getItem('deepLinkRequest') && sessionStorage.getItem('correlationId')) {
      correlationId = sessionStorage.getItem('correlationId');
    }

    return correlationId;
  }

  private createCorrelationId(): string {
    return uuidv4();
  }
}
