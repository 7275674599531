import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AgentService } from '@app/agent/services/agent.service';
import { UtilityService } from '@app/@core/services/utility.service';
import { Location } from '@angular/common';
import { isSuperAdmin, isAgent, PERMISSIONS } from '@app/@shared/role.constants';
import { SuperAdminService } from '@app/super-admin/services/super-admin.service';

@Component({
  selector: 'app-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.scss', '../../../../theme/ngx-datatable.scss'],
})
export class AuditLogsComponent implements OnInit {
  fromDate = new FormControl('');
  toDate = new FormControl('');
  inputSearchBox = new FormControl('');
  filterType = new FormControl('');
  rows: any = [];
  user: any;
  isSuperAdmin: boolean;
  isAgent: boolean;
  queryParams: any = {};
  isLoading: boolean = true;
  activePage: number = 1;
  errMessage: string = 'No Audit History';
  pages = {
    limit: 10,
    totalCount: 0,
    offset: 0,
  };

  constructor(
    private agentService: AgentService,
    private utilityService: UtilityService,
    private location: Location,
    private superAdminService: SuperAdminService
  ) {}

  ngOnInit(): void {
    this.user = this.utilityService.getUser();
    this.isSuperAdmin = isSuperAdmin(this.user.role);
    this.isAgent = isAgent(this.user.role);
    this.getAuditLogsDetails(this.queryParams);
  }

  mapResponse = (res: any) => {
    this.rows = res.success ? res.data : [];
    this.pages.totalCount = res.total_count;
    this.isLoading = false;
  };

  handleError = () => (this.isLoading = false);

  getAuditLogsDetails(queryParams: any) {
    if (this.hasPermissionToViewAuditLogSubAgent()) {
      this.agentService.getAgentAuditLogs(queryParams).subscribe(this.mapResponse, this.handleError);
    }
    if (this.isSuperAdmin || this.hasPermissionToViewAuditLogTSEmp()) {
      this.superAdminService.getAdminAuditLogs(queryParams).subscribe(this.mapResponse, this.handleError);
    }
  }

  handlePagination(params: any) {
    this.activePage = params;
    this.getAuditLogsDetails({ ...this.queryParams, page: params });
  }

  getUserInitials(name: any) {
    return this.utilityService.getInitials(name);
  }

  handleSearch(searchKeywords: any) {
    if (this.filterType.value == 'agent') {
      this.queryParams = { ...this.queryParams, agent: searchKeywords };
    } else if (this.filterType.value == 'agency_id') {
      this.queryParams = { ...this.queryParams, agency_id: searchKeywords };
    } else if (this.filterType.value == 'module') {
      this.queryParams = { ...this.queryParams, module: searchKeywords };
    }

    this.getAuditLogsDetails(this.queryParams);
  }

  handleDateRangeSelection(params: any) {
    this.queryParams = { ...this.queryParams, ...params };
    this.getAuditLogsDetails(this.queryParams);
  }

  clearSearchBox() {
    this.inputSearchBox.setValue('');
    this.queryParams = {};
    this.getAuditLogsDetails(this.queryParams);
  }

  backToDashboard() {
    this.location.back();
  }
  hasPermissionToViewAuditLogTSEmp() {
    return this.utilityService.hasPermission(PERMISSIONS.logAdminTSE);
  }
  hasPermissionToViewAuditLogSubAgent() {
    return this.utilityService.hasPermission(PERMISSIONS.logAdmin);
  }
}
