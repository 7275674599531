// tslint:disable:object-literal-sort-keys
export const LOCALE_DOMAINS = [
  {
    id: 'AC',
    domain: 'africa.travelstart.com',
  },
  {
    id: 'AE',
    domain: 'www.travelstart.ae',
  },
  {
    id: 'BH',
    domain: 'www.travelstart.com.bh',
  },
  {
    id: 'BW',
    domain: 'www.travelstart.co.bw',
  },
  {
    id: 'CT',
    domain: 'club.travelstart.co.za',
  },
  {
    id: 'DZ',
    domain: 'www.travelstart.dz',
  },
  {
    id: 'EG',
    domain: 'www.travelstart.com.eg',
  },
  {
    id: 'GH',
    domain: 'www.travelstart.gh',
  },
  {
    id: 'GO',
    domain: 'www.travelstart.com',
  },
  {
    id: 'GO',
    domain: 'www2.travelstart.com',
  },
  {
    id: 'JO',
    domain: 'www.travelstart.jo',
  },
  {
    id: 'KE',
    domain: 'www.travelstart.co.ke',
  },
  {
    id: 'KW',
    domain: 'www.travelstart.com.kw',
  },
  {
    id: 'KW',
    domain: 'kw.travelstart.com',
  },
  {
    id: 'LY',
    domain: 'www.travelstart.ly',
  },
  {
    id: 'MA',
    domain: 'www.travelstart.ma',
  },
  {
    id: 'ME',
    domain: 'www.travelstart.me',
  },
  {
    id: 'MW',
    domain: 'www.travelstart.mw',
  },
  {
    id: 'NA',
    domain: 'www.travelstart.com.na',
  },
  {
    id: 'NG',
    domain: 'www.travelstart.com.ng',
  },
  {
    id: 'OM',
    domain: 'www.travelstart.com.om',
  },
  {
    id: 'PK',
    domain: 'www.travelstart.pk',
  },
  {
    id: 'QA',
    domain: 'www.travelstart.qa',
  },
  {
    id: 'SA',
    domain: 'www.travelstart.com.sa',
  },
  {
    id: 'TB',
    domain: 'travelbar.travelstart.co.za',
  },
  {
    id: 'TB',
    domain: 'travelbar2.travelstart.co.za',
  },
  {
    id: 'TN',
    domain: 'www.travelstart.tn',
  },
  {
    id: 'TR',
    domain: 'www.geziko.com',
  },
  {
    id: 'TT',
    domain: 'www.travelstart.tt',
  },
  {
    id: 'TZ',
    domain: 'www.travelstart.co.tz',
  },
  {
    id: 'ZA',
    domain: 'www.travelstart.co.za',
  },
  {
    id: 'ZA',
    domain: 'www2.travelstart.co.za',
  },
  {
    id: 'GZ',
    translationLocale: 'en-GZ',
    domain: 'za.geziko.com',
  },
  {
    id: 'GZ',
    translationLocale: 'en-GZ',
    domain: 'geziko.co.za',
  },
  {
    id: 'GZ',
    translationLocale: 'en-GZ',
    domain: 'www.geziko.co.za',
  },
  {
    id: 'ZW',
    domain: 'www.travelstart.co.zw',
  },
  {
    id: 'GO',
    domain: 'go.travelstart.com',
  },
  {
    id: 'GO',
    domain: 'go2.travelstart.com',
  },
  {
    id: 'AC',
    domain: 'betaac.travelstart.com',
  },
  {
    id: 'AE',
    domain: 'betaae.travelstart.com',
  },
  {
    id: 'BH',
    domain: 'betabh.travelstart.com',
  },
  {
    id: 'BW',
    domain: 'betabw.travelstart.com',
  },
  {
    id: 'CT',
    domain: 'betact.travelstart.com',
  },
  {
    id: 'DZ',
    domain: 'betadz.travelstart.com',
  },
  {
    id: 'EG',
    domain: 'betaeg.travelstart.com',
  },
  {
    id: 'GH',
    domain: 'betagh.travelstart.com',
  },
  {
    id: 'GO',
    domain: 'betago.travelstart.com',
  },
  {
    id: 'JO',
    domain: 'betajo.travelstart.com',
  },
  {
    id: 'KE',
    domain: 'betake.travelstart.com',
  },
  {
    id: 'KW',
    domain: 'betakw.travelstart.com',
  },
  {
    id: 'LY',
    domain: 'betaly.travelstart.com',
  },
  {
    id: 'MA',
    domain: 'betama.travelstart.com',
  },
  {
    id: 'ME',
    domain: 'betame.travelstart.com',
  },
  {
    id: 'MW',
    domain: 'betamw.travelstart.com',
  },
  {
    id: 'NA',
    domain: 'betana.travelstart.com',
  },
  {
    id: 'NG',
    domain: 'betang.travelstart.com',
  },
  {
    id: 'OM',
    domain: 'betaom.travelstart.com',
  },
  {
    id: 'PK',
    domain: 'betapk.travelstart.com',
  },
  {
    id: 'QA',
    domain: 'betaqa.travelstart.com',
  },
  {
    id: 'SA',
    domain: 'betasa.travelstart.com',
  },
  {
    id: 'TB',
    domain: 'betatb.travelstart.com',
  },
  {
    id: 'FS',
    domain: 'betafts.travelstart.com',
  },
  {
    id: 'CT',
    domain: 'betact.travelstart.com',
  },
  {
    id: 'TN',
    domain: 'betatn.travelstart.com',
  },
  {
    id: 'TR',
    domain: 'beta.geziko.com',
  },
  {
    id: 'TT',
    domain: 'betatt.travelstart.com',
  },
  {
    id: 'TZ',
    domain: 'betatz.travelstart.com',
  },
  {
    id: 'ZA',
    domain: 'betaza.travelstart.com',
  },
  {
    id: 'GZ',
    translationLocale: 'en-GZ',
    domain: 'betaza.geziko.com',
  },
  {
    id: 'GZ',
    translationLocale: 'en-GZ',
    domain: 'beta.geziko.co.za',
  },
  {
    id: 'ZW',
    domain: 'betazw.travelstart.com',
  },
  {
    id: 'AC',
    domain: 'alphaac.travelstart.com',
  },
  {
    id: 'AE',
    domain: 'alphaae.travelstart.com',
  },
  {
    id: 'BH',
    domain: 'alphabh.travelstart.com',
  },
  {
    id: 'BW',
    domain: 'alphabw.travelstart.com',
  },
  {
    id: 'CT',
    domain: 'alphact.travelstart.com',
  },
  {
    id: 'DZ',
    domain: 'alphadz.travelstart.com',
  },
  {
    id: 'EG',
    domain: 'alphaeg.travelstart.com',
  },
  {
    id: 'GH',
    domain: 'alphagh.travelstart.com',
  },
  {
    id: 'GO',
    domain: 'alphago.travelstart.com',
  },
  {
    id: 'JO',
    domain: 'alphajo.travelstart.com',
  },
  {
    id: 'KE',
    domain: 'alphake.travelstart.com',
  },
  {
    id: 'KW',
    domain: 'alphakw.travelstart.com',
  },
  {
    id: 'LY',
    domain: 'alphaly.travelstart.com',
  },
  {
    id: 'MA',
    domain: 'alphama.travelstart.com',
  },
  {
    id: 'ME',
    domain: 'alphame.travelstart.com',
  },
  {
    id: 'MW',
    domain: 'alphamw.travelstart.com',
  },
  {
    id: 'NA',
    domain: 'alphana.travelstart.com',
  },
  {
    id: 'NG',
    domain: 'alphang.travelstart.com',
  },
  {
    id: 'OM',
    domain: 'alphaom.travelstart.com',
  },
  {
    id: 'PK',
    domain: 'alphapk.travelstart.com',
  },
  {
    id: 'QA',
    domain: 'alphaqa.travelstart.com',
  },
  {
    id: 'SA',
    domain: 'alphasa.travelstart.com',
  },
  {
    id: 'TB',
    domain: 'alphatb.travelstart.com',
  },
  {
    id: 'TN',
    domain: 'alphatn.travelstart.com',
  },
  {
    id: 'TR',
    domain: 'alpha.geziko.com',
  },
  {
    id: 'TT',
    domain: 'alphatt.travelstart.com',
  },
  {
    id: 'TZ',
    domain: 'alphatz.travelstart.com',
  },
  {
    id: 'ZA',
    domain: 'alphaza.travelstart.com',
  },
  {
    id: 'GZ',
    translationLocale: 'en-GZ',
    domain: 'alphaza.geziko.com',
  },
  {
    id: 'GZ',
    translationLocale: 'en-GZ',
    domain: 'alpha.geziko.co.za',
  },
  {
    id: 'ZW',
    domain: 'alphazw.travelstart.com',
  },
  {
    id: 'AC',
    domain: 'preprodac.travelstart.com',
  },
  {
    id: 'AE',
    domain: 'preprodae.travelstart.com',
  },
  {
    id: 'BH',
    domain: 'preprodbh.travelstart.com',
  },
  {
    id: 'BW',
    domain: 'preprodbw.travelstart.com',
  },
  {
    id: 'CT',
    domain: 'preprodct.travelstart.com',
  },
  {
    id: 'DZ',
    domain: 'preproddz.travelstart.com',
  },
  {
    id: 'EG',
    domain: 'preprodeg.travelstart.com',
  },
  {
    id: 'GH',
    domain: 'preprodgh.travelstart.com',
  },
  {
    id: 'GO',
    domain: 'preprodgo.travelstart.com',
  },
  {
    id: 'JO',
    domain: 'preprodjo.travelstart.com',
  },
  {
    id: 'KE',
    domain: 'preprodke.travelstart.com',
  },
  {
    id: 'KW',
    domain: 'preprodkw.travelstart.com',
  },
  {
    id: 'LY',
    domain: 'preprodly.travelstart.com',
  },
  {
    id: 'MA',
    domain: 'preprodma.travelstart.com',
  },
  {
    id: 'ME',
    domain: 'preprodme.travelstart.com',
  },
  {
    id: 'MW',
    domain: 'preprodmw.travelstart.com',
  },
  {
    id: 'NA',
    domain: 'preprodna.travelstart.com',
  },
  {
    id: 'NG',
    domain: 'preprodng.travelstart.com',
  },
  {
    id: 'OM',
    domain: 'preprodom.travelstart.com',
  },
  {
    id: 'PK',
    domain: 'preprodpk.travelstart.com',
  },
  {
    id: 'QA',
    domain: 'preprodqa.travelstart.com',
  },
  {
    id: 'SA',
    domain: 'preprodsa.travelstart.com',
  },
  {
    id: 'TB',
    domain: 'preprodtb.travelstart.com',
  },
  {
    id: 'TN',
    domain: 'preprodtn.travelstart.com',
  },
  {
    id: 'TR',
    domain: 'preprod.geziko.com',
  },
  {
    id: 'TT',
    domain: 'preprodtt.travelstart.com',
  },
  {
    id: 'TZ',
    domain: 'preprodtz.travelstart.com',
  },
  {
    id: 'ZA',
    domain: 'preprodza.travelstart.com',
  },
  {
    id: 'GZ',
    translationLocale: 'en-GZ',
    domain: 'preprodza.geziko.com',
  },
  {
    id: 'GZ',
    translationLocale: 'en-GZ',
    domain: 'preprod.geziko.co.za',
  },
  {
    id: 'ZW',
    domain: 'preprodzw.travelstart.com',
  },
  {
    id: 'ZA',
    domain: 'localhost',
  },
];
