import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from '@app/@core/services/utility.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Credentials, CredentialsService } from './credentials.service';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private credentialsService: CredentialsService,
    private http: HttpClient,
    private router: Router,
    public utilityService: UtilityService
  ) {}

  private loggedIn = new BehaviorSubject<boolean>(false);
  public showHeadActions = new BehaviorSubject<boolean>(false);

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get isHeaderActions() {
    return this.showHeadActions.asObservable();
  }

  showHeaderBtns() {
    this.showHeadActions.next(true);
  }
  hideHeaderBtns() {
    this.showHeadActions.next(false);
  }

  setIsloggedIn() {
    this.loggedIn.next(true);
  }

  logout() {
    this.utilityService.clearAuthToken();
    this.loggedIn.next(false);
    this.router.navigate(['/']);
  }
  get islogged() {
    return this.utilityService.getAuthToken();
  }
  // logout(): Observable<boolean> {
  //   this.credentialsService.setCredentials();
  //   return of(true);
  // }
}
