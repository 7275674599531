import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ApiService } from '@app/general/services/api/api.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackbarService } from '../services/snackbar.service';
import { UtilityService } from '../services/utility.service';
import { DOMAINS } from '@app/general/services/domains';

const OBJECT = 'object';
const IDENTIFIER = 'identifier';
const STRING = 'string';
const SUCCESS = 'success';

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  private utilityService: UtilityService;
  private snackbar: SnackbarService;
  tsCountry: any;
  currency: any;
  private TS_ORG: any;
  constructor(private injector: Injector, private apiService: ApiService) {
    DOMAINS.forEach((host: any) => {
      if (window.location.hostname === host.hostname) {
        this.TS_ORG = host.TS_ORG;
      }
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.utilityService = this.injector.get(UtilityService);
    this.snackbar = this.injector.get(SnackbarService);
    const authToken = this.utilityService.getAuthToken();
    if (sessionStorage.getItem('country-language')) {
      let value = sessionStorage.getItem('country-language');
      this.tsCountry = value.split('-')[1];
    } else {
      this.tsCountry = this.apiService.extractCountryFromDomain();
    }
    if (
      (!authToken || authToken == 'undefined' || request.url.includes('https://s3.eu-west-2.amazonaws.com')) &&
      !request.url.includes('wapi.travelstart.com') &&
      !request.url.includes('https://www.winsms.co.za')
    ) {
      request = request.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          accept: 'application/json',
          'TS-ORG': this.TS_ORG,
        }),
      });
      return next.handle(request);
    } else if (request.url.includes('wapi.travelstart.com')) {
      request = request.clone({
        headers: new HttpHeaders({
          Accept: '*/*',
          'TS-country': `${this.tsCountry}`,
          'TS-language': 'en',
        }),
      });
    } else if (request.url.includes('https://www.winsms.co.za')) {
      request = request.clone({
        headers: new HttpHeaders({
          Accept: '*/*',
        }),
      });
    } else {
      request = request.clone({
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + authToken,
          Accept: '*/*',
          'TS-country': `${this.tsCountry}`,
          'TS-language': 'en',
          'TS-ORG': this.TS_ORG,
        }),
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';

        if (typeof error.error === OBJECT && error.status === 400) {
          const data = error.error;
          let errorMsgForSnackbar = '';
          Object.keys(data).forEach(function (key) {
            if (key !== IDENTIFIER && typeof data[key] === OBJECT) {
              const newData = data[key];
              Object.keys(newData).forEach(function (key) {
                errorMsgForSnackbar += newData[key];
                errorMsgForSnackbar += '\n';
              });
            }
            if (key !== IDENTIFIER && typeof data[key] === STRING) {
              errorMsgForSnackbar += data[key];
              errorMsgForSnackbar += '\n';
            }
          });
          this.snackbar.error(errorMsgForSnackbar);
        }

        // Added 404 status code to handle no data response.
        if (typeof error.error === OBJECT && (error.status === 404 || error.status === 403)) {
          const data = error.error;
          let errorMsgForSnackbar = '';
          Object.keys(data).forEach(function (key) {
            if (key !== IDENTIFIER && key !== SUCCESS) {
              errorMsgForSnackbar += data[key];
              errorMsgForSnackbar += '\n';
            }
          });
          this.snackbar.error(errorMsgForSnackbar);
        }
        // Added 401 status code to handle token has expired or invalid token.
        if (typeof error.error === OBJECT && error.status === 401) {
          this.snackbar.sessionTimeout();
        }

        if (error.error instanceof ErrorEvent) {
          console.log('this is client side error');
          errorMsg = `Error: ${error.error.message}`;
        } else {
          console.log('this is server side error');
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}, ${error}`;
        }
        return throwError(errorMsg);
      })
    );
  }
}
