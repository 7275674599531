import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import { SignupComponent } from './signup/signup.component';
import { AgentConfirmationComponent } from './agent-confirmation/agentconfirmation.component';
import { OTPVerificationComponent } from './otp-verification/otp-verification.component';

const routes: Routes = [
  { path: 'register', component: SignupComponent },
  //{ path: 'login', component: SigninComponent },
  {
    path: 'confirmation',
    component: SignupComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'resetPassword',
    component: ForgotPasswordComponent,
  },
  {
    path: 'OTP-verification',
    component: OTPVerificationComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule {}
