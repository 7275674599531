<!-- Circular loader overlay -->
<div class="overlay" *ngIf="isLoading">
  <div class="loader"></div>
</div>
<div class="contact-us-Section">
  <app-login-header></app-login-header>
  <div class="mt-3">
    <div class="iframeSec">
      <div class="row">
        <div class="col-md-6 offset-md-3 py-3">
          <iframe #iframeRef id="IframeRef" class="iframe" [src]="iframeUrl" title="contact-us"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
