import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, UntilDestroy } from '@core';
import { I18nService } from './i18n/i18n.service';
import { ResponsiveService } from '@core/services/responsive.service';
import { ApiService } from './general/services/api/api.service';
import { ThemeService } from './@core/services/theme.service';

const log = new Logger('App');

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  ONLINE: string = 'online';
  OFFLINE: string = 'offline';
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatus: string;

  @ViewChild('iframeRef', { static: false }) iframe: ElementRef<HTMLIFrameElement>;

  constructor(
    private router: Router,
    private i18nService: I18nService,
    private responsiveService: ResponsiveService,
    private apiService: ApiService,
    private themeService: ThemeService
  ) {}

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }
    this.setThemeByCountry(this.apiService.extractWhiteLabelDomain().TS_ORG);
    console.log('>>>>>- init app');

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    this.onResize();

    this.onlineEvent = fromEvent(window, this.ONLINE);
    this.offlineEvent = fromEvent(window, this.OFFLINE);

    this.subscriptions.push(this.onlineEvent.subscribe((e) => (this.connectionStatus = this.ONLINE)));
    this.subscriptions.push(this.offlineEvent.subscribe((e) => (this.connectionStatus = this.OFFLINE)));

    // this.sendMessageToIframe()
    this.updateAppVersion();
  }

  onResize() {
    this.responsiveService.checkWidth();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /*It checks the latest version of the app and updates the local storage*/
  updateAppVersion() {
    console.log('app_Version >>>>>>>>', '2.0.0');
  }
  /**To set whitelabel theme based on whaitelabel domain */
  private setThemeByCountry(country: string) {
    console.log(country);
    switch (country) {
      case 'TS_FS':
        this.setTheme('flightsite');
        break;
      case 'TS_CT':
        this.setTheme('clubhub');
        break;
      default:
        this.setTheme('default');
    }
  }
  setTheme(param: any) {
    console.log(param);
    this.themeService.setTheme(param);
  }
}
