<div class="header">
  <h2 mat-dialog-title>{{ data?.title }}</h2>
  <button mat-icon-button [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>Reason for rejection</mat-label>
    <textarea matInput [(ngModel)]="data.rejectReason"></textarea>
  </mat-form-field>
</div>

<div mat-dialog-actions [align]="'end'">
  <button mat-raised-button color="primary" [mat-dialog-close]="data.rejectReason">
    {{ data?.btnText }}
  </button>
</div>
