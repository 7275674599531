import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize, first } from 'rxjs/operators';

import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@core';
import { UtilityService } from '@app/@core/services/utility.service';
import { RestService } from '@app/@core/services/rest.service';

@UntilDestroy()
@Component({
  templateUrl: './agentconfirmation.component.html',
  styleUrls: ['./agentconfirmation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgentConfirmationComponent implements OnInit {
  successMsg: string;
  isVerified: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public restService: RestService
  ) {}

  ngOnInit() {
    this.getTokens();
  }

  getTokens() {
    let params;
    params = this.route.snapshot.queryParams;
    this.restService.confirmAgent(params).subscribe((res: any) => {
      if (res) {
        this.isVerified = true;
        this.successMsg = res.message;
      }
    });
  }
}
