import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { RestService } from '@app/@core/services/rest.service';

const AGENT = 'agent';
const ALL = 'all';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss', './../../../../theme/toggle_switch.scss'],
})
export class PermissionsComponent implements OnChanges {
  products: any = [];
  permissions: any = [];
  isPermissionsTabDisabled = false;
  areAllPermissionsSelected: boolean = false;
  nextButtonText: string = 'Next';
  areProductsLoading: boolean = true;
  ALL_PERMISSIONS: string = 'Allow all Permissions';
  @Input() shouldResetForm: boolean;
  @Input() selectedProducts: any = [];
  @Input() selectedPermissions: any = [];
  @Input() userRole: string = AGENT;
  @Input() isSubmitting: boolean;
  @Input() submitButtonText: string = 'Create User';
  @Input() shouldShowCancelButton: boolean = false;
  selectedProductsData: any = [1];
  @Output() formSubmitEvnt: EventEmitter<any> = new EventEmitter<any>();
  selectedTab: any = null;
  constructor(private restService: RestService) {}

  ngOnInit() {
    this.selectTab('widget');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getProducts();
    if (changes?.shouldResetForm?.currentValue) {
      this.selectedProductsData = [];
      this.isPermissionsTabDisabled = false;
      this.areAllPermissionsSelected = false;
      this.permissions = this.permissions.map((each: Object) => ({ ...each, isSelected: false }));
      this.switchToNextTab();
    }
  }

  getProducts() {
    this.restService.getProducts().subscribe((res: any) => {
      this.products = res.success ? res.data : [];
      if (this.selectedProductsData.length) {
        this.getPermissions(this.selectedProductsData);
        this.isPermissionsTabDisabled = false;
        this.switchToNextTab();
      } else this.isPermissionsTabDisabled = true;
      this.areProductsLoading = false;
    });
  }

  productSelectionHandler(event: any, id: any) {
    const isSelected = event.target.checked;
    this.selectedProductsData = isSelected ? [1] : [];
    this.isPermissionsTabDisabled = !event.target.checked;
    event.target.checked && this.getPermissions([1]);
  }

  getPermissions(productIds: number[]) {
    const reqObj = {
      productlist: productIds.length > 1 ? [1] : productIds,
      user_type: this.userRole,
    };
    this.restService.getPermissions(reqObj).subscribe((res: any) => {
      if (res.success) {
        this.permissions = res.data.map((each: any) => ({
          ...each,
          isSelected: this.selectedPermissions.some((x: any) => x.id == each.id),
        }));
        this.areAllPermissionsSelected =
          this.permissions.filter((each: any) => each.isSelected).length === this.permissions.length;
      } else this.permissions = [];
    });
  }

  permissionsSelectionHandler(permissionId: string) {
    let selectedPermissionsCount = 0;
    if (permissionId === ALL) this.areAllPermissionsSelected = !this.areAllPermissionsSelected;

    this.permissions = this.permissions.map((each: any) => {
      if (each.id === parseInt(permissionId) || this.areAllPermissionsSelected) {
        let isSelected = true;
        if (each.id === parseInt(permissionId)) {
          isSelected = !each.isSelected;
          if (this.areAllPermissionsSelected) this.areAllPermissionsSelected = false;
        } else if (permissionId === ALL) isSelected = true;
        else isSelected = each.isSelected;

        each = { ...each, isSelected };
      } else each = { ...each, isSelected: permissionId === ALL ? false : each.isSelected };

      if (each.isSelected) selectedPermissionsCount++;
      if (selectedPermissionsCount === this.permissions.length) this.areAllPermissionsSelected = true;
      return each;
    });
  }

  switchToNextTab() {
    this.selectTab('permissions');
  }

  submitForm = () => {
    const getSelectedPermissions = () =>
      this.permissions.reduce((acc: object, each: any) => (each.isSelected ? { ...acc, [each.id]: true } : acc), {});
    this.formSubmitEvnt.emit({ permissions: getSelectedPermissions(), products: this.selectedProductsData });
  };
  selectedActive(param: any) {
    return this.selectedTab == param;
  }
  selectTab(param: any) {
    this.selectedTab = param;
  }
}
