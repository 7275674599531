import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedCommonService } from '@app/@shared/services/shared-common.service';
import { AgentService } from '@app/agent/services/agent.service';
import { getCityNamesfromJson, getNegtiveAmount } from '@app/@shared/utils/shared.utils';
import { homepagePath } from '@app/auth/routing_path';

@Component({
  selector: 'app-view-reserved-itinerary',
  templateUrl: './view-reserved-itinerary.component.html',
  styleUrls: ['./view-reserved-itinerary.component.scss'],
})
export class ViewReservedItineraryComponent implements OnInit {
  public itineraryInformation: any = null;
  isLoading: boolean = false;
  bookingRef: any = null;
  isHaveError: boolean = false;
  collapsedCardList = {
    bookingStatusCard_expanded: false,
    flightDetailsCard_expanded: true,
    travellerDetailsCard_expanded: false,
    invoiceDetailsCard_expanded: false,
  };
  airportsJsonData: any = null;
  constructor(
    private agentService: AgentService,
    private route: ActivatedRoute,
    private sharedCommonService: SharedCommonService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((x: any) => {
      if (x?.bookingRef) {
        this.bookingRef = x.bookingRef;
        this.getReservedItinData();
      }
    });
    this.getAirportsJsonList();
  }
  /**To expand and collapse cards */
  expandCard(param: any) {
    this.collapsedCardList[param] = !this.collapsedCardList[param];
  }
  /**To get Itinerary Data */
  getReservedItinData() {
    this.isLoading = true;
    this.agentService.viewItinerary(this.bookingRef).subscribe(
      (res: any) => {
        this.isLoading = false;
        if (res.success) {
          this.itineraryInformation = res?.data;
        } else {
          this.isHaveError = true;
          this.isLoading = false;
        }
      },
      (error) => {
        if (error) {
          this.isHaveError = true;
          this.isLoading = false;
        }
      }
    );
  }
  getMinusAmt(amount: any) {
    return getNegtiveAmount(amount);
  }
  makePayment() {
    /**hide functionality now we are follow payment link flow if CH want internal EFT we can enable it
     * const user = JSON.parse(sessionStorage.getItem('user'));
    if (user.organization_id === 'TS_CT') {
      this.router.navigate([`${homepagePath(user)}/bank-transfer`]);
      return;
    }
     */

    /** we are redirect to iframe with uuid & invide details in the iframe create a paymentlink with these UUID & INVID render iframe with this link  */

    if (
      this.itineraryInformation?.uuid &&
      this.itineraryInformation?.invoiceList?.length > 0 &&
      this.itineraryInformation?.invoiceList[0]?.invoiceId
    ) {
      this.router.navigate(['/bookingFlights'], {
        queryParams: {
          uuid: this.itineraryInformation?.uuid || null,
          invid:
            this.itineraryInformation?.invoiceList?.length > 0 && this.itineraryInformation?.invoiceList[0]?.invoiceId
              ? this.itineraryInformation?.invoiceList[0]?.invoiceId
              : null,
          tccReference: this.bookingRef,
        },
      });
    }
  }
  public getCityName(param: string) {
    return this.airportsJsonData ? getCityNamesfromJson(param, this.airportsJsonData) : param;
  }
  /**To get all Airports form S3 temporary solution only */
  getAirportsJsonList() {
    this.sharedCommonService.getAirportsJsonData().subscribe((data: any) => {
      this.airportsJsonData = data;
    });
  }
}
