<div class="">
  <app-login-header></app-login-header>
  <div class="row m-0">
    <div class="col-12 px-0 px-lg-3">
      <div class="bank_details_sec mt-3 mb-3" *ngIf="offlinebanksList.length > 0">
        <div class="bank_details_head">BANK DETAILS</div>
        <div class="bank_details_desc">
          If you prefer to pay by bank transfer or cash deposit, please use the following bank details using your
          booking number as transaction reference.
        </div>
        <div class="bank_details_subhead">The following banks allow for EFT or I-PAY instant EFT payment options.</div>
        <ul>
          <li class="banksList" *ngFor="let bank of offlinebanksList">
            <div class="bankName">
              <span class="mr-1">{{ bank.name }}</span>
              <a [href]="bank.url" rel="noopener" target="_blank">
                <img src="../../../../assets/icons/dashboard_Icons/navigateTo.svg" alt="navigateTo" width="12px" />
              </a>
              <span class="float-right">
                <img src="../../../../assets/icons/dashboard_Icons/bank_logo.svg" alt="bank_logo" width="24px" />
              </span>
            </div>
            <div class="bank_desc">
              <div class="desc_labl">Branch code</div>
              <div class="desc_val">{{ bank.branch_code }}</div>
            </div>
            <div class="bank_desc">
              <div class="desc_labl">Account Number</div>
              <div class="desc_val">{{ bank.account_number }}</div>
            </div>
            <div class="bank_desc">
              <div class="desc_labl">Account Holder</div>
              <div class="desc_val">{{ bank.account_holder }}</div>
            </div>
            <div class="bank_desc">
              <div class="desc_labl">SWIFT code</div>
              <div class="desc_val">{{ bank.SWIFTcode }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
