<div class="invoicePage">
  <app-login-header></app-login-header>
  <div class="invoice-container">
    <div class="sec_header">
      <div class="d-flex align-items-lg-center mb-lg-2 mb-sm-3">
        <img
          alt="left-short-arrow"
          (click)="goToProfile()"
          class="mr-4 back-page"
          src="./../../../assets/icons/back-arrow.svg"
        />
        <div class="d-flex align-items-center">
          <div class="to-from-city">{{ getAirports(deptCity) }}</div>
          <div class="mx-2">
            <span *ngIf="tripType == ONE_WAY">
              <img
                alt="dir"
                class="m-1"
                width="16px"
                src="./../../../assets/icons/srp_Icons/one-way-icn.svg"
                alt="one-way-icn.svg"
              />
            </span>
            <span *ngIf="tripType !== MULTI_CITY && tripType !== ONE_WAY">
              <img
                alt="dir"
                class="m-1"
                width="16px"
                src="./../../../assets/icons/srp_Icons/two_way_route.svg"
                alt="two_way_route.svg"
              />
            </span>
            <span *ngIf="tripType == MULTI_CITY">
              <img
                alt="dir"
                width="16px"
                class="m-1 mr-3 ml-3"
                src="./../../../assets/icons/srp_Icons/multy_city_arrows.svg"
                alt="multy_city_arrows"
              />
            </span>
          </div>
          <div class="to-from-city">{{ getAirports(arrCity) }}</div>
        </div>
      </div>
      <div class="travel-info ml-5 d-flex align-items-center" *ngIf="viewResultData">
        <div *ngIf="viewResultData?.agencyDetail?.agency_name">
          <div translate class="labelTxt">Agency Name</div>
          <div class="labelvalue">{{ viewResultData.agencyDetail.agency_name }}</div>
        </div>
        <div *ngIf="viewResultData?.status?.booking" class="mx-5">
          <div translate class="labelTxt">Status</div>
          <div class="{{ viewResultData?.status?.booking }}">
            {{ viewResultData?.status?.booking ? viewResultData?.status?.booking : 'N/A' }}
          </div>
        </div>
        <div *ngIf="viewResultData.travellers">
          <div class="labelTxt">Traveller(s)</div>
          <div class="labelvalue">
            {{ viewResultData.travellers }}
          </div>
        </div>
      </div>
    </div>
    <div class="booking_Itinerary_page mb-4">
      <div class="total_viewcontainer" *ngIf="!activeTab && viewResultData && !viewResultData?.code">
        <div
          *ngIf="
            viewResultData?.reservations?.length > 0 &&
            viewResultData?.reservations[0]?.originDestinationOptionsList.length > 0
          "
        >
          <div class="flight_details">
            <app-flight-details-card
              [itinerary_Data_Info]="viewResultData"
              [isShowManageBookings]="apiService.toDisplayForClubhub()"
            ></app-flight-details-card>
          </div>
        </div>

        <section class="travellers_details" *ngIf="viewResultData?.reservations?.length > 0">
          <div class="travellers-inner">
            <div class="sec_title mb-2">
              <label translate>Traveller Information</label>
            </div>
            <ul class="list" *ngIf="viewResultData.reservations[0].travellerList.length > 0">
              <li *ngFor="let adult of viewResultData.reservations[0].travellerList">
                <div class="row mt-1 mb-1">
                  <div class="col-md-2 col-6 mb-3 mb-md-0">
                    <label translate class="trav_labl" for="">First name</label>
                    <p class="mb-0 trav_data">
                      <span *ngIf="adult.personName.nameTitle">{{ adult.personName.nameTitle }}.</span>
                      {{ adult.personName.givenName | titlecase }}
                    </p>
                  </div>
                  <div class="col-md-2 col-6 mb-3 mb-md-0">
                    <label translate class="trav_labl" for="">Surname</label>
                    <p class="mb-0 trav_data">{{ adult.personName.surname | titlecase }}</p>
                  </div>
                  <div class="col-md-3 col-6 mb-3 mb-md-0">
                    <label translate class="trav_labl" for="">Email Address</label>
                    <p translate class="mb-0 trav_data" *ngIf="!adult.email">N/A</p>
                    <p class="mb-0 trav_data" *ngIf="adult.email">{{ adult.email }}</p>
                  </div>
                  <div class="col-md-2 col-6 mb-3 mb-md-0">
                    <label translate class="trav_labl" for="">Passport number</label>
                    <p translate class="mb-0 trav_data" *ngIf="!adult.passport.passportNumber">N/A</p>
                    <p class="mb-0 trav_data" *ngIf="adult.passport.passportNumber">
                      {{ adult.passport.passportNumber }}
                    </p>
                  </div>
                  <div class="col-md-2 col-6">
                    <label translate class="trav_labl" for="">Contact number</label>
                    <p
                      class="mb-0 trav_data"
                      *ngIf="
                        adult.telephoneSummaryList.length > 0 &&
                        adult.telephoneSummaryList[0] &&
                        adult.telephoneSummaryList[0].phoneNumber
                      "
                    >
                      +<span
                        *ngIf="adult.telephoneSummaryList[adult.telephoneSummaryList.length - 1].countryAccessCode"
                        >{{ adult.telephoneSummaryList[adult.telephoneSummaryList.length - 1].countryAccessCode }}</span
                      >&nbsp;<span
                        *ngIf="adult.telephoneSummaryList[adult.telephoneSummaryList.length - 1].areaCityCode"
                        >{{ adult.telephoneSummaryList[adult.telephoneSummaryList.length - 1].areaCityCode }}</span
                      >{{ adult.telephoneSummaryList[adult.telephoneSummaryList.length - 1].phoneNumber }}
                    </p>
                    <p translate class="mb-0 trav_data" *ngIf="adult.telephoneSummaryList.length == 0">N/A</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>

        <!--Invoice Details-->

        <div class="fare_breakdown" *ngIf="viewResultData.invoiceList && viewResultData.invoiceList.length > 0">
          <div class="row mx-0">
            <div class="col-md-6 col-lg-5 px-0">
              <div class="fare_info" *ngIf="viewResultData">
                <div class="tripType">
                  <div translate class="sec_heading">Trip cost details</div>
                </div>
                <div class="price_breakup">
                  <div class="fares">
                    <table class="table" aria-describedby="fares">
                      <tr *ngFor="let data of viewResultData.invoiceList[0].invoiceLineList">
                        <td *ngIf="data.amount > 0" id="description" class="txt_align">
                          {{ data.description }}
                        </td>
                        <td *ngIf="data.amount > 0" class="txt_des">
                          {{ data.currencyCode | currencyCode }}{{ data.amount | currency: '' + ' ':'symbol':'1.0' }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="total_amt d-flex align-items-center" *ngIf="viewResultData.totalAmount">
                  <label translate class="labl">Total Price</label>
                  <label class="amt ml-auto">
                    <span>
                      {{ viewResultData.totalCurrency | currencyCode
                      }}{{ viewResultData.totalAmount | currency: '' + ' ':'symbol':'2.0' }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="error_Sec total_viewcontainer" *ngIf="!isLoading && !viewResultData">
        <div class="container error_body">
          <div class="sec_img d-flex justify-content-center">
            <img src="./../../../../assets/icons/nff-web.svg" alt="server Error" class="img-fluid" />
          </div>
          <div class="err_msg">
            <p translate="" class="Trip_head">No data found</p>
          </div>
        </div>
      </div>
      <div *ngIf="isLoading">
        <app-spinner [isLoading]="isLoading"></app-spinner>
      </div>
    </div>
  </div>
</div>
<div id="inData"></div>

<div *ngIf="allowDownloadingCustomerInvoice">
  <app-customer-invoice [customerInvoiceData]="customerBookingReferenceId"></app-customer-invoice>
</div>

<div *ngIf="allowDownloadingAgentInvoice">
  <app-agent-invoice [agentInvoiceData]="agentBookingReferenceId"></app-agent-invoice>
</div>
