<div class="card_item">
  <div class="card_item_header">
    <ng-content select="[header]"></ng-content>
  </div>

  <div class="card_item_body">
    <ng-content select="[body]"></ng-content>
  </div>

  <div class="card_item_footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
