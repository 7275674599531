<div *ngIf="editContent && editTemplate">
  <div class="mb-lg-4 editTemplete_Section mb-sm-0">
    <span style="cursor: pointer" class="d-none d-lg-block float-left">
      <i class="fas fa-arrow-left" (click)="viewTemplete()"></i></span
    >&nbsp;
    <span class="float-right editTemplete-btn-sm">
      <button class="btn btn_preview" (click)="savetemplate()">Save</button>
      <button class="btn btn_preview" (click)="previewTemplate()">Live Preview</button>
      <button class="btn btn_preview btn_publish" (click)="publishTemplate()">Publish</button>
    </span>
  </div>
  <ejs-richtexteditor #toolsRTE id="alltoolRTE" [toolbarSettings]="tools" [(ngModel)]="editContent">
    <!-- <ng-template #valueTemplate>
      <p>The Rich Text Editor component is WYSIWYG ("what you see is what you get") editor
        that provides the best user experience to create and update the content.
            Users can format their content using standard toolbar commands.</p>
    
            <p><b>Key features:</b></p>
    
            <ul><li><p>Provides &lt;IFRAME&gt; and &lt;DIV&gt; modes</p></li>
            <li><p>Capable of handling markdown editing.</p></li>
            <li><p>Contains a modular library to load the necessary functionality on demand.</p></li>
            <li><p>Provides a fully customizable toolbar.</p></li>
            <li><p>Provides HTML view to edit the source directly for developers.</p></li>
            <li><p>Supports third-party library integration.</p></li>
            <li><p>Allows preview of modified content before saving it.</p></li>
            <li><p>Handles images, hyperlinks, video, hyperlinks, uploads, etc.</p></li>
            <li><p>Contains undo/redo manager.</p></li>
            <li><p>Creates bulleted and numbered lists.</p></li>
            </ul>
      </ng-template> -->
  </ejs-richtexteditor>
</div>
<div class="editTemplete_Section" *ngIf="!editTemplate && previewtemplate">
  <span style="cursor: pointer">
    <i class="fas fa-arrow-left" (click)="viewEditTemplete()" aria-hidden="true"></i>&nbsp;
    <span class="d-lg-none email-live-prev"> Email Live Preview </span>
  </span>

  <div class="mb-4" [innerHTML]="getData(previewtemplate.html)"></div>
  <div class="editTemplete-btn-sm d-lg-none">
    <button class="btn btn_group" (click)="publishTemplate()">Publish</button>
    <button class="btn btn_group btn_save" (click)="savetemplate()">Save</button>
  </div>
</div>
<div
  class="modal fade"
  id="confirmEdit_Modal"
  data-backdrop="static"
  data-keyboard="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog confirm_mdl_dialog" role="document">
    <div class="modal-content dialog_content">
      <div class="modal-body mdl_body">
        <button type="button" class="close d-lg-none" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div translate class="mdl_heading">Are you sure you want to Save?</div>
        <p class="mdl_subheading">
          <span translate>Once update is done, then the previous template is replaced with current one.</span>
        </p>
        <!-- <h5 class="mb-5">
          Estimated Refund Amount<br />
          <span class="refamount" *ngIf="viewResultData"
            >{{ viewResultData.totalCurrency }}&nbsp;{{ viewResultData.totalAmount }}</span>
        </h5> -->

        <div class="text-center">
          <button type="button" class="cancel_btn mr-2" aria-label="Close" data-dismiss="modal">
            <span translate>Cancel</span>
          </button>
          <button type="button" class="sub_btn btn-primary" aria-label="Close" (click)="continue()">
            <span translate>Save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  ​
</div>
