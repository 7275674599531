<div class="bg-color">
  <div class="OTP_verification_sec">
    <div class="logo_sec">
      <a class="" [routerLink]="'/'" routerLinkActive="active">
        <img
          *ngIf="apiService.extractWhiteLabelDomain().TS_ORG !== 'TS_FS'"
          class="img-fluid"
          src="assets/organization-logos/{{ apiService.extractWhiteLabelDomain().TS_ORG }}.svg"
          width="160"
          alt="{{ apiService.extractWhiteLabelDomain().TS_ORG }}"
        />
        <img
          *ngIf="apiService.isFlightsiteDomain()"
          class="img-fluid"
          src="assets/organization-logos/flight-site_logo.png"
          width="160"
          alt="travelstart-logo"
        />
      </a>
    </div>
    <div class="otp_body_sec">
      <div class="head_sec text-left">
        <img
          src="../../../assets/icons/back-arrow.svg"
          alt="left-short-arrow"
          (click)="switchToLogIn()"
          style="cursor: pointer"
        />
      </div>
      <div class="verification_form mt-3">
        <div class="otp_Img">
          <img src="../../../assets/icons/OTP_verification.svg" alt="OTP_verification" />
        </div>
        <div class="verification_head_txt mt-3 mb-2">OTP Verification</div>
        <div class="verification_sub_txt">
          One-Time Password sent to your registered mobile number
          <span *ngIf="tokenData?.mobile_number">ending with {{ tokenData?.mobile_number }}</span>
        </div>
        <div class="otp_control mt-3 my-3">
          <input
            type="text"
            placeholder="Enter OTP"
            class="form-control text-center"
            (keypress)="onlyNumbers($event)"
            maxlength="6"
            [formControl]="OTP_Control"
          />
          <div *ngIf="submitted && OTP_Control.errors" class="validationError_msg">
            <div *ngIf="OTP_Control.errors.required">Please enter OTP</div>
          </div>
        </div>
        <div class="resend_otp_success mb-1" *ngIf="isResend_OTP">OTP has sent successfully.</div>
        <div class="resend_otp">
          Didn’t receive the OTP? <span class="otpLink" (click)="resendOTP()">Resend OTP</span>
        </div>
        <div class="OTP_Mismatched d-none">OTP has mismatched please enter valid OTP</div>
        <div class="mt-3">
          <button class="btn primary_CTA verifyCTA" (click)="verify_OTP()">
            <i class="fa fa-spinner fa-spin mr-1" style="font-size: 12px" [hidden]="!isVerified" aria-hidden="true"></i
            >Verify
          </button>
        </div>
      </div>
      <div class="verification_success mt-3 d-none">
        <div class="success_Img">
          <img src="../../../assets/icons/success-check.svg" alt="success-check" />
        </div>
        <div class="success_Txt my-2">Success</div>
        <div class="success_msg">Thank you your OTP has verified successfully</div>
        <div class="mt-3 d-none">
          <button class="btn primary_CTA verifyCTA" (click)="navigateToDashboard()">Okay</button>
        </div>
      </div>
    </div>
  </div>
</div>
