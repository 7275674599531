<div class="payStack_sec">
  <div class="credit-card pt-2" [formGroup]="paymentGatewayForm" *ngIf="!showPaymentGatewayIframe">
    <div class="paystack_card">
      <div class="card-body">
        <div class="card-info">
          <div class="row card_details">
            <div class="col-sm-12">
              <div class="row m-0">
                <div class="col-sm-12 text-left">
                  <div class="add-money-input">
                    <input
                      class="form-control pl-18"
                      placeholder="Enter your deposit amount"
                      type="tel"
                      [formControl]="paymentGatewayForm"
                      (keypress)="onlyNumbers($event)"
                      (input)="onChange($event.target.value)"
                      (paste)="(false)"
                      required
                    />
                    <span>
                      {{ currency_code | currencyCode }}
                    </span>
                  </div>
                  <div *ngIf="isSubmitted && paymentGatewayForm.errors">
                    <div class="validationError_msg" *ngIf="isSubmitted && paymentGatewayForm.errors.required">
                      Required Amount
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-0">
                <div class="col-sm-12 text-left">
                  <ul class="list-unstyled add-money-tags">
                    <li
                      (click)="selectAmountToBeAdded(defaultAmount)"
                      *ngFor="let defaultAmount of currency_code == 'ZAR' ? ZA_default_Amount : NG_default_Amount"
                    >
                      <span>{{ currency_code | currencyCode }}{{ defaultAmount }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row m-0 accept-fee">
                <div class="col-sm-12">
                  <label class="btn p-0">
                    <mat-checkbox
                      type="checkbox"
                      class="mat-checkbox"
                      (change)="askConsentToProceedPaymentHandler($event)"
                      [checked]="isVaildAmount"
                      [ngClass]="{ opacity05: paymentGatewayForm.value == 0 }"
                    >
                      <span translate class="labl" *ngIf="!apiService.toDisplayForWhitelabel()"
                        >I agree to pay processing fee.</span
                      >
                      <span translate class="" *ngIf="apiService.toDisplayForWhitelabel()">
                        <span translate>I agree to pay processing fee.</span>
                        <a
                          target="_blank"
                          href="{{ 'terms&conditionsLink' | translate }}"
                          rel="noopener"
                          class="termsLink"
                          ><span translate>T&C</span></a
                        >
                      </span>
                    </mat-checkbox>
                    <div *ngIf="isSubmitted && !isVaildAmount">
                      <div translate class="validationError_msg">Please select pay processing fee.</div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="d-flex justify-content-center m-0">
                <div class="text-center">
                  <span>
                    <button
                      class="btn_addMoney primary_CTA"
                      (click)="paymentGatewayHandler()"
                      translate
                      [disabled]="isLoading"
                      [ngClass]="{ opacity05: canProceedToInitiatePayment || !isAddMoneyViaPaystackInProgress }"
                    >
                      <i class="fa fa-spinner fa-spin mr-2" *ngIf="isLoading" aria-hidden="true"></i>
                      Proceed
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="iframe_payment_gateway" *ngIf="showPaymentGatewayIframe">
    <div class="iframe_section bg-white">
      <div class="text-center payment_response" *ngIf="isPaymentProcessing && paymentVerificationDetails">
        <div class="paymentSuccess" *ngIf="paymentVerificationDetails.success">
          <img
            class="img_section"
            src="../../../../assets/icons/success-green-tick.svg"
            alt="success_img"
            width="100px"
          />
          <div class="success_text align-self-center">Money added successfully to wallet</div>
          <button
            class="mt-3 click_btn primary_CTA"
            type="button"
            data-dismiss="modal"
            (click)="closeAddMoneySectionModal()"
          >
            Ok
          </button>
        </div>
        <div
          class="paymentFailed"
          *ngIf="!paymentVerificationDetails.success && paymentVerificationDetails.message == 'FAILED'"
        >
          <div class="text-center">
            <img
              class="failure_img"
              src="../../../../../assets/icons/something_went_wrong_icon.svg"
              alt="payment failed"
            />
            <div class="mt-3">
              <div><strong translate>Sorry please try again.</strong></div>
              <div class="payment_failedtext">
                <span translate>Don&#x2019;t worry, no money has been deducted from your account.</span>
                <span translate>If there has been a deduction, please email us at</span>
                <a href="mailto:help@travelstart.com">help@travelstart.com</a>.
              </div>
            </div>
            <button
              class="mt-3 click_btn primary_CTA"
              type="button"
              data-dismiss="modal"
              (click)="closeAddMoneySectionModal()"
            >
              Ok
            </button>
          </div>
        </div>
      </div>

      <div id="iframeContainer" class="form form-horizontal" *ngIf="!isPaymentProcessing">
        <div
          id="pause"
          class="d-flex align-items-center justify-content-center mt-5"
          *ngIf="isPaystackCallbackUrlLoading"
        >
          <div id="spinner"></div>
        </div>
        <div [hidden]="isPaystackCallbackUrlLoading">
          <iframe
            title="3DIframe"
            id="threeDSecureIFrame"
            name="threeDSecureIFrame"
            class="w-100"
            height="100%"
            [src]="paystackTargetIFrameUrl"
          >
            <div>
              <span translate>Your browser does not support iFrames.</span>
            </div>
          </iframe>
        </div>
      </div>
    </div>
  </section>
</div>
