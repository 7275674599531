<div>
  <app-login-header></app-login-header>
</div>

<div class="generate_paymentlink_Sec">
  <div class="generate_paymentlink_card">
    <div class="generate_paymentlink_header mb-3">
      <div class="head_txt">Generate Card Payment link</div>
      <div class="head_sub_txt">Generate a unique payment link for transaction</div>
    </div>
    <div class="generate_paymentlink_body">
      <form [formGroup]="generate_paymentlink_Form">
        <div class="formHead_txt mb-3">Customer Details</div>
        <div class="row formControlLabels">
          <div class="col-lg-2 col-12">
            <label for="firstname">Customer Name*</label>
          </div>
          <div class="col-lg-4 col-12">
            <input
              type="text"
              class="form-control"
              name=""
              id=""
              placeholder="Customer name"
              formControlName="firstName"
            />
            <div class="validation_msg" *ngIf="form_submitted && form.firstName.errors">
              <div *ngIf="form_submitted && form.firstName.errors.required">Customer name is required</div>
              <div *ngIf="form_submitted && form.firstName.errors.pattern">Customer name is Invalid</div>
            </div>
          </div>
        </div>
        <div class="row formControlLabels">
          <div class="col-lg-2 col-12">
            <label for="lastname">Customer Surname*</label>
          </div>
          <div class="col-lg-4 col-12">
            <input
              type="text"
              class="form-control"
              name=""
              id=""
              placeholder="Customer surname"
              formControlName="surName"
            />
            <div class="validation_msg" *ngIf="form_submitted && form.surName.errors">
              <div *ngIf="form_submitted && form.surName.errors.required">Customer Surname is required</div>
              <div *ngIf="form_submitted && form.surName.errors.pattern">Customer Surname is Invalid</div>
            </div>
          </div>
        </div>
        <div class="row formControlLabels">
          <div class="col-lg-2 col-12">
            <label for="email">Email*</label>
          </div>
          <div class="col-lg-4 col-12">
            <input type="text" class="form-control" name="" id="" placeholder="Enter email" formControlName="email" />
            <div class="validation_msg" *ngIf="form_submitted && form.email.errors">
              <div *ngIf="form_submitted && form.email.errors.required">Email is required</div>
              <div *ngIf="form_submitted && form.email.errors.pattern">Email is Invalid</div>
            </div>
          </div>
        </div>
        <div class="row formControlLabels">
          <div class="col-lg-2 col-12">
            <label for="mobile_number">Mobile Number*</label>
          </div>
          <div class="col-lg-4 col-12">
            <input
              matInput
              class="form-control"
              appNumbersonly
              type="text"
              class="form-control"
              ng2TelInput
              [ng2TelInputOptions]="{ separateDialCode: true, formatOnDisplay: true }"
              (intlTelInputObject)="telInputObject($event)"
              (countryChange)="onCountryChange($event)"
              (keypress)="onlyNumbersKeysAllowed($event)"
              formControlName="mobileNumber"
              name="phone"
            />
            <div class="validation_msg" *ngIf="form_submitted && form.mobileNumber.errors">
              <div *ngIf="form_submitted && form.mobileNumber.errors.required">Mobile Number is required</div>
              <div translate *ngIf="form_submitted && form.mobileNumber.errors.minlength">
                Mobile number should have minimum 6 digits.
              </div>
              <div translate *ngIf="form_submitted && form.mobileNumber.errors.maxlength">
                Mobile number should have maximum 14 digits.
              </div>
            </div>
          </div>
        </div>
        <div class="row formControlLabels">
          <div class="col-lg-2 col-12">
            <label for="email">Booking Reference*</label>
          </div>
          <div class="col-lg-4 col-12">
            <input
              type="text"
              class="form-control"
              name=""
              id=""
              placeholder="Enter Reference No."
              formControlName="booking_Ref"
            />
            <div class="validation_msg" *ngIf="form_submitted && form.booking_Ref.errors">
              <div *ngIf="form_submitted && form.booking_Ref.errors.required">Booking reference is required</div>
            </div>
          </div>
        </div>
        <div class="row formControlLabels">
          <div class="col-lg-2 col-12">
            <label for="amount">Amount*</label>
          </div>
          <div class="col-lg-4 col-12">
            <div class="add-money-control">
              <input
                class="form-control pl-4"
                placeholder="Enter amount"
                type="number"
                formControlName="amount"
                (paste)="(false)"
                required
              />
              <span *ngIf="currency_code" class="currencyCode">
                {{ currency_code | currencyCode }}
              </span>
            </div>
            <div class="validation_msg" *ngIf="form_submitted && form.amount.errors">
              <div *ngIf="form_submitted && form.amount.errors.required">Amount is required</div>
              <!-- <div *ngIf="form_submitted && form.amount.errors.invalid || form.amount.value == 0">
                                Amount is Invalid
                            </div> -->
            </div>
          </div>
        </div>
        <div class="row formControlLabels">
          <div class="offset-lg-2 col-lg-4 col-12">
            <button class="btn submitCTA primary_CTA" (click)="generateLink()" [disabled]="isLoading">
              <i class="fa fa-spinner fa-spin" style="font-size: 24px" [hidden]="!isLoading" aria-hidden="true"></i>
              Generate link
            </button>
            <div class="success_Msg mt-3" *ngIf="paymentLink">
              <i class="fa fa-check mr-1"></i
              ><span
                >Link generated successfully and send to your email. If want please use this payment link :
                <div class="paymentLink">{{ paymentLink }}</div>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
