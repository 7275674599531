import { Component } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  tabs = ['All', 'Add Money', 'Sale', 'Refund'];
  selectedTab = 'All';

  handleTabSelection(tab: string) {
    this.selectedTab = tab;
  }
}
