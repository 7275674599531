<div class="flight_card">
  <div class="col-lg-12 col-12 flight_info p-0" *ngIf="itineraryInfo && itineraryInfo.reservations?.length > 0">
    <div
      *ngFor="let reservations of itineraryInfo.reservations; let index = index"
      [ngClass]="index == 0 && itineraryInfo.reservations.length > 1 ? 'border_btm' : ''"
    >
      <div
        class="m-0 flight_row"
        *ngFor="
          let odoList of reservations.originDestinationOptionsList;
          let idx = index;
          let last = last;
          let first = first
        "
        [ngClass]="index == 0 && reservations.originDestinationOptionsList.length > 1 && !last ? 'border_btm' : ''"
      >
        <div class="segMents_route d-lg-flex align-items-center mb-2 mb-lg-3">
          <div>
            <span>
              <img src="../../../assets/icons/srp_Icons/flight_dpt.svg" alt="flight_dpt" />
            </span>
            <span *ngIf="tripType && tripType !== 'multi'">
              <span
                class="dept_seg"
                *ngIf="idx === 0 && index === 0 && tripType && tripType !== 'multi' && tripType !== 'OpenJaw'"
              >
                <span class="way_def">DEPARTURE</span>
                <span class="date_Info">{{
                  itineraryInfo.reservations[0].originDestinationOptionsList[idx].bookingFlightSegmentList[0]
                    .departureDateTime | date: 'd MMM yy'
                }}</span>
              </span>

              <span
                class="dept_seg"
                *ngIf="
                  (itineraryInfo.reservations[0].originDestinationOptionsList.length === 2 &&
                    idx === 1 &&
                    tripType &&
                    tripType === 'Roundtrip') ||
                  index > 0
                "
              >
                <span class="way_def">RETURN</span>
                {{
                  itineraryInfo.reservations[index].originDestinationOptionsList[idx].bookingFlightSegmentList[0]
                    .departureDateTime | date: 'd MMM yy'
                }}
              </span>
            </span>

            <span
              class="dept_seg"
              *ngIf="
                itineraryInfo.reservations[0].originDestinationOptionsList.length > 1 &&
                tripType &&
                (tripType === 'multi' || tripType === 'OpenJaw')
              "
            >
              <span class="way_def">TRIP {{ idx + 1 }}</span>
              {{
                itineraryInfo.reservations[0].originDestinationOptionsList[idx].bookingFlightSegmentList[0]
                  .departureDateTime | date: 'd MMM yy'
              }}
            </span>
            <span class="pnr_txt ml-2 d-block d-lg-inline">PNR : {{ reservations.pnrReference }}</span>
          </div>
          <div class="ml-auto d-flex align-items-center">
            <div class="fare_ref" *ngIf="itineraryInfo?.fareChargesRules">
              <span
                translate
                class="non-ref-text"
                *ngIf="
                  odoList.bookingFlightSegmentList[0].airlineCode === 'GE' ||
                  odoList.bookingFlightSegmentList[0].airlineCode === 'FA' ||
                  itineraryInfo?.fareChargesRules?.cancellations === 'ANY TIME TICKET IS NON-REFUNDABLE.'
                "
              >
                Non-Refundable
              </span>
            </div>
            <div *ngIf="itineraryInfo?.status?.booking == 'PAID' && isShowManageBookings">
              <div class="ml-2" *ngIf="checkItinhasAirline('FA', odoList)">
                <button class="btn manageCTA primary_CTA" (click)="manageAirlineBookings('FA', reservations)">
                  Manage Booking
                </button>
              </div>
              <div class="ml-2" *ngIf="checkItinhasSupplier(reservations, odoList)">
                <button class="btn manageCTA primary_CTA" (click)="manageSupplierBookings(reservations)">
                  Manage Booking
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row mb-2"
          *ngFor="let segments of odoList.bookingFlightSegmentList; let index = index; let last = last"
        >
          <div class="d-flex align-items-center align-items-lg-start col-lg-3 col-sm-12 mb-2 mb-lg-0">
            <span class="airl_logo">
              <img
                class="carrier_logo airLineXs"
                src="{{ assets_PATH }}/assets/img/carriers/retina48px/carrier-{{
                  segments.operatingAirlineCode ? segments.operatingAirlineCode : segments.marketingAirlineCode
                }}.png"
                alt="{{
                  segments.operatingAirlineCode ? segments.operatingAirlineCode : segments.marketingAirlineCode
                }}"
              />
            </span>
            <span class="air_info clearfix">
              <span class="airl_name">
                {{ segments.marketingAirline }}
              </span>
              <span class="airl_numb d-lg-block"> ({{ segments.flightNumber }}) </span>

              <div *ngIf="segments.opAirlineCode">
                <div class="mweb opAirline">
                  Operated by
                  {{
                    flightsList?.airlineNames
                      ? flightsList.airlineNames[segments.opAirlineCode]
                      : segments.opAirlineCode
                  }}
                </div>
              </div>
              <div class="d-none d-lg-block">
                <ng-container *ngTemplateOutlet="baggage_InfoData; context: { $implicit: { segments: segments } }">
                </ng-container>
              </div>
            </span>
          </div>
          <div class="col-md-12 col-lg-9 col-12">
            <div class="row">
              <div class="col-lg-4 col-4 text-center">
                <div class="airp_name">{{ segments.departureDateTime | date: 'd MMM yy' }}</div>
                <div class="time">
                  {{ segments.departureDateTime | date: 'HH:mm' }}
                </div>
                <div class="airp_name">
                  {{ getAirportName(segments.departureAirport) }}
                </div>
              </div>

              <div class="text-center col-lg-3 col-4">
                <div class="flight_data w-100">
                  <div class="dur">
                    {{ getTimeInHours(segments.tsDuration) }}
                  </div>
                  <div class="">
                    <img src="../../../../assets/icons/srp_Icons/flight_route.svg" alt="flight_route" />
                  </div>
                  <div class="stops" translate>
                    Non stop
                    <span class="cabinClass d-none d-lg-inline">| {{ segments.cabin | lowercase }}</span>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-4 text-center">
                <div class="airp_name">{{ segments.arrivalAirportGmt | date: 'd MMM yy' }}</div>
                <div class="time">
                  {{ segments.arrivalAirportGmt | date: 'HH:mm' }}
                </div>
                <div class="airp_name">
                  {{ getAirportName(segments.arrivalAirport) }}
                </div>
              </div>
            </div>
          </div>
          <div class="extras mt-1 offset-0 offset-lg-3 pl-lg-0 col-12 col-lg-8">
            <div class="d-block d-lg-none">
              <ng-container *ngTemplateOutlet="baggage_InfoData; context: { $implicit: { segments: segments } }">
              </ng-container>
            </div>
            <div
              class="col-12 layover_section text-center"
              *ngIf="odoList.bookingFlightSegmentList.length > 1 && !last"
            >
              <div class="layover">
                <span>
                  <img src="./../../../assets/icons/srp_Icons/flight_dt_layover.svg" alt="layover" />
                  <span class="lap_txt">{{ getLayoverTxt(odoList)[index] }}</span>
                  {{ getLayoverTime(odoList)[index] }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #baggage_InfoData let-data>
  <div class="baggage_Info">
    <span class="baggage" *ngIf="data.segments.fareBaggageAllowanceList.length > 0">
      <span
        *ngFor="let bag of data.segments.fareBaggageAllowanceList; let idx = index; let first = first"
        class="baggage_des"
      >
        <span *ngIf="bag?.baggageDescription">
          <img
            *ngIf="handBag(bag?.baggageDescription)"
            class="bag_icon"
            src="./../../../assets/icons/srp_Icons/new_hand_baggage.svg"
            alt="hand"
          />
          <img
            *ngIf="bag?.baggageDescription === 'No baggage allowance'"
            class="bag_icon ml-1"
            src="./../../../assets/icons/srp_Icons/new_no_baggage.svg"
            alt="no bag"
          />
          <img
            *ngIf="bagageDesc(bag?.baggageDescription) === 'Checked baggage'"
            class="bag_icon ml-1"
            src="./../../../assets/icons/srp_Icons/new_checked_baggage.svg"
            alt="checked_baggage"
          />

          <span *ngIf="bag?.baggageDescription === 'No baggage allowance'" class="handLaggage"
            ><span translate class="baggage_val">No baggage</span>
          </span>
          <span *ngIf="bag?.baggageDescription !== 'No baggage allowance'" class="handLaggage"
            ><span class="baggage_val">{{ baggageDes(bag?.baggageDescription) }}</span>
          </span>
        </span>
      </span>
    </span>
  </div>
</ng-template>
