<div class="card-body px-0 px-md-3">
  <div [formGroup]="cardForm">
    <div class="row card-enter justify-content-center">
      <div class="card-section">
        <div class="card-container" [ngClass]="{ flip_card: flip_Card }">
          <div class="p-3 d-flex justify-content-center flex-column text-center">
            <span
              >Current Balance
              <span class="font-weight-bold">{{ wallet.currency | currencyCode }}{{ wallet.amount }}</span></span
            >
            <span class="d-none">you need to add {{ 'R200' }} to book your flight</span>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="flip front">
                <div class="clearfix"></div>
                <div class="row card_details">
                  <div class="col-sm-12">
                    <div class="row m-0">
                      <div class="col-sm-12 text-left">
                        <div class="add-money-input">
                          <input
                            required
                            type="number"
                            placeholder="0"
                            formControlName="amount"
                            class="form-control bg-transparent"
                            [ngClass]="{
                              'pl-22': (wallet.currency | currencyCode).length == 1,
                              'pl-32': (wallet.currency | currencyCode).length == 2,
                              'pl-42': (wallet.currency | currencyCode).length == 3
                            }"
                          />
                          <span>{{ wallet.currency | currencyCode }}</span>
                        </div>
                        <div *ngIf="isSubmitted && form.amount.errors">
                          <div class="validationError_msg" *ngIf="isSubmitted && form.amount.errors.required">
                            Required Amount
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="col-sm-12 text-left">
                        <ul class="list-unstyled add-money-tags">
                          <li>
                            <span (click)="setAmountToBeAdded(2000000)"
                              >{{ wallet.currency | currencyCode }}2000000</span
                            >
                          </li>
                          <li>
                            <span (click)="setAmountToBeAdded(500000)">{{ wallet.currency | currencyCode }}500000</span>
                          </li>
                          <li>
                            <span (click)="setAmountToBeAdded(1000000)"
                              >{{ wallet.currency | currencyCode }}1000000</span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="col-sm-9 text-left">
                        <div class="form-group mb-0">
                          <label class="wallet-card-label" translate>Card Number</label>
                        </div>
                        <div class="h-25">
                          <label *ngIf="cardnumber" class="cardDetails" translate>
                            {{ transformCardNumber(cardnumber) }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="col-md-8 col-8">
                        <div class="form-group text-left mb-0">
                          <label class="wallet-card-label" translate for="cc_name">CardHolder Name </label>
                        </div>
                        <div class="h-25">
                          <label *ngIf="cardHolderName" class="cardDetails text-uppercase" translate>{{
                            cardHolderName
                          }}</label>
                        </div>
                      </div>
                      <div class="col-md-4 col-4">
                        <div class="form-group mb-0">
                          <label class="wallet-card-label" translate>EXPIRY</label>
                        </div>
                        <div class="h-25">
                          <label *ngIf="cardExpiryMonth" class="cardDetails" translate
                            >{{ cardExpiryMonth }}/{{ cardExpiryYear }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flip back" [ngClass]="!flip_Card ? 'd-none' : 'inherit'">
                <div class="clearfix"></div>

                <div class="col-12 p-0">
                  <div>
                    <div class="strip"></div>
                    <div class="clearfix"></div>
                    <div class="form-group col-12 card_details cvv_field pull-right">
                      <div class="clearfix"></div>

                      <p translate class="text-right mb-1">CVV</p>
                      <span class="cvv">
                        <input
                          readonly
                          required
                          mask="000"
                          value="{{ CVV }}"
                          type="password"
                          autocomplete="off"
                          class="form-control"
                          title="Credit card n"
                        />
                      </span>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="details card-body p-0">
            <form [formGroup]="cardForm">
              <div class="form-group col-sm-12 p-0">
                <label translate>Card Number</label>
                <input
                  required
                  type="text"
                  maxlength="19"
                  minlength="16"
                  id="card_number"
                  autocomplete="off"
                  appCreditCardInput
                  class="form-control"
                  title="Credit card number"
                  mask="0000 0000 0000 0000"
                  formControlName="cardNumber"
                  placeholder="Enter the card number"
                />
                <div *ngIf="isSubmitted && form.cardNumber.errors">
                  <div class="validationError_msg" *ngIf="isSubmitted && form.cardNumber.errors.required">
                    Required Card Number
                  </div>
                </div>
                <div *ngIf="isSubmitted && form.cardNumber.errors">
                  <div class="validationError_msg" *ngIf="isSubmitted && form.cardNumber.errors.minlength">
                    Card Number should have minimum 16 digits
                  </div>
                </div>
              </div>
              <div class="form-group col-sm-12 p-0">
                <label translate for="cc_name">Card Holder Name</label>
                <input
                  required
                  type="text"
                  id="cc_name"
                  minlength="3"
                  maxlength="30"
                  autocomplete="off"
                  class="form-control"
                  formControlName="cardName"
                  placeholder="Enter your name on card"
                />
                <div *ngIf="isSubmitted && form.cardName.errors">
                  <div class="validationError_msg" *ngIf="isSubmitted && form.cardName.errors.required">
                    Required Card Holder Name
                  </div>
                </div>
                <div *ngIf="isSubmitted && form.cardName.errors">
                  <div class="validationError_msg" *ngIf="isSubmitted && form.cardName.errors.minlength">
                    Minimum 3 characters required
                  </div>
                </div>
                <div *ngIf="form.cardName.errors">
                  <div class="validationError_msg" *ngIf="form.cardName.errors.pattern">
                    Only alphabetical characters accepted
                  </div>
                </div>
              </div>
              <div class="row m-0">
                <div class="form-group col-sm-7 p-0">
                  <label translate for="cc_name">EXPIRY</label>
                  <br />
                  <div class="row">
                    <div class="col">
                      <select
                        id="card_expiry"
                        class="form-control selectExpiry col-sm-12"
                        formControlName="cardExpiryMonth"
                        required
                      >
                        <option translate value="">Month</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                      <div *ngIf="isSubmitted && form.cardExpiryMonth.errors">
                        <div class="validationError_msg" *ngIf="isSubmitted && form.cardExpiryMonth.errors.required">
                          Required Card Expiry Month
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <select
                        required
                        id="cardExpiryYear"
                        (keydown.Tab)="cardFlip()"
                        formControlName="cardExpiry"
                        class="form-control selectExpiry col-sm-12"
                      >
                        <option translate value="">Year</option>
                        <option value="{{ yearInfo.year }}" *ngFor="let yearInfo of cardExpiryYears">
                          {{ yearInfo.year }}
                        </option>
                      </select>
                      <div *ngIf="isSubmitted && form.cardExpiry.errors">
                        <div class="validationError_msg" *ngIf="isSubmitted && form.cardExpiry.errors.required">
                          Required Card Expiry Year
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group col-sm-5 p-0">
                  <div class="col-sm-10 pull-right p-0">
                    <label translate for="cc_name">CVV</label>
                    <input
                      id="cvv"
                      required
                      maxlength="3"
                      minlength="3"
                      type="password"
                      placeholder="CVV"
                      autocomplete="off"
                      class="form-control"
                      (click)="cardFlip()"
                      formControlName="cvv"
                      (blur)="cardFlipInit()"
                    />
                    <div *ngIf="isSubmitted && form.cvv.errors">
                      <div class="validationError_msg" *ngIf="isSubmitted && form.cvv.errors.required">
                        Required CVV
                      </div>
                    </div>
                    <div *ngIf="isSubmitted && form.cvv.errors">
                      <div class="validationError_msg" *ngIf="isSubmitted && form.cvv.errors.minlength">
                        Minimum 3 length required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-2 d-flex flex-fill">
                  <button class="btn btn_addMoney btn-block text-uppercase" (click)="proceedAddMoney()">
                    <i class="fa fa-spinner fa-spin mr-2" *ngIf="isAddingMoneyToWallet" aria-hidden="true"></i>
                    <span>Proceed to add money</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
