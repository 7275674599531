import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  AGENT_ACTIVATION,
  AGENT_CONFIRMATION,
  GET_TOKENS,
  AGENT_SIGNUP,
  GET_COUNTRIES,
  AGENT_LOGIN,
  ADMIN_LOGIN,
  GET_CITIES,
  GET_PRODUCTS,
  GET_PERMISSIONS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_VALIDATE,
  GET_LOCAL_GOVERNANCE,
  RESEND_OTP,
  ACCOUNTS,
  VERIFY_OTP,
} from 'src/constants/constants';
import { CredentialsService } from '@app/auth/credentials.service';
import { UtilityService } from './utility.service';
import { ApiService } from './../../general/services/api/api.service';
@Injectable({
  providedIn: 'root',
})
export class RestService {
  http: HttpClient;
  private BASE_URL: string;

  public userObj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public userObj$ = this.userObj.asObservable();

  constructor(
    http: HttpClient,
    public credentialsService: CredentialsService,
    private utilityService: UtilityService,
    private apiService: ApiService
  ) {
    this.http = http;
    this.BASE_URL = this.apiService.apiBaseUrl();
  }

  setUserDetails(userObj: any): void {
    this.userObj.next(userObj);
  }

  agentSignUp(agent: any): Observable<any> {
    return this.http.post(this.BASE_URL + AGENT_SIGNUP, agent).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getTokens(agent: any): Observable<any> {
    return this.http.post(this.BASE_URL + GET_TOKENS, agent).pipe(
      map((res) => {
        return res;
      })
    );
  }

  activateAgent(agent: any): Observable<any> {
    return this.http.post(this.BASE_URL + AGENT_ACTIVATION, agent).pipe(
      map((res) => {
        return res;
      })
    );
  }

  login(): Observable<any> {
    return this.http.get(this.BASE_URL + AGENT_LOGIN).pipe(
      map((user) => {
        return user;
      })
    );
  }

  getCountryList(): Observable<any> {
    return this.http.get(this.BASE_URL + GET_COUNTRIES).pipe(
      map((countries) => {
        return countries;
      })
    );
  }
  getCityList(country: any) {
    return this.http.get(this.BASE_URL + GET_CITIES + '?country=' + country);
  }

  getLocalGovAreas(city: string) {
    return this.http.get(this.BASE_URL + GET_LOCAL_GOVERNANCE + '?city=' + city);
  }

  getProducts() {
    return this.http.get(this.BASE_URL + GET_PRODUCTS);
  }

  getPermissions(data: any) {
    return this.http.post(this.BASE_URL + GET_PERMISSIONS, data).pipe(map((res) => res));
  }

  confirmAgent(params: any): Observable<any> {
    return this.http.get(this.BASE_URL + AGENT_CONFIRMATION + params.id + '/' + params.token + '/').pipe(
      map((res) => {
        return res;
      })
    );
  }

  logout() {
    this.utilityService.clearAuthToken();
  }

  forgotPasswordRequest(data: any) {
    return this.http.post(this.BASE_URL + FORGOT_PASSWORD_REQUEST, data);
  }

  forgotPasswordValidation(data: any) {
    return this.http.post(this.BASE_URL + FORGOT_PASSWORD_VALIDATE, data);
  }

  resetPassword(data: any) {
    return this.http.post(this.BASE_URL + FORGOT_PASSWORD, data);
  }
  send_OTP_SMS(data: any) {
    let Url = `${this.BASE_URL}${ACCOUNTS}${RESEND_OTP}`;
    return this.http.post(Url, data);
  }
  verify_OTP_SMS(data: any) {
    let Url = `${this.BASE_URL}${ACCOUNTS}${VERIFY_OTP}`;
    return this.http.post(Url, data);
  }
}
