import { generateIframeUrlByHostName } from '@app/@shared/utils/shared.utils';

import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';

import { delay } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss', '../../../../theme/overlay-loader.scss'],
})
export class ContactUsComponent implements OnInit {
  iframeUrl: any;
  isLoading: boolean = true;
  constructor(private _sanitizationService: DomSanitizer) {}

  ngOnInit(): void {
    this.getIframeUrl();
  }
  getIframeUrl() {
    this.iframeUrl = this._sanitizationService.bypassSecurityTrustResourceUrl(this.iframeUrlByHostName());
    of(null)
      .pipe(delay(2000))
      .subscribe(() => {
        this.isLoading = false;
      });
  }
  iframeUrlByHostName() {
    let iframe_Link = generateIframeUrlByHostName();
    let B2BUser: any = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
    let bearerToken: any = sessionStorage.getItem('accessTokenData')
      ? JSON.parse(sessionStorage.getItem('accessTokenData')).access
      : null;
    return `${iframe_Link}/contact-us/contact-form?b2b_Token=${bearerToken}&organization=${B2BUser?.organization_id}`;
  }
}
