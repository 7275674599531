<div>
  <app-login-header></app-login-header>
</div>
<div
  class="view_reserved_Itin_sec container"
  *ngIf="
    itineraryInformation?.reservations &&
    itineraryInformation?.reservations[0]?.originDestinationOptionsList?.length > 0 &&
    !isLoading
  "
>
  <div class="view_reserved_Itin_card">
    <div class="row">
      <div class="col-12">
        <section class="confirmation_Card mt-0 bookingStatusCard_expanded">
          <div class="booking_status">
            <div class="row m-0 align-items-center">
              <div class="col-lg-2 col-12 icon_booking_wrapper">
                <div class="text-center">
                  <img
                    class=""
                    src="./../../../assets/icons/srp_Icons/booking_success_icn.svg"
                    alt="bookingConfirmed"
                    loading="lazy"
                  />
                </div>
              </div>
              <div class="col-lg-8 col-12 booking_info">
                <div class="status_title">
                  <div class="ref_details">
                    <span translate class="ref_txt">Booking Reference Number:</span>&nbsp;
                    <span>{{ itineraryInformation.bookingRef }}</span>
                  </div>
                  <div class="desc">
                    <span translate>Ticketing is currently in progress</span>,
                    <span translate>keep an eye on your emails for your e-ticket</span>
                  </div>
                </div>
                <div class="route_defn">
                  <div>
                    <span class="city_labls">{{
                      getCityName(
                        itineraryInformation.reservations[0].originDestinationOptionsList[0].bookingFlightSegmentList[0]
                          .departureAirport
                      )
                    }}</span>
                    <span class="pr-2 pl-2" *ngIf="itineraryInformation.trip == 'Roundtrip'">
                      <img src="./../../../assets/icons/srp_Icons/two_way_route.svg" alt="round-trip" loading="lazy"
                    /></span>
                    <span class="pr-2 pl-2" *ngIf="itineraryInformation.trip == 'OneWay'">
                      <img src="./../../../assets/icons/srp_Icons/one-way-icn.svg" alt="one-way" loading="lazy"
                    /></span>
                    <span
                      class="pr-2 pl-2"
                      *ngIf="itineraryInformation.trip == 'multi' || itineraryInformation.trip == 'OpenJaw'"
                    >
                      <img
                        src="./../../../assets/icons/srp_Icons/multy_city_arrows.svg"
                        alt="multi-trip"
                        loading="lazy"
                    /></span>

                    <span class="city_labls">{{
                      getCityName(
                        itineraryInformation.reservations[0].originDestinationOptionsList[
                          itineraryInformation.reservations[0].originDestinationOptionsList.length - 1
                        ].bookingFlightSegmentList[
                          itineraryInformation.reservations[0].originDestinationOptionsList[
                            itineraryInformation.reservations[0].originDestinationOptionsList.length - 1
                          ].bookingFlightSegmentList.length - 1
                        ].arrivalAirport
                      )
                    }}</span>
                  </div>
                  <div class="book_ref">
                    <span class="labls"
                      ><span translate>Booking details sent to:</span>&nbsp;
                      <strong class="booking_details_send">{{ itineraryInformation.contactInfo.email }}</strong></span
                    >
                  </div>
                  <div class="mt-3">
                    <button class="btn paymentCTA primary_CTA" (click)="makePayment()">Make payment</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="confirmation_Card flightDetailsCard_expanded" *ngIf="itineraryInformation?.reservations">
          <div class="sec_head">
            <div translate class="sec_title">Flight Details</div>
            <div class="float-right ml-auto" (click)="expandCard('flightDetailsCard_expanded')">
              <span class="angleIcon" *ngIf="!collapsedCardList.flightDetailsCard_expanded">
                <i aria-hidden="true" class="fa fa-angle-down"></i>
              </span>
              <span class="angleIcon" *ngIf="collapsedCardList.flightDetailsCard_expanded">
                <i aria-hidden="true" class="fa fa-angle-up"></i>
              </span>
            </div>
          </div>
          <div class="flight_details" [hidden]="!collapsedCardList.flightDetailsCard_expanded">
            <app-flight-details-card [itinerary_Data_Info]="itineraryInformation"></app-flight-details-card>
          </div>
        </section>
        <section
          class="travellers_details confirmation_Card travellerDetailsCard_expanded"
          *ngIf="itineraryInformation.reservations[0].travellerList.length > 0"
        >
          <div class="sec_head">
            <div translate class="sec_title">Traveller(s)</div>
            <div class="float-right ml-auto d-none">
              <span class="angleIcon" *ngIf="!collapsedCardList.travellerDetailsCard_expanded">
                <i aria-hidden="true" class="fa fa-angle-down"></i>
              </span>
              <span class="angleIcon" *ngIf="collapsedCardList.travellerDetailsCard_expanded">
                <i aria-hidden="true" class="fa fa-angle-up"></i>
              </span>
            </div>
          </div>
          <div class="travellersData">
            <ul class="list">
              <li *ngFor="let pax of itineraryInformation.reservations[0].travellerList">
                <div class="d-block d-lg-inline-flex pax_info">
                  <span class="pax_icn">
                    <img src="../../../assets/icons/srp_Icons/pax-icn.svg" loading="lazy" alt="pax" />
                  </span>
                  <span *ngIf="pax.personName.nameTitle"
                    >{{ pax.personName.nameTitle }}. {{ pax.personName.givenName }}</span
                  >&nbsp;<span *ngIf="pax.personName.middleName">{{ pax.personName.middleName }}</span
                  >&nbsp;{{ pax.personName.surname }}
                </div>
                <!-- <div
                        class="ml-lg-4 d-block d-lg-inline-flex seats_Data"
                        *ngIf=" pax?.seatDetailsList?.length> 0 ">
                        <span>
                        <span class="d-none d-lg-inline-block"> Selected seats :</span>
                        <span class="d-inline-block d-lg-none">
                        <img  src="../../../assets/icons/srp_Icons/seat-list-icn.svg" alt="seat_icn" loading="lazy"/>
                        </span>
                        </span>
                        <span *ngFor="let seat of pax.seatDetailsList; let idx = index">
                           <span *ngIf="seat.seatNumber && seat.seatNumber != ''">
                              <div class="seat_info">
                                 <div class="mb-0">{{ flight_route.get(seat.flightNumber) }}</div>
                                 <div class="mb-0">{{ seat.seatNumber }}</div>
                              </div>
                           </span>
                        </span>
                     </div> -->
              </li>
            </ul>
          </div>
        </section>
        <!--Invoice Details-->
        <section class="confirmation_Card invoiceDetailsCard_expanded">
          <div class="sec_head">
            <div translate class="sec_title">Invoice details</div>
            <div class="float-right ml-auto" (click)="expandCard('invoiceDetailsCard_expanded')">
              <span class="angleIcon" *ngIf="!collapsedCardList.invoiceDetailsCard_expanded">
                <i aria-hidden="true" class="fa fa-angle-down"></i>
              </span>
              <span class="angleIcon" *ngIf="collapsedCardList.invoiceDetailsCard_expanded">
                <i aria-hidden="true" class="fa fa-angle-up"></i>
              </span>
            </div>
          </div>
          <div
            class="fare_info mt-3"
            *ngIf="itineraryInformation"
            [hidden]="!collapsedCardList.invoiceDetailsCard_expanded"
          >
            <div class="price_breakup">
              <div class="fares">
                <table class="table" aria-describedby="fares">
                  <tr *ngFor="let data of itineraryInformation.invoiceList[0].invoiceLineList">
                    <td id="description" class="txt_align">
                      {{ data.description }}
                    </td>
                    <td *ngIf="data.amount > 0" class="txt_des">
                      {{ data.currencyCode | currencyCode }}{{ data.amount | number: '1.2-2' }}
                    </td>
                    <td *ngIf="data.amount < 0" class="txt_des">
                      -{{ data.currencyCode | currencyCode }}{{ getMinusAmt(data.amount) | number: '1.2-2' }}
                    </td>
                  </tr>
                </table>
              </div>
              <div class="total_amt" *ngIf="itineraryInformation.totalAmount">
                <label translate class="labl">Total</label>
                <label class="amt">
                  <span
                    >{{ itineraryInformation.totalCurrency | currencyCode
                    }}{{ itineraryInformation.totalAmount | number: '1.2-2' }}</span
                  >
                </label>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isLoading">
  <app-spinner [isLoading]="isLoading"></app-spinner>
</div>
<div
  class="error_Sec mt-3 container"
  *ngIf="
    (!itineraryInformation?.reservations ||
      (itineraryInformation?.reservations &&
        itineraryInformation?.reservations[0]?.originDestinationOptionsList?.length == 0) ||
      isHaveError) &&
    !isLoading
  "
>
  <div class="container error_body">
    <div class="sec_img d-flex justify-content-center">
      <img src="./../../../../assets/icons/nff-web.svg" alt="server Error" class="img-fluid" />
    </div>
    <div class="err_msg" *ngIf="isHaveError || !itineraryInformation?.reservations">
      <p translate="" class="Trip_head">No data found</p>
    </div>
    <div
      class="err_msg"
      *ngIf="
        itineraryInformation?.reservations &&
        itineraryInformation?.reservations[0]?.originDestinationOptionsList?.length == 0
      "
    >
      <p translate="" class="Trip_head">Sorry your booking has cancelled</p>
    </div>
  </div>
</div>
