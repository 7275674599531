<app-login-header></app-login-header>
<div class="agent-header">
  <div class="header">
    <div>
      <ng-content select="[headerContent]"></ng-content>
    </div>

    <div>
      <ul class="nav-container p-0">
        <li class="tab-content text-uppercase" [ngClass]="{ 'active-tab': isBookingsTabActive || isItineraryView }">
          <div *ngIf="isSuperAdmin" [routerLink]="['/super-admin/bookings']">Bookings</div>
          <div *ngIf="isAgent" [routerLink]="['/agent/bookings']">Bookings</div>
        </li>
        <li
          *ngIf="isAgent"
          class="tab-content text-uppercase"
          [ngClass]="{ 'active-tab': isTravellersTabActive }"
          [routerLink]="['/agent/travellers']"
        >
          <div>Travellers</div>
        </li>
        <li class="tab-content text-uppercase" [ngClass]="{ 'active-tab': isPaymentsTabActive }">
          <div *ngIf="isSuperAdmin" [routerLink]="['/super-admin/payments']">Payments</div>
          <div *ngIf="isAgent" [routerLink]="['/agent/payments']">Payments</div>
        </li>
      </ul>
    </div>
  </div>
</div>
