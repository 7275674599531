import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@app/@shared/components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar) {}

  error(message: string) {
    return this._snackBar.open(message, 'X', { panelClass: ['error-msg-snack'] });
  }

  success(message: string) {
    return this._snackBar.open(message, 'CLOSE', { duration: 2000, panelClass: ['snackbar-success'] });
  }

  info(message: string) {
    return this._snackBar.open(message, 'CLOSE', { duration: 2000, panelClass: ['snackbar-info'] });
  }
  sessionTimeout() {
    let info = {
      message: 'Your session has expired',
      sub_message: 'Please log in again.',
      action: 'Ok',
    };
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: info,
    });
  }
}
