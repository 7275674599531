import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedCommonService } from '@app/@shared/services/shared-common.service';
import { AgentService } from '@app/agent/services/agent.service';
import { numInputNoChars } from '@app/general/utils/regex-utils';
import { CountryCodes } from '@app/general/utils/country-code';

@Component({
  selector: 'app-generate-payment-link',
  templateUrl: './generate-payment-link.component.html',
  styleUrls: ['./generate-payment-link.component.scss'],
})
export class GeneratePaymentLinkComponent implements OnInit {
  generate_paymentlink_Form: FormGroup;
  countryName: any = null;
  countryCode: any = null;
  form_submitted: boolean = false;
  currency_code: any = null;
  isLoading: boolean = false;
  paymentLink: any = null;
  userCountry: any = null;
  get form() {
    return this.generate_paymentlink_Form.controls;
  }
  constructor(
    private form_builder: FormBuilder,
    private commonService: SharedCommonService,
    private agentService: AgentService
  ) {
    this.getUserCountry();
  }

  ngOnInit(): void {
    this.currency_code = JSON.parse(sessionStorage.getItem('currency'));
    this.createForm();
  }
  createForm() {
    this.generate_paymentlink_Form = this.form_builder.group({
      firstName: ['', [Validators.pattern("^[a-zA-Z]+[-'s]?[a-zA-Z ]+$"), Validators.required]],
      surName: ['', [Validators.pattern("^[a-zA-Z]+[-'s]?[a-zA-Z ]+$"), Validators.required]],
      email: ['', [Validators.pattern('[a-zA-Z0-9._+-]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'), Validators.required]],
      mobileNumber: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(14)]],
      country_code: ['1'],
      amount: ['', [Validators.required]],
      booking_Ref: ['', [Validators.required]],
    });
  }

  generateLink() {
    this.form_submitted = true;
    if (this.generate_paymentlink_Form.invalid) {
      return;
    } else {
      let reqObj = {
        givenName: this.form.firstName.value,
        surname: this.form.surName.value,
        email: this.form.email.value,
        mobile: this.form.country_code.value.concat(this.form.mobileNumber.value),
        bookingref: this.form.booking_Ref.value,
        amount: this.form.amount.value,
      };
      this.isLoading = true;
      this.paymentLink = null;
      this.agentService.generatePaymentLink(reqObj).subscribe(
        (data: any) => {
          this.createForm();
          this.isLoading = false;
          this.form_submitted = false;
          console.log(data);
          this.paymentLink = data.redirection_link;
          setTimeout(() => {
            this.paymentLink = null;
          }, 200000);
        },
        (error: any) => {
          if (error) {
            this.isLoading = false;
            this.form_submitted = false;
          }
        }
      );
    }
  }
  getUserCountry() {
    this.commonService.getUserCountry().subscribe((value: any) => {
      this.userCountry = value.country;
    });
    for (let x in CountryCodes) {
      if (CountryCodes[x].code == this.userCountry) {
        this.generate_paymentlink_Form.get('country_code').setValue(CountryCodes[x].dial_code);
      }
    }
  }
  telInputObject(value: any) {
    this.countryName = value;
    this.commonService.getUserCountry().subscribe((val: any) => {
      this.userCountry = val.country;
      this.userCountry ? value.setCountry(this.userCountry) : value.setCountry('NG');
    });
  }

  onCountryChange(q: any) {
    this.generate_paymentlink_Form.controls['country_code'].setValue(q.dialCode);
    this.countryCode = Object.values(q)[2];
  }
  // allows users to type only numbers
  onlyNumbersKeysAllowed(event: any) {
    return numInputNoChars(event);
  }
}
