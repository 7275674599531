import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  FLIGHT_SEARCH,
  LOAD_FLIGHTS,
  AGENT_LIST,
  ADMIN_LOGIN,
  AGENT_VERIFCATION,
  ADMIN_DASHBOARD,
  AGENT_TOTAL_EARNINGS,
  AGENT_PROFILE_EDIT,
  AGENT_PAYMENT_DETAILS,
  ADMIN_PAYMENT_DETAILS,
  ADMIN_WALLET_TRANSACTIONS,
  ADMIN_AUDIT_LOGS,
  ADMIN_WALLET_CREDIT,
  AGENT_STAFF_LIST,
  BOOKING_LIST,
  TICKETING_REJECTIONS_LIST,
  CORE,
  GET_TICKETING_REJECTION_BY_ID,
  AGENT_SEARCH,
  CREATE_TICKETING_REJECTION,
  ADMIN,
  AGENT_STAFF,
  AGENCY_DETAILS,
  STAFF_APPROVAL_LIST,
  APPROVE_STAFF,
} from 'src/constants/constants';
import { CredentialsService } from '@app/auth/credentials.service';
import { ApiService } from './../../general/services/api/api.service';
@Injectable({
  providedIn: 'root',
})
export class SuperAdminService {
  http: HttpClient;
  private BASE_URL: any;

  public userObj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public userObj$ = this.userObj.asObservable();

  constructor(http: HttpClient, public credentialsService: CredentialsService, private apiService: ApiService) {
    this.http = http;
    this.BASE_URL = this.apiService.apiBaseUrl();
  }

  adminLogin(admin: any): Observable<any> {
    return this.http.post(this.BASE_URL + ADMIN_LOGIN, admin).pipe(
      map((res) => {
        return res;
      })
    );
  }

  loadFlights(searchTerm: any) {
    return this.http.get<any>(LOAD_FLIGHTS + 'n=6&q=' + searchTerm + '&locale=en_ZA');
  }

  searchFlight(searchObj: any): Observable<any> {
    if (searchObj.length >= 3) {
      return this.http.post(this.BASE_URL + FLIGHT_SEARCH, searchObj).pipe(
        map((res) => {
          return res;
        })
      );
    }
  }

  getAgentList(params: any) {
    return this.http.get(this.BASE_URL + AGENT_LIST, { params });
  }

  activateUser(user: any, isStaff: boolean): Observable<any> {
    let Url = isStaff
      ? this.BASE_URL + ADMIN + APPROVE_STAFF + user.agentId
      : this.BASE_URL + AGENT_VERIFCATION + user.agentId;
    return this.http.post(Url, user.status).pipe(
      map((res) => {
        return res;
      })
    );
  }
  loadAdminDashboard(params: any) {
    return this.http.get(this.BASE_URL + ADMIN_DASHBOARD, { params });
  }
  getAgentTotalEarning(params: any) {
    return this.http.get(this.BASE_URL + AGENT_TOTAL_EARNINGS, { params });
  }

  updateAgentDetails(agent: any): Observable<any> {
    return this.http.post(this.BASE_URL + AGENT_PROFILE_EDIT + agent.agentId, agent).pipe(map((res) => res));
  }

  getAgentDetails(id: any) {
    return this.http.get(this.BASE_URL + AGENT_PROFILE_EDIT + id);
  }

  getAgentPaymentDetails(id: any) {
    return this.http.get(this.BASE_URL + AGENT_PAYMENT_DETAILS + id);
  }

  getPaymentsTilesInfo(params: any) {
    return this.http.get(this.BASE_URL + ADMIN_PAYMENT_DETAILS, { params });
  }

  getAdminWalletTransactions(params: any) {
    return this.http.get(this.BASE_URL + ADMIN_WALLET_TRANSACTIONS, { params });
  }

  getAgentWalletTransactions(id: any, params: any) {
    return this.http.get(this.BASE_URL + ADMIN_WALLET_TRANSACTIONS + '/' + id, { params });
  }
  getAdminAuditLogs(params: any) {
    return this.http.get(this.BASE_URL + ADMIN_AUDIT_LOGS, { params });
  }
  getAgentWalletCreditDetails(id: any) {
    return this.http.get(this.BASE_URL + ADMIN_WALLET_CREDIT + '/' + id);
  }
  updateAgentWalletCredit(id: any, data: any) {
    return this.http.post(this.BASE_URL + ADMIN_WALLET_CREDIT + '/' + id, data);
  }
  getAgentStaffList(id: any) {
    return this.http.get(this.BASE_URL + AGENT_STAFF_LIST + '/' + id);
  }
  getAgentBookingHistory(id: any, params: any) {
    return this.http.get(this.BASE_URL + BOOKING_LIST + '/' + id, { params });
  }
  downloadReport(file: string | undefined, link: any): Observable<Blob> {
    return this.http.get(link, {
      responseType: 'blob',
    });
  }
  downloadExcelFile(exportReportLink: any, isBookingsTabActive: boolean) {
    let filename = isBookingsTabActive ? 'bookings-list.csv' : 'Transcations.csv';
    this.downloadReport(filename, exportReportLink).subscribe((file) => {
      const a = document.createElement('a');
      a.setAttribute('type', 'hidden');
      a.href = URL.createObjectURL(file);
      a.download = filename + '.xlsx';
      a.click();
      a.remove();
    });
  }
  getTicketingRejectionsList(params: any) {
    let Url = this.BASE_URL + CORE + TICKETING_REJECTIONS_LIST;
    return this.http.get(Url, { params });
  }
  getTicketingRejectionsDetailsById(id: any) {
    let Url = this.BASE_URL + CORE + GET_TICKETING_REJECTION_BY_ID + '/' + id;
    return this.http.get(Url);
  }
  getAgentsBySearch(param: any) {
    const Url = this.BASE_URL + CORE + AGENT_SEARCH + `?query=${param}`;
    return this.http.get(Url);
  }
  createTiketingRejection(data: any) {
    const Url = this.BASE_URL + CORE + CREATE_TICKETING_REJECTION;
    return this.http.post(Url, data);
  }
  updateAgentStaffStatus(reqObj: any) {
    const Url = this.BASE_URL + ADMIN + AGENT_STAFF + `/${reqObj.agencyId}/${reqObj.userId}`;
    return this.http.post(Url, {
      status: reqObj.status,
    });
  }
  getAgentProconDetails(param: any) {
    const Url = this.BASE_URL + ADMIN + AGENCY_DETAILS + `/${param}`;
    return this.http.get(Url);
  }
  updateAgentProconDetails(agencyId: any, data: any) {
    const Url = this.BASE_URL + ADMIN + AGENCY_DETAILS + '/' + agencyId;
    return this.http.post(Url, data);
  }
  /**To get staff list for approval from Admin  */
  getStaffApprovalList(params: any) {
    const Url = this.BASE_URL + ADMIN + STAFF_APPROVAL_LIST;
    return this.http.get(Url, { params });
  }
  /**To Approve agents staff from Admin */
  approveStaff(staff: any): Observable<any> {
    return this.http.post(this.BASE_URL + ADMIN + APPROVE_STAFF + staff.agentId, staff.status).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
