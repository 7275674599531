<div class="snackbarSec" *ngIf="data">
  <div class="headerTxt">
    {{ data?.message }}
  </div>
  <div class="subTxt mt-1" *ngIf="data?.sub_message">
    {{ data?.sub_message }}
  </div>
  <div *ngIf="data?.action" class="actionsSec">
    <button class="btn loginCTA primary_CTA" (click)="snackbarAction(data.action)">{{ data.action }}</button>
  </div>
</div>
