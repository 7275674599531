<!-- Circular loader overlay -->
<div class="overlay" *ngIf="showLoader">
  <div class="loader"></div>
</div>

<div class="iframe-container">
  <div class="bookFlowHeader mb-1">
    <span class="backtoDashboard d-flex align-items-center" (click)="navigateToDashboard()">
      <img src="../../../../assets/icons/back-arrow.svg" alt="back-to-dashboard-arrow" class="" />
      <div>
        <div>Back to</div>
        <div class="dashboard_txt">Dashboard</div>
      </div>
    </span>
  </div>
  <div>
    <iframe
      #iframeRef
      id="IframeRef"
      class="iframe"
      [src]="iframeUrl"
      style="
        width: 100%;
        height: calc(100% - 75px);
        position: absolute;
        border: none;
        margin: 0;
        padding: 0;
        overflow: hidden !important;
      "
      title="car booking flow"
    ></iframe>
  </div>
</div>
